import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { HUDTyper, HUDIcon, HUDInput, HUDCredentialInput, HUDLocationInput, HUDLocationList, HUDCardSectional } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import PatrolBuddyGoHUDUtilityManager from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import PatrolBuddyGoHUDUserManager from "../HUDManagers/PatrolBuddyGoHUDUserManager.js";

import { HUDLayoutManagerScreen } from "../HUDComponents/HUDLayoutManager.js";











function PatrolBuddyBaseAccountCreationView(patrolBuddyBaseAccountCreationViewConfigurationSettings)
{
     const {
     } = patrolBuddyBaseAccountCreationViewConfigurationSettings;
     
     const [accountCredentialProcessIndicatorTitle, setAccountCredentialProcessIndicatorTitle] = useState("Base Account Credentials");
     
     const [createBasePatrollerButtonHover, setCreateBasePatrollerButtonHover] = useState(false);
     
     const [usernameInputFocus, setUsernameInputFocus] = useState(false);
     
     const [usernameInputValue, setUsernameInputValue] = useState("");
     
     const [passwordInputFocus, setPasswordInputFocus] = useState(false);
     
     const [passwordInputValue, setPasswordInputValue] = useState("");
     
     const [creatingBasePatroller, setCreatingBasePatroller] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Enter Precinct Address");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(5); 
     
     const [locationResults, setLocationResults] = useState([]);
     
     const [currentLocality, setCurrentLocality] = useState({});
     
     const [localitySearchButtonHover, setLocalitySearchButtonHover] = useState(false);
     
     const [processingLocality, setProcessingLocality] = useState(false);
     
     const [addressInputInFocus, setAddressInputInFocus] = useState(false);
     
     const [addressInputValue, setAddressInputValue] = useState("");
     
     const [actionBarItemHover, setActionBarItemHover] = useState(null);
     
     const [actionBarItems, setActionBarItems] = useState([
          {
               itemName : "Locality",
               iconName : "fa-map-marker-alt",
               hoverColor : "#C9F5FF",
               color : "#616161",
               hudAction : () => {
                    HUDUniversalHUDUtilityManager.ScrollToComponent(localitySectionRef);
               }
          },
          {
               itemName : "Account Credentials",
               iconName : "fa-user-lock",
               hoverColor : "#C9F5FF",
               color : "#616161",
               hudAction : () => {
                    HUDUniversalHUDUtilityManager.ScrollToComponent(baseAccountSectionRef);
               }
          }
     ]);
     
     const baseAccountSectionRef = useRef(null);
     
     const localitySectionRef = useRef(null);
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     
     
     
     
     
     
     
     
     
     
     
     
     async function HandleBaseAccountCreation() 
     {
          setAccountCredentialProcessIndicatorTitle("Creating Base Account...");
          
          setProcessIndicatorTypeSpeed(5);
          
          setCreatingBasePatroller(true);
          
          if (HUDUniversalHUDUtilityManager.CheckEmptyData(currentLocality))
          {
               setTimeout(() => {
                    setAccountCredentialProcessIndicatorTitle("Did Not Create Base Account...Add a Locality.");
                    
                    setCreatingBasePatroller(false);
                    
                    setProcessIndicatorTypeSpeed(3);
               }, 1269);
               
               return;
          }
          
          const addedLocality = await PatrolBuddyGoHUDUtilityManager.AddPBGLocality(currentLocality.country, currentLocality.county, currentLocality.state, currentLocality.city);
          
          if (addedLocality === null)
          {
               setTimeout(() => {
                    setAccountCredentialProcessIndicatorTitle("Did Not Create Base Account...Potential Connection Issues.");
                    
                    setCreatingBasePatroller(false);
               }, 1269);
               
               setTimeout(() => {
                    setProcessIndicatorTypeSpeed(3);
               }, 1269);
               
               return;
          }
          
          const authenticated = await PatrolBuddyGoHUDUserManager.AddPBGUserBaseAccount(usernameInputValue, passwordInputValue, currentLocality.address, currentLocality.county);
          
          setTimeout(() => {
               if (authenticated[0] === true)
               {
                    setAccountCredentialProcessIndicatorTitle("Base Account Creation Successful!");
                    
                    setUsernameInputValue("");
                    
                    setPasswordInputValue("");
               }
               else
               {
                   setAccountCredentialProcessIndicatorTitle("Try Again...");
                   
                   setUsernameInputValue(usernameInputValue);
                   
                   setPasswordInputValue(passwordInputValue);
               }
               
               setCreatingBasePatroller(false);
          }, 1269);
          
          setTimeout(() => {
               setProcessIndicatorTypeSpeed(3);
          }, 1269);
          
          return;
     };
     
     async function HandleLocalitySearch(desiredAddressData)
     {
          setProcessIndicatorTitle("Verifying Address...");
          
          setProcessIndicatorTypeSpeed(21);
          
          setProcessingLocality(true);
          
          setCurrentLocality({});
          
          const desiredAddress = desiredAddressData.place_name ? desiredAddressData.place_name : desiredAddressData.text;
          
          setProcessIndicatorTitle("Getting Locality...");
          
          const localityData = await PatrolBuddyGoHUDUtilityManager.GetPBGLocality(desiredAddress);
          
          if (localityData !== null)
          {
               setAddressInputValue("");
               
               setTimeout(() => {
                    setProcessIndicatorTitle("Address Verified!");
                    
                    setProcessingLocality(false);
                    console.log({ ...localityData, address : desiredAddress });
                    setCurrentLocality({ ...localityData, address : desiredAddress });
               }, 1269);
               
               setTimeout(() => {
                    setProcessIndicatorTypeSpeed(5);
               }, 1269);
               
               return;
          }
          
          setTimeout(() => {
               setProcessIndicatorTitle("Try Again...");
               
               setProcessingLocality(false);
          }, 1869);
          
          setTimeout(() => {
               setProcessIndicatorTypeSpeed(5);
          }, 1869);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     return (
          <HUDLayoutManagerScreen>
               <div style = {{ position : "relative", height : "100%", width : "98.69%" }}>
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "center",
                              fontSize : "1.269rem",
                              color : "#616161",
                              marginTop : "0.569rem"
                         }}
                    >
                         <HUDTyper speed = {3} >
                              Base Account
                         </HUDTyper>
                    </label>
                    
                    <div
                         style = {{
                              position : "relative",
                              background : "#616161",
                              height : "0.169rem",
                              width : "calc(100%)",
                              opacity : 0.769,
                              marginBottom : "0.69rem",
                              marginTop : "0.69rem",
                         }}
                    />
                    
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              borderRadius : "0.69rem",
                              background : "rgba(41.69, 41.69, 41.69, 0.469)",
                              gap : "21.69px",
                              height : "auto",
                              width : "calc(100%)",
                              paddingBottom : "0.369rem",
                              paddingLeft : "0.69rem",
                              paddingTop : "0.369rem",
                              marginBottom : "0.69rem",
                              marginRight : "0.69rem",
                              marginTop : "0.69rem",
                         }}
                    >
                         {actionBarItems.length > 0 ? actionBarItems.map((item, index) => (
                         <span
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "column",
                                   transition : "all 0.069s ease",
                                   gap : "2.69px",
                                   scale : actionBarItemHover !== null && actionBarItemHover === index ? "1.069" : "1"
                              }}
                              
                              onMouseLeave = {() => {
                                   setActionBarItemHover(null);
                              }}
                              
                              onMouseEnter = {() => {
                                   setActionBarItemHover(index);
                              }}
                              
                              onClick = {item.hudAction}
                         >
                              <HUDIcon
                                   iconConfigurationSettings = {{
                                        cursor : "pointer"
                                   }}
                                   
                                   name = {item.iconName}
                                   
                                   color = {actionBarItemHover !== null && actionBarItemHover === index ? item.hoverColor : item.color}
                              />
                              
                              <span
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        cursor : "pointer",
                                        fontWeight : "569",
                                        fontFamily : "ModeNine",
                                        fontSize : "0.869rem",
                                        color : actionBarItemHover !== null && actionBarItemHover === index ? item.hoverColor : item.color
                                   }}
                              >
                                   {item.itemName}
                              </span>
                         </span>
                         )) : (
                         <>
                         </>
                         )}
                    </div>
                    
                    <div 
                         className = "invisa-scroller" 
                         
                         style = {{ 
                              position : "relative", 
                              display : "flex", 
                              flexDirection : "column", 
                              overflowY : "auto", 
                              height : "37.69rem" 
                         }}
                    >
                    <HUDCardSectional
                         containerConfigurationSettings = {{
                              boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.69)",
                              background : "rgba(57, 57, 57, 1)"
                         }}
                         
                         contentConfigurationSettings = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "column",
                              alignItems : "center",
                              transition : "height 0.869s ease-in-out",
                              boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.69)",
                              borderRadius : "0.269rem",
                              background : "#333333",
                              height : processingLocality || addressInputValue.trim() === "" ? "0rem" : "16.99rem",
                              width : "calc(100%)",
                              marginBottom : "0rem",
                              marginTop : "0rem",
                         }}
                         
                         footerConfigurationSettings = {{
                              marginTop : "0rem",
                         }}
                         
                         holographicBorderColor = {colorTransparency(paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor), 0.869)}
                         
                         holographicBorderRadius = {15.69}
                         
                         holographicBorderWidth = {1.969}
                         
                         showCardButton = {false}
                         
                         headerContent = {
                              <div ref = {localitySectionRef}>
                              <label
                                   style = {{
                                        position : "relative",
                                        flexShrink : 0,
                                        transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bolder",
                                        textAlign : "center",
                                        fontSize : "1.069rem",
                                        color : "#616161",
                                        marginTop : "0.569rem"
                                   }}
                              >
                                   <HUDTyper speed = {processIndicatorTypeSpeed} >
                                        {processIndicatorTitle}
                                   </HUDTyper>
                              </label>
                              
                              <div
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "row",
                                        alignItems : "center",
                                        visibility : processingLocality ? "collapse" : "visible",
                                        height : processingLocality ? "0rem" : "auto",
                                        gap : "10px",
                                        width : "27rem"
                                   }}
                              >
                              <HUDLocationInput
                                   className = "verification-modal-input-light"
                                   
                                   containerConfigurationSettings = {{
                                        display : "flex",
                                        alignItems : "center",
                                        background : "transparent",
                                        maxWidth : undefined,
                                        width : "calc(95.69%)",
                                        paddingRight : "0.069rem",
                                        marginTop : "0.869rem"
                                   }}
                                   
                                   inputConfigurationSettings = {{ 
                                        position : "relative",
                                        display : "flex",
                                        outline : "none",
                                        transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in, margin-left 0.269s ease-in",
                                        borderRadius : addressInputInFocus ? "0.69rem" : "0.369rem",
                                        borderLeft : "none",
                                        borderTop : "none",
                                        boxShadow : addressInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                        borderBottom : addressInputInFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                        borderRight : "none",
                                        visibility : processingLocality ? "hidden" : "visible",
                                        opacity : processingLocality ? 0 : 1,
                                        fontFamily : "ModeNine",
                                        fontSize : "1.069rem",
                                        background : "transparent",
                                        color : addressInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                        scale : addressInputInFocus ? "1.069" : "1",
                                        height : "1.569rem",
                                        width : "calc(100%)",
                                        paddingRight : "0.69rem",
                                        marginRight : "0.69rem",
                                        marginLeft : addressInputInFocus ? "1.069rem" : "0.369rem",
                                        marginTop : "0rem",
                                   }}
                                   
                                   setResultsExternally = {setLocationResults}
                                   
                                   setInputFocus = {setAddressInputInFocus}
                                   
                                   setInputValue = {setAddressInputValue}
                                   
                                   inputFocus = {addressInputInFocus}
                                   
                                   inputValue = {addressInputValue}
                                   
                                   inputValueExternally = {addressInputValue}
                                   
                                   onInputUpdateCompletion = {(response, setQuery) => {
                                        setQuery("");
                                   }}
                                   
                                   placeholder = "Search for a location..."
                                   
                                   showList = {false}
                                   
                                   inputType = "text"
                              />
                              </div>
                              </div>
                         }
                         
                         footerContent = {
                              <div
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        justifyContent : "flex-start",
                                        width : "calc(100%)"
                                   }}
                              >
                                   {currentLocality && HUDUniversalHUDUtilityManager.CheckEmptyData(currentLocality) === false ? (
                                   <>
                                   <label
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             flexShrink : 0,
                                             transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                             fontFamily : "ModeNine",
                                             fontWeight : "bolder",
                                             textAlign : "center",
                                             fontSize : "0.969rem",
                                             color : "#707070",
                                             marginTop : "0.569rem"
                                        }}
                                   >
                                        <HUDTyper speed = {processIndicatorTypeSpeed} >
                                             Locality Details
                                        </HUDTyper>
                                   </label>
                                   
                                   <label
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             flexShrink : 0,
                                             transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                             fontFamily : "ModeNine",
                                             fontWeight : "bolder",
                                             textAlign : "center",
                                             fontSize : "0.969rem",
                                             color : "#616161",
                                             marginLeft : "0.869rem",
                                             marginTop : "0.569rem"
                                        }}
                                   >
                                        <HUDTyper speed = {processIndicatorTypeSpeed} >
                                             {`Country: ${currentLocality.country}`}
                                        </HUDTyper>
                                   </label>
                                   
                                   <label
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             flexShrink : 0,
                                             transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                             fontFamily : "ModeNine",
                                             fontWeight : "bolder",
                                             textAlign : "center",
                                             fontSize : "0.969rem",
                                             color : "#616161",
                                             marginLeft : "0.869rem",
                                             marginTop : "0.569rem"
                                        }}
                                   >
                                        <HUDTyper speed = {processIndicatorTypeSpeed} >
                                             {`State: ${currentLocality.state}`}
                                        </HUDTyper>
                                   </label>
                                   
                                   <label
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             flexShrink : 0,
                                             transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                             fontFamily : "ModeNine",
                                             fontWeight : "bolder",
                                             textAlign : "center",
                                             fontSize : "0.969rem",
                                             color : "#616161",
                                             marginLeft : "0.869rem",
                                             marginTop : "0.569rem"
                                        }}
                                   >
                                        <HUDTyper speed = {processIndicatorTypeSpeed} >
                                             {`County: ${currentLocality.county}`}
                                        </HUDTyper>
                                   </label>
                                   </>
                                   ) : (
                                   <label
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             flexShrink : 0,
                                             transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                             fontFamily : "ModeNine",
                                             fontWeight : "bolder",
                                             textAlign : "center",
                                             fontSize : "0.969rem",
                                             color : "#616161",
                                             marginTop : "0.569rem"
                                        }}
                                   >
                                        <HUDTyper speed = {processIndicatorTypeSpeed} >
                                             N/A
                                        </HUDTyper>
                                   </label>
                                   )}
                              </div>
                         }
                    >
                         <HUDLocationList
                              resultsContainerConfigurationSettings = {{
                                   position : "relative",
                                   width : "calc(100%)",
                                   top : 0
                              }}
                              
                              onResultSelect = {HandleLocalitySearch}
                              
                              results = {locationResults}
                         />
                     </HUDCardSectional>
                     
                     <div
                         style = {{
                               position : "relative",
                               display : "flex",
                               background : "#616161",
                               height : "0.169rem",
                               width : "calc(91.69%)",
                               marginBottom : "0.69rem",
                               marginRight : "0.69rem",
                               marginLeft : "1.869rem",
                               marginTop : "0.69rem"
                         }}
                     />
                     
                     <HUDCardSectional
                         containerConfigurationSettings = {{
                              boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.69)",
                              background : "rgba(57, 57, 57, 1)"
                         }}
                         
                         contentConfigurationSettings = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "column",
                              alignItems : "center",
                              transition : "height 0.869s ease-in-out",
                              boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.69)",
                              borderRadius : "0.269rem",
                              background : "#333333",
                              height : creatingBasePatroller ? "0rem" : "8.69rem",
                              width : "calc(100%)",
                              marginBottom : "0rem",
                              marginTop : "0rem",
                         }}
                         
                         footerConfigurationSettings = {{
                              marginTop : "0rem",
                         }}
                         
                         holographicBorderColor = "rgba(169, 169, 169, 0.869)"
                         
                         holographicBorderRadius = {15.69}
                         
                         holographicBorderWidth = {1.969}
                         
                         showCardButton = {false}
                         
                         headerContent = {
                              <div ref = {baseAccountSectionRef}>
                              <label
                                   style = {{
                                        position : "relative",
                                        flexShrink : 0,
                                        transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                        fontFamily : "ModeNine",
                                        fontWeight : "bolder",
                                        textAlign : "center",
                                        fontSize : "1.069rem",
                                        color : "#616161",
                                        marginTop : "0.569rem"
                                   }}
                              >
                                   <HUDTyper speed = {processIndicatorTypeSpeed} >
                                        {accountCredentialProcessIndicatorTitle}
                                   </HUDTyper>
                              </label>
                              </div>
                         }
                         
                         footerContent = {
                              <div
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        alignItems : "center",
                                        width : "calc(100%)",
                                        marginTop : "0rem"
                                   }}
                              >
                                   {!creatingBasePatroller && usernameInputValue.trim() !== "" && passwordInputValue !== "" && HUDUniversalHUDUtilityManager.CheckEmptyData(currentLocality) === false ? (
                                   <Fade direction = "down" duration = {269} >
                                        <div
                                              style = {{
                                                   position : "relative",
                                                   display : "flex",
                                                   userSelect : "none",
                                                   cursor : "pointer",
                                                   transition : "background 0.369s ease, color 0.369s ease, font-size 0.369s ease-in-out, scale 0.269s ease-in-out",
                                                   visibility : creatingBasePatroller ? "collapse" : "visible",
                                                   fontFamily : "ModeNine",
                                                   fontSize : createBasePatrollerButtonHover ? "1.269rem" : "1.169rem",
                                                   borderRadius : "0.69rem",
                                                   background : createBasePatrollerButtonHover ? "rgba(201, 245, 255, 0.569)" : "rgba(69, 69, 87.69, 0.169)",
                                                   color : createBasePatrollerButtonHover ? "white" : "rgba(120, 120, 140, 0.69)",
                                                   scale : createBasePatrollerButtonHover ? "1.269" : "1",
                                                   width : "auto",
                                                   paddingBottom : "0.569rem",
                                                   paddingRight : "0.69rem",
                                                   paddingLeft : "0.69rem",
                                                   paddingTop : "0.569rem"
                                              }}
                                              
                                              onMouseLeave = {() => {
                                                   setCreateBasePatrollerButtonHover(false);
                                                   
                                                   return;
                                              }}
                                              
                                              onMouseEnter = {() => {
                                                   setCreateBasePatrollerButtonHover(true);
                                                   
                                                   return;
                                              }}
                                              
                                              onClick = {HandleBaseAccountCreation}
                                        >
                                              <HUDTyper speed = {3} >
                                                   Create Base Patroller
                                              </HUDTyper>
                                        </div>
                                   </Fade>
                                   ) : (
                                   <>
                                   </>
                                   )} 
                              </div>
                         }
                    >
                         <div
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "column",
                                   justifyContent : "flex-start",
                                   alignItems : "center",
                                   width : "calc(87.69%)"
                              }}
                         >
                         <HUDInput
                              className = "verification-modal-input-light"
                              
                              containerConfigurationSettings = {{
                                   position : "relative",
                                   display : "flex",
                                   width : "calc(100%)",
                                   marginLeft : "0rem",
                                   marginTop : "1rem"
                              }}
                              
                              inputContainerConfigurationSettings = {{ 
                                   position : "relative",
                                   display : "flex",
                                   transition : "all 0.069s ease",
                                   borderRadius : "0.169rem",
                                   borderLeft : "none",
                                   borderTop : "none",
                                   borderBottom : usernameInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                   borderRight : "none",
                                   visibility : creatingBasePatroller ? "hidden" : "visible",
                                   opacity : creatingBasePatroller ? 0 : 1,
                                   fontFamily : "ModeNine",
                                   fontSize : "1.069rem",
                                   background : "transparent",
                                   color : usernameInputFocus ? "#C9F5FF" : "#A5A5A5",
                                   scale : usernameInputFocus ? "1.069" : "1",
                                   height : "1.569rem",
                                   width : "calc(77.69%)",
                                   paddingRight : "0.69rem",
                                   marginRight : "0.69rem",
                                   marginTop : "1.869rem",
                              }}
                              
                              setInputFocus = {setUsernameInputFocus}
                              
                              setInputValue = {setUsernameInputValue}
                              
                              inputFocus = {usernameInputFocus}
                              
                              inputValue = {usernameInputValue}
                              
                              placeholder = "Username..."
                              
                              inputType = "text"
                         />
                         
                         <HUDCredentialInput
                              credentialInputContainerConfigurationSettings = {{
                                   position : "relative",
                                   display : "flex",
                                   visibility : creatingBasePatroller ? "collapse" : "visible",
                                   width : "calc(77.69%)",
                                   marginLeft : "0rem",
                                   marginTop : "1rem"
                              }}
                              
                              inputContainerConfigurationSettings = {{
                                   position : "relative",
                                   display : "flex",
                                   transition : "all 0.069s ease",
                                   background : "transparent",
                                   borderRadius : "0.169rem",
                                   borderBottom : passwordInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                   borderRight : "none",
                                   borderLeft : "none",
                                   borderTop : "none",
                                   fontFamily : "ModeNine",
                                   fontSize : "1.069rem",
                                   color : "rgba(169, 227.69, 255, 1)",
                                   width : "calc(100%)",
                                   marginLeft : passwordInputFocus ? "1.69rem" : "0rem",
                              }}
                              
                              credentialShownIconColor = "rgba(169, 227.69, 255, 1)"
                              
                              placeholder = "Password..."
                              
                              setInputFocus = {setPasswordInputFocus}
                              
                              setInputValue = {setPasswordInputValue}
                              
                              inputFocus = {passwordInputFocus}
                              
                              inputValue = {passwordInputValue}
                              
                              inputType = "password"
                         />
                         </div>
                    </HUDCardSectional>
                    </div>
               </div>
          </HUDLayoutManagerScreen>
     );
}

export default PatrolBuddyBaseAccountCreationView;