import * as React from "react";

import { HUDNavigationComponent, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";











function PatrolBuddyGoActionButton(patrolBuddyGoActionButtonConfigurationSettings)
{
     const {
          setPatrolBuddyGoActionButtonHover,
          patrolBuddyGoActionButtonHover,
          patrolBuddyGoActionButtonTitle = "",
          patrolBuddyGoActionButtonIcon,
          HandlePatrolBuddyGoAction,
     } = patrolBuddyGoActionButtonConfigurationSettings;
     
     
     
     
     
     
     
     
     
     
     
     return (
          <HUDNavigationComponent>
               {(navigateTo) => (
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              justifyContent : "center",
                              alignItems : "center",
                              cursor : "pointer",
                              transition : "scale 0.3s ease-in-out, background 0.69s ease-in-out, box-shadow 0.69s ease-in-out",
                              boxShadow : patrolBuddyGoActionButtonHover ? "0 0 10px rgba(255, 255, 255, 0.69)" : "inset 0 0 10px rgba(0, 0, 0, 0.69)",
                              background : patrolBuddyGoActionButtonHover ? "#9FD1D9" : "#575757",
                              borderRadius : "1.269rem",
                              scale : patrolBuddyGoActionButtonHover ? "1.169" : "1",
                              height : "4.469rem",
                              width : "21rem",
                              marginTop : "1.69rem",
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoActionButtonHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoActionButtonHover(true);
                         }}
                         
                         onClick = {async () => {
                              if (HandlePatrolBuddyGoAction)
                              {
                                   HandlePatrolBuddyGoAction(navigateTo);
                                   
                                   return;
                              }
                              
                              return;
                         }}
                    >
                         {patrolBuddyGoActionButtonIcon ? (
                               <HUDIcon
                                    iconConfigurationSettings = {{
                                         cursor : "pointer",
                                         height : "3.69rem",
                                         marginRight : "1.069rem",
                                    }}
                                    
                                    svg = {patrolBuddyGoActionButtonIcon}
                               />
                          ) : (
                               <>
                               </>
                          )}
                          
                          <label
                               style = {{
                                    position : "relative",
                                    display : "flex",
                                    userSelect : "none",
                                    cursor : "pointer",
                                    fontFamily : "ModeNine",
                                    fontWeight : "bold",
                                    fontSize : "1.469rem",
                                    width : "auto",
                                    color : "#FFFFFF"
                               }}
                          >
                               <HUDTyper speed = {2} >
                                    {patrolBuddyGoActionButtonTitle}
                               </HUDTyper>
                          </label>
                    </div>
               )}
          </HUDNavigationComponent>
     );
};

export default PatrolBuddyGoActionButton