import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";

import { HUDUniversalHUDUtilityManager, Delay, DetermineFileType, ConvertDateTime } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDHeaderBar, HUDGridItem, HUDContentPreview, HUDVideo, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade, motion } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import PatrolBuddyDataViewer from "./PatrolBuddyDataViewer.js";











const itemVariants = (index) => ({
  hidden : { opacity : 0, y : -20 },
  visible : {
    opacity : 1,
    y : 0,
    transition : {
      duration : 0.5,
      delay : index * 0.169,
    },
  },
});

function PBGFileCell(pbgFileCellConfigurationSettings)
{
     const {
          pbgFileFooterContainerConfigurationSettings = {},
          pbgFileDateContainerConfigurationSettings = {},
          pbgFileCellContainerConfigurationSettings = {},
          pbgFileVideoConfigurationSettings = {},
          pbgFileConfigurationSettings = {},
          filePreviewIndicatorContainerConfigurationSettings = {},
          filePreviewVideoContainerConfigurationSettings = {},
          filePreviewIconContainerConfigurationSettings = {},
          filePreviewVideoConfigurationSettings = {},
          filePreviewIndicatorConfigurations = {},
          filePreviewIconConfigurations = {},
          playVideoOnClick = false,
          playVideoOnHover = true,
          autoPlayVideo = true,
          showHeaderBar = true,
          colorTransparency,
          paletteColor,
          palette,
          signedUser,
          signedIn,
          index,
          file,
          createWindow,
          viewFile
     } = pbgFileCellConfigurationSettings;
     
     const [fileHovering, setFileHovering] = useState(null);
     
     
     
     
     
     
     
     
     
     
     const videoPreviewConfigurations = {
          previewIndicatorContainerConfigurationSettings : {
               position : "fixed",
               ...filePreviewIndicatorContainerConfigurationSettings
          },
          previewVideoContainerConfigurationSettings : {
               height : "calc(100%)",
               width : "calc(100%)",
               ...filePreviewVideoContainerConfigurationSettings
          },
          previewIconContainerConfigurationSettings : {
               position : "fixed",
               height : "calc(100%)",
               width : "calc(100%)",
               left : 0,
               top : 0,
               ...filePreviewIconContainerConfigurationSettings
          },
          previewVideoConfigurationSettings : {
               height : "calc(100%)",
               width : "calc(100%)",
               ...filePreviewVideoConfigurationSettings
          },
          previewIndicatorConfigurations : {
               fontSize : "18.69px",
               ...filePreviewIndicatorConfigurations
          },
          previewIconConfigurations : {
               style : {
                    height : "3.469rem",
                    width : "3.469rem"
               },
               ...filePreviewIconConfigurations
          },
          playOnHover : playVideoOnHover,
          playOnClick : playVideoOnClick,
          autoPlay : autoPlayVideo,
          hoverDelay : 1569,
          videoSRC : file.url,
          type : "video"
     };
     
     return (
          <motion.div
               key = {index}
               
               variants = {itemVariants(index)}
               
               initial = "hidden"
               
               animate = "visible"
               
               style = {{
                    height : "227px",
                    width : "auto",
                    ...pbgFileCellContainerConfigurationSettings
               }}
          >
               <HUDGridItem
                    gridItemConfigurationSettings = {{
                         position : "relative",
                         display : 'flex',
                         alignItems : 'center',
                         justifyContent : 'center',
                         overflowY : "hidden",
                         overflowX : "hidden",
                         msOverflowStyle : "none",
                         scrollbarWidth : "none",
                         cursor : "pointer",
                         transition: 'border 0.269s ease, scale 0.269s ease, transform 0.269s ease, opacity 0.269s ease',
                         fontSize: '18px',
                         fontWeight: 'bold',
                         border: fileHovering !== null ? `1px solid ${paletteColor(palette, "PatrolBuddyLights.color2", patrolBuddyGoLoadingColor)}` : `0.469px solid ${paletteColor(palette, "PatrolBuddyAccent.color2", patrolBuddyGoLoadingColor)}`,
                         borderRadius: '10px',
                         boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                         color: '#FFFFFF',
                         backgroundColor: paletteColor(palette, "Interface.color3", patrolBuddyGoLoadingColor),
                         scale : fileHovering !== null ? "1.0169" : "1",
                         height : "calc(100%)",
                         width : "22.69rem",
                         paddingBottom : "1.69px",
                         paddingTop : "1.69px",
                         ...pbgFileConfigurationSettings
                    }}
                    
                    onMouseLeave = {() => {
                         setFileHovering(null);
                    }}
                    
                    onMouseEnter = {() => {
                         setFileHovering(index);
                    }}
               >
                    {showHeaderBar ? (
                    <HUDHeaderBar
                         openIconConfigurationSettings = {{
                              left : "calc(91.69%)"
                         }}
                    >
                         <HUDIcon
                              iconConfigurationSettings = {{
                                   cursor : "pointer",
                                   zIndex : 12769,
                              }}
                              
                              name = "external-link"
                              
                              onClick = {() => {
                                   const fileData = {
                                        fileID : `${file.name}_${HUDUniversalHUDUtilityManager.GenerateUUID()}`,
                                        headerContent : (
                                             <div
                                                  style = {{
                                                       position : "relative",
                                                       display : "flex",
                                                       flexDirection : "row"
                                                  }}
                                             >
                                                  <label
                                                       style = {{
                                                            position : "relative",
                                                            fontWeight : "769",
                                                            fontSize : "1.269rem",
                                                            color : "white"
                                                       }}
                                                  >
                                                       {file.name}
                                                  </label>
                                             </div>
                                        ),
                                        footerContent : (
                                             <div
                                                  style = {{
                                                       position : "relative",
                                                       display : "flex",
                                                       flexDirection : "row"
                                                  }}
                                             >
                                                  <label
                                                       style = {{
                                                            position : "relative",
                                                            fontWeight : "769",
                                                            fontSize : "0.969rem",
                                                            color : "white"
                                                       }}
                                                  >
                                                       {ConvertDateTime(file.lastModified, true, false, true, true)}
                                                  </label>
                                             </div>
                                        ),
                                        content : (
                                             <PatrolBuddyDataViewer
                                                  videoConfigurationSettings = {pbgFileVideoConfigurationSettings}
                                                  
                                                  file = {file}
                                                  
                                                  signedUser = {signedUser}
                                                  
                                                  signedIn = {signedIn}
                                             />
                                        ),
                                        ...file
                                   };
                                   
                                   createWindow(fileData);
                                   
                                   return;
                              }}
                         />
                    </HUDHeaderBar>
                    ) : (
                    <>
                    </>
                    )}
                    
                    {DetermineFileType(file) !== "unknown" ? (
                         <div
                              onClick = {() => {
                                   if (!viewFile) { return; }
                                   
                                   viewFile(file);
                                   
                                   return;
                              }}
                         >
                              <HUDContentPreview
                                   {...(DetermineFileType(file) === "video" ? videoPreviewConfigurations : {})}
                              />
                         </div>
                    ) : (
                         <>
                         </>
                    )}
                    
                    <div
                         style = {{
                              position : "absolute",
                              display : "flex",
                              flexDirection : "column",
                              fontSize : "14.69px",
                              background : "rgba(0, 0, 0, 0.169)",
                              width : "calc(100%)",
                              paddingBottom : "0.69rem",
                              paddingLeft : "0.69rem",
                              paddingTop : "0.69rem",
                              bottom : 0,
                              ...pbgFileFooterContainerConfigurationSettings
                         }}
                         
                         onClick = {() => {
                              if (!viewFile) { return; }
                              
                              viewFile(file);
                              
                              return;
                         }}
                    >
                         {file.name.length > 27 ? file.name + "..." : file.name}
                         
                         <div
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   fontSize : "9.69px",
                                   width : "calc(100%)",
                                   paddingBottom : "0.269rem",
                                   paddingLeft : "0.69rem",
                                   paddingTop : "0.369rem",
                                   bottom : 0,
                                   ...pbgFileDateContainerConfigurationSettings
                              }}
                         >
                              {fileHovering ? ConvertDateTime(file.lastModified, true, false, true, false) : ConvertDateTime(file.lastModified, true, false, true, true)}
                         </div>
                    </div>
               </HUDGridItem>
          </motion.div>
     );
};


export default PBGFileCell;