import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation, PatrolBuddyGoSupportChatPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import HUDIoTide from "@trap_stevo/iotide-client";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











const supportIO = new HUDIoTide();

function PatrolBuddyGoSupportAddChatPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Add Chat");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [creatingChat, setCreatingChat] = useState(false);
     
     const { getFromSessionCache, addToSessionCache, clearSessionCache } = useHUDSession();
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [titleInputInFocus, setTitleInputInFocus] = useState(false);
     
     const [messageInputInFocus, setMessageInputInFocus] = useState(false);
     
     const [titleInputValue, setTitleInputValue] = useState("");
     
     const [messageInputValue, setMessageInputValue] = useState("");
     
     const [messageLineCount, setMessageLineCount] = useState(1);
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const createChatButtonRef = useRef(null);
     
     const messageInputRef = useRef(null);
     
     const titleInputRef = useRef(null);
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible, true);
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     const createSupportChat = async () => {
          setProcessIndicatorTitle("Creating Chat...");
          
          setCreatingChat(true);
          
          const currentUTCDateTime = new Date().toUTCString();
          
          const currentUTCTimeInMilliseconds = new Date(currentUTCDateTime).getTime();
          
          const currentUTCTimeInSeconds = currentUTCTimeInMilliseconds / 1000;
          
          const message = messageInputValue;
          
          const chatData = {
               ChatTitle  : titleInputValue, 
               Message : message, 
               Date : currentUTCTimeInSeconds
          };
          
          const userData = {
               access : signedUser.accessLevel,
               username : signedUser.username,
               userID : signedUser.userID
          };
          
          supportIO.emitEvent("supportNet", "create-support-chat", { ...userData, sentData : chatData });
          
          setTitleInputValue("");
          
          setMessageInputValue("");
          
          return;
     };
     
     const adjustMessageInputHeight = () => {
          const textArea = messageInputRef.current;
          
          if (!textArea) { return; }
          
          textArea.style.transition = 'none';
          
          textArea.style.height = 'auto';
          
          const scrollHeight = textArea.scrollHeight;
          
          if (messageLineCount <= 21 && messageInputValue.length > 0)
          {
               textArea.style.height = `${scrollHeight}px`;
               
               textArea.style.marginTop = -`${scrollHeight}rem`;
               
               textArea.style.marginBottom = "3rem";
          }
          
          textArea.style.transition = '';
          
          return;
     };
     
     const handleTitleInputInFocus = (e) => {
          setTitleInputInFocus(true);
          
          return;
     };
     
     const handleTitleInputBlur = (e) => {
          setTitleInputInFocus(false);
          
          return;
     };
     
     const handleMessageInputInFocus = (e) => {
          setMessageInputInFocus(true);
          
          return;
     };
     
     const handleMessageInputBlur = (e) => {
          setMessageInputInFocus(false);
          
          return;
     };
     
     const handleTitleInputChange = (e) => {
          setTitleInputValue(e.target.value);
          
          return;
     };
     
     const handleMessageInputChange = (e) => {
          setMessageInputValue(e.target.value);
          
          return;
     };
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          const timer = setTimeout(() => {
               if (titleInputRef.current === null || titleInputRef.current === undefined) { return; }
               
               titleInputRef.current.focus();
               
               return;
          }, 100);
          
          return () => clearTimeout(timer);
     }, []);
     
     useEffect(() => {
          const lineCount = messageInputValue.split('\n').length;
          
          setMessageLineCount(lineCount);
          
          adjustMessageInputHeight();
          
          return () => {};
     }, [messageInputValue]);
     
     useEffect(() => {
          if (titleInputRef.current === null || titleInputRef.current === undefined || messageInputRef.current === null || messageInputRef.current === undefined) { return; }
          
          if (sidePanelVisible && titleInputValue === "")
          {
               titleInputRef.current.blur();
          }
          
          if (sidePanelVisible && messageInputValue === "")
          {
               messageInputRef.current.blur();
               
               return;
          }
          
          if (titleInputValue === "")
          {
               titleInputRef.current.focus();
               
               return;
          }
          
          if (messageInputValue === "")
          {
               messageInputRef.current.focus();
          }
          
          return;
     }, [sidePanelVisible]);
     
     useEffect(() => {
          if (creatingChat === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return () => {};
     }, [creatingChat]);
     
     useEffect(() => {
          supportIO.createIO("supportNet", "https://utilities.patrolbuddygo.com:8699");
          
          supportIO.onEvent("supportNet", "connect", () => {
               console.log("Connected to support net!");
          });
          
          supportIO.onEvent("supportNet", "chatCreated", (incomingData) => {
               try
               {
                    const chatCreationData = JSON.parse(incomingData);
                    
                    if (chatCreationData.status === "success")
                    {
                         const chatCreated = setTimeout(() => {
                              setProcessIndicatorTitle("Chat Creation Successful!");
                              
                              setCreatingChat(false);
                              
                              return;
                         }, 500);
                         
                         const openingChat = setTimeout(() => {
                              console.log(chatCreationData.onGoingChat);
                              
                              localStorage.setItem("CurrentSupportChat", JSON.stringify(chatCreationData.onGoingChat));
                              
                              supportIO.closeSocket("supportNet");
                              
                              PatrolBuddyGoSupportChatPanelNavigationActivation(pbgPanelNavigator);
                              
                              return;
                         }, 699);
                         
                         return () => {
                              clearInterval(chatCreated);
                              
                              clearInterval(openingChat);
                         };
                    }
                    else if (chatCreationData.status !== "success")
                    {
                         setProcessIndicatorTitle("Try Again.");
                         
                         setCreatingChat(false);
                         
                         return;
                    }
               }
               catch (error)
               {
                    return;
               }
          });
          
          return () => {
               supportIO.closeSocket("supportNet");
          };
     }, [signedUser, supportIO]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return () => {};
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <PatrolBuddySidePanel
                    currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    setSidePanelVisible = {setSidePanelVisible}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    sidePanelRef = {sidePanelRef}
                    
                    containsTopHeader = {true}
               >
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out, margin-top 0.169s ease",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              fontSize : patrolBuddyGoTitleHover ? "1.269rem" : "1.069rem",
                              color : patrolBuddyGoTitleHover ? paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor) : processIndicatorColor,
                              marginTop : titleInputValue.trim() !== "" && messageInputValue.trim() !== "" && titleInputValue !== null && messageInputValue !== null ? "84.69vh" : "95.69vh"
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoTitleHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoTitleHover(true);
                         }}
                    >
                         <HUDTyper speed = {processIndicatorTypeSpeed} >
                              {processIndicatorTitle}
                         </HUDTyper>
                    </label>
               </PatrolBuddySidePanel>
               
               <label
                    style = {{
                         position : "relative",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         fontSize : "1.69rem",
                         color : colorTransparency(paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor), 0.469),
                         transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                         visibility : creatingChat ? "hidden" : "visible",
                         width : "calc(69%)",
                         marginTop : "5.69rem",
                         flexShrink : 0
                    }}
               >
                    <HUDTyper speed = {21} >
                         Fill in the designated information that describes your support desires... 
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         transition : "height 0.869s ease-in-out",
                         height : creatingChat ? "0rem" : null,
                         width : "calc(94.69%)",
                         borderRadius : "1rem",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         background : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                         marginBottom : "1rem",
                         marginTop : "2.69rem",
                    }}
               >
                    <div
                         className = "invisa-scroller"
                         
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "column",
                              alignItems : "center",
                              height : creatingChat ? "0rem" : "auto",
                              transition : creatingChat ? "height 0.869s ease-in-out" : "height 0.269s ease-in",
                              width : "calc(98.69%)",
                              overflowY : "scroll",
                              overflowX : "hidden",
                              borderRadius : "1rem",
                              boxShadow : "0 0 10px rgba(0, 0, 0, 0.469)",
                              background : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color4", patrolBuddyGoLoadingColor), 0.169),
                              marginBottom : "1rem",
                              marginRight : "1rem",
                              marginLeft : "1rem",
                              marginTop : "0.869rem",
                         }}
                    >
                    <input
                         className = "hud-input-light"
                         
                         type = "text"
                         
                         ref = {titleInputRef}
                         
                         value = {titleInputValue}
                         
                         onChange = {handleTitleInputChange}
                         
                         onFocus = {handleTitleInputInFocus}
                         
                         onBlur = {handleTitleInputBlur}
                         
                         placeholder = "Enter a descriptive title for your support chat..."
                         
                         style = {{ 
                              background : "transparent",
                              borderRadius : "2rem",
                              height : "2.969rem",
                              outline : "none",
                              borderLeft : "none",
                              borderTop : "none",
                              boxShadow : titleInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                              borderBottom : titleInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderRight : titleInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              color : titleInputInFocus ? "#C9F5FF" : "#A5A5A5",
                              scale : titleInputInFocus ? "1.069" : "1",
                              transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                              visibility : creatingChat ? "hidden" : "visible",
                              opacity : creatingChat ? 0 : 1,
                              fontFamily : "ModeNine",
                              fontSize : "1.269rem",
                              width : "calc(57%)",
                              paddingRight : "1rem",
                              paddingLeft : "1rem",
                              marginTop : "3.69rem",
                         }}
                    />
                    
                    <textarea
                         className = "hud-input-light"
                         
                         type = "text"
                         
                         ref = {messageInputRef}
                         
                         value = {messageInputValue}
                         
                         onChange = {handleMessageInputChange}
                         
                         onFocus = {handleMessageInputInFocus}
                         
                         onBlur = {handleMessageInputBlur}
                         
                         rows = {messageLineCount}
                         
                         placeholder = "Type your first message..."
                         
                         style = {{ 
                              background : "transparent",
                              borderRadius : "2rem",
                              height : "2.969rem",
                              outline : "none",
                              borderLeft : "none",
                              borderTop : "none",
                              boxShadow : messageInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                              borderBottom : messageInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderRight : messageInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              color : messageInputInFocus ? "#C9F5FF" : "#A5A5A5",
                              scale : messageInputInFocus ? "1.069" : "1",
                              transition : "scale 0.3s ease-in-out, opacity 0.69s ease-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                              visibility : creatingChat ? "hidden" : "visible",
                              opacity : creatingChat ? 0 : 1,
                              fontFamily : "ModeNine",
                              fontSize : "1.269rem",
                              width : "calc(57%)",
                              resize : "none",
                              paddingBottom : "0.69rem",
                              paddingRight : "1rem",
                              paddingLeft : "1rem",
                              paddingTop : "0.69rem",
                              marginBottom : "3rem",
                              marginTop : "3rem",
                         }}
                    />
                    </div>
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               {titleInputValue.trim() !== "" && messageInputValue.trim() !== "" && titleInputValue !== null && messageInputValue !== null && (
               <div
                    className = "invisa-scroller"
                    
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "row",
                         justifyContent : "center",
                         alignItems : "center",
                         height : "7.469rem",
                         width : "calc(100%)",
                         background : "rgba(0, 0, 0, 0.269)",
                         visibility : creatingChat ? "hidden" : "visible",
                         borderRadius : "1rem 1rem 0rem 0rem",
                         overflowY : "auto",
                         zIndex : "69",
                         bottom : 0
                    }}
               >
                    <div
                         ref = {createChatButtonRef}
                         
                         style = {{
                              position : "fixed",
                              display : "flex",
                              flexDirection : "row",
                              alignItems : "center",
                              height : "3.469rem",
                              width : "auto",
                              scale : patrolBuddyGoActionButtonHover ? "1.169" : "1",
                              transition : "scale 0.3s ease-in-out, background 0.69s ease-in-out, box-shadow 0.69s ease-in-out",
                              background : patrolBuddyGoActionButtonHover ? "#9FD1D9" : "#575757",
                              borderRadius : "1.269rem",
                              boxShadow : patrolBuddyGoActionButtonHover ? "0 0 10px rgba(255, 255, 255, 0.69)" : "inset 0 0 10px rgba(0, 0, 0, 0.69)",
                              marginBottom : "1.69rem",
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoActionButtonHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoActionButtonHover(true);
                         }}
                         
                         onClick = {createSupportChat}
                    >
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bold",
                                   fontSize : "1.469rem",
                                   color : "#FFFFFF",
                                   marginRight : "1.69rem",
                                   marginLeft : "1.69rem",
                                   flexShrink : 0
                              }}
                          >
                              <HUDTyper speed = {2} >
                                   Create Chat!
                              </HUDTyper>
                          </label>
                    </div>
               </div>
               )}
               
               <div
                    style = {{
                         position : "relative",
                         height : "7.69rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoSupportAddChatPanel;