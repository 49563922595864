import React from "react";

import { useState, useEffect } from "react";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDRTSCViewer } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";











function PatrolCamViewer(hudVideoPlayerConfigurationSettings)
{
  const {
       viewerLoadingIndicatorConfigurationSettings = {},
       viewerContainerConfigurationSettings = {},
       videoContainerConfigurationSettings = {},
       videoControlsConfigurationSettings = {},
       videoFileConfigurationSettings = {},
       messageConfigurationSettings = {},
       overlayConfigurationSettings = {},
       videoConfigurationSettings = {},
       clientStreamSettings = {},
       clientDetails = {},
       onVideoDuration,
       onVideoProgress,
       onVideoReady, 
       onVideoStart, 
       onVideoError,
       onVideoSeek,
       playsInline = true,
       controls = true,
       light = false,
       muted = false,
       volume = 0.8,
       preventCameraSignalConnections = false,
       closeCameraSignalConnection = null,
       closeCameraConnection = 0,
       onPatrolCamStreaming = null,
       onWebRTSPIdle = null,
       streaming = true,
       visionizerSize,
       nightModeActivated = false,
       windowMaximized = false,
       previewMode = false,
       overlayMode = false,
       zoneMode = false,
       sessionID = "",
       height = 1080,
       width = 1280,
       streamsSRC,
       signalSRC,
       videoSRC,
       children,
       ...rest
  } = hudVideoPlayerConfigurationSettings;
  
  const { palette, paletteColor, colorTransparency } = useHUDPalette();
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div 
         style = {{ 
              position : "relative",
              display : "flex",
              justifyContent : "center",
              alignItems : "center",
              clipPath : "inset(0% round 15px)",
              background : "rgba(3, 3, 3, 1)",
              height : "calc(100%)",
              width : previewMode ? "calc(100%)" : "1920px",
              ...videoContainerConfigurationSettings
         }}
    >
         <div
              style = {{
                   position : "absolute",
                   pointerEvents : "none",
                   transition : "all 0.69s ease",
                   mixBlendMode : streaming && nightModeActivated ? "screen" : undefined,
                   filter : streaming ? "brightness(1.0)" : undefined,
                   backgroundColor : streaming && nightModeActivated ? "rgba(255, 255, 255, 0.1169)" : "transparent",
                   height : "calc(100%)",
                   width : previewMode ? "calc(100%)" : "1920px",
                   zIndex : 2769,
                   bottom : previewMode ? 0 : "calc(12.69%)",
                   left : 0
              }}
         />
         
         <HUDRTSCViewer
              videoContainerConfigurationSettings = {{
                   transition : "all 0.69s ease",
                   filter : streaming && nightModeActivated ? "brightness(2.569) saturate(1.169) hue-rotate(0deg) sepia(0.169) contrast(1.0)" : undefined,
                   ...videoConfigurationSettings
              }}
              
              videoConfigurationSettings = {{
                   boxShadow : `0 0 12.69px ${colorTransparency(paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor), 0.569)}`,
              }}
              
              loadingSpinnerConfigurationSettings = {{
                   height : "47px",
                   width : "47px",
                   marginTop : "0rem",
                   ...viewerLoadingIndicatorConfigurationSettings
              }}
              
              containerConfigurationSettings = {{
                   height : "calc(100%)", 
                   width : "calc(100%)",
                   zIndex : 0,
                   ...viewerContainerConfigurationSettings
              }}
              
              overlayConfigurationSettings = {{
                   position : "absolute",
                   bottom : 0,
                   right : 0,
                   left : 0,
                   top : 0,
                   ...overlayConfigurationSettings
              }}
              
              messageConfigurationSettings = {{
                   fontSize : "1.269rem",
                   ...messageConfigurationSettings
              }}
              
              preventSignalConnections = {preventCameraSignalConnections}
              
              closeSignalConnection = {closeCameraSignalConnection}
              
              triggerConnectionClosure = {closeCameraConnection}
              
              useRTSPToWebConnection = {true}
              
              webRTSPIdleTimeLimit = {30000}
              
              onWebRTSPStreaming = {onPatrolCamStreaming}
              
              onWebRTSPIdle = {onWebRTSPIdle}
              
              streamsURL = {streamsSRC}
              
              signalURL = {signalSRC}
              
              sessionID = {sessionID}
              
              rtspURL = {videoSRC}
              
              height = {height}
              
              width = {width}
         />
         
         {children}
    </div>
  );
}

export default PatrolCamViewer;
