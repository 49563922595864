import * as React from "react";

import { useEffect } from "react";

import { HUDUniversalHUDUtilityManager, DetermineFileType, ConvertDateTime } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDVideo, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";










function PatrolBuddyDataViewer(patrolBuddyDataViewerConfigurationSettings)
{
     const {
          videoConfigurationSettings = {},
          file,
          signedUser,
          signedIn
     } = patrolBuddyDataViewerConfigurationSettings;
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    marginBottom : "1.69rem"
               }}
          >
               {DetermineFileType(file) === "video" ? (
                    <HUDVideo
                         headerTitleConfigurationSettings = {{
                              color : "rgba(69, 215, 227, 1)"
                         }}
                         
                         downloadActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         downloadActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
           
                         downloadActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         downloadActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         downloadIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         volumeTrackThumbColor = {"rgba(227, 227.69, 227.69, 1)"}
                         
                         volumeTrackBarColor = {"rgba(115.69, 215, 227, 1)"}
                         
                         videoTrackThumbColor = {"rgba(227, 227.69, 227.69, 1)"}
                         
                         videoTrackBarColor = {"rgba(115.69, 215, 227, 1)"}
                         
                         pictureInPictureActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         pictureInPictureActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         pictureInPictureActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         pictureInPictureActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         pictureInPictureIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         fullScreenActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         fullScreenActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         fullScreenActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         fullScreenActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         fullScreenIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         subtitleActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         subtitleActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         subtitleActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         subtitleActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         subtitleIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         volumeActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         volumeActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         volumeActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         volumeActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         volumeIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         forwardActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         forwardActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         forwardActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         forwardActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         forwardIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         rewindActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         rewindActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         rewindActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         rewindActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         rewindIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         jumpToActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         jumpToActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         jumpToActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         jumpToActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         jumpToIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         playActionDestinationHoveredBackgroundColor = {"rgba(69, 215, 227, 0.3)"}
                         
                         playActionHoveredBackgroundColor = {"rgba(69, 215, 227, 0.1)"}
                         
                         playActionInitialHoveredBackgroundColor = {"rgba(69, 215, 227, 0.4)"}
                         
                         playActionInitialBackgroundColor = {"rgba(69, 215, 227, 0.2)"}
                         
                         playIconColor = {"rgba(69, 215, 227, 1)"}
                         
                         pictureInPictureToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         fullScreenToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         subtitleToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         downloadToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         forwardToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         rewindToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         jumpToToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         volumeToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         playToolTipConfigurationSettings = {{
                              color : "rgba(127.69, 215, 227, 1)"
                         }}
                         
                         showDownloadButton = {signedUser && signedUser.accessLevel && signedUser.accessLevel.accountAccessR >= 2 ? true : false}
                         
                         source = {file.url}
                         
                         title = {file.name}
                         
                         {...videoConfigurationSettings}
                    />
               ) : (
                    <>
                    </>
               )}
          </div>
     );
};

export default PatrolBuddyDataViewer;