import * as React from "react";

import { useState, useEffect } from "react";

import { HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDTracker } from "@trap_stevo/legendarybuilderproreact-ui";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";











function PatrolBuddyCell(patrolBuddyCellConfigurationSettings)
{
     const {
          patrolBuddyCellContainerConfigurationSettings = {},
          patrolBuddyCellContainerConfigurations = {},
          patrolBuddyCellHeaderContent = null,
          patrolBuddyCellContent = null,
          patrolBuddyData,
          showDisplayName = false,
          onClick,
          index
     } = patrolBuddyCellConfigurationSettings;
     
     const [cellHover, setCellHover] = useState(false);
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     
     
     
     
     
     
     
     
     
     
     return (
          <>
          {patrolBuddyData ? (
               <div
                    className = "invisa-scroller"
                    
                    key = {index}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         flexShrink : 0,
                         justifyContent : "center",
                         alignItems : "center",
                         msOverflowStyle : "none",
                         scrollbarWidth : "none",
                         overflowY : "scroll",
                         overflowX : "hidden",
                         cursor : "pointer",
                         transition : "all 0.269s ease-in",
                         boxShadow : cellHover ? `inset 0 0 10px ${colorTransparency(paletteColor(palette, "Interface.color12", patrolBuddyGoLoadingColor), 0.469)}` : `inset 0 0 10px ${colorTransparency(paletteColor(palette, "Interface.color25", patrolBuddyGoLoadingColor), 0.469)}`,
                         borderRadius : "1rem",
                         background : cellHover ? colorTransparency(paletteColor(palette, "Interface.color5", patrolBuddyGoLoadingColor), 0.269) : colorTransparency(paletteColor(palette, "Interface.color25", patrolBuddyGoLoadingColor), 0.269),
                         height : "calc(100%)",
                         width : "calc(15.69%)",
                         scale : cellHover ? "1.0169" : "1",
                         gap : "0.269rem", 
                         paddingBottom : "20px",
                         paddingTop : "20px",
                         marginRight : "0.269rem",
                         ...patrolBuddyCellContainerConfigurationSettings
                    }}
                    
                    onMouseLeave = {() => {
                         setCellHover(false);
                         
                         return;
                    }}
                    
                    onMouseEnter = {() => {
                         setCellHover(true);
                         
                         return;
                    }}
                    
                    onClick = {() => {
                         if(onClick)
                         {
                              onClick(patrolBuddyData, index);
                         }
                         
                         return;
                    }}
                    
                    {...patrolBuddyCellContainerConfigurations}
               >
                    {patrolBuddyCellHeaderContent && patrolBuddyCellHeaderContent}
                    
                    <img
                         style = {{
                              position : "relative",
                              objectFit : "contain",
                              userSelect : "none",
                              transition : "all 0.269s ease",
                              boxShadow : cellHover ? `0px 0px 12.69px -3.69px ${colorTransparency(paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor), 0.69)}` : "none",
                              borderRadius : "calc(50%)",
                              background : cellHover ? paletteColor(palette, "PatrolBuddyAccent.color4", patrolBuddyGoLoadingColor) : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color4", patrolBuddyGoLoadingColor), 0.469),
                              height : "4rem",
                              width : "4rem",
                              padding : "5px"
                         }}
                         
                         src = {require("../HUDContent/HUDPatrolBuddyGoIcon.png")}
                    />
                    
                    {showDisplayName ? (
                         <label
                              style = {{
                                   position : "relative",
                                   flexShrink : 0,
                                   userSelect : "none",
                                   cursor : "pointer",
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   textAlign : "center",
                                   wordWrap : "break-word",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   fontSize : "0.69rem",
                                   color : paletteColor(palette, "PatrolBuddyLightAccent.color4", patrolBuddyGoLoadingColor),
                                   width : "calc(91.69%)",
                                   marginTop : "0.469rem"
                              }}
                         >
                              <HUDTyper speed = {7} >
                                   {patrolBuddyData.DisplayName}
                              </HUDTyper>
                         </label>
                    ) : null}
                    
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              userSelect : "none",
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              textAlign : "center",
                              wordWrap : "break-word",
                              fontFamily : "ModeNine",
                              fontWeight : "469",
                              fontStyle : "italic",
                              fontSize : "0.5269rem",
                              color : paletteColor(palette, "PatrolBuddyAccent.color10", patrolBuddyGoLoadingColor),
                              width : "calc(91.69%)",
                              marginTop : "0.469rem"
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {patrolBuddyData.SerialKey}
                         </HUDTyper>
                    </label>
                    
                    <div
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              userSelect : "none",
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              borderRadius : "0.69rem",
                              background : paletteColor(palette, "PatrolBuddyAccent.color10", patrolBuddyGoLoadingColor),
                              height : "0.0169rem",
                              width : "calc(91.69%)",
                              marginBottom : "0.269rem",
                              marginTop : "0.469rem"
                         }}
                    />
                    
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              userSelect : "none",
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              textAlign : "center",
                              wordWrap : "break-word",
                              fontFamily : "ModeNine",
                              fontWeight : "469",
                              fontStyle : "italic",
                              fontSize : "0.5269rem",
                              color : paletteColor(palette, "PatrolBuddyAccent.color10", patrolBuddyGoLoadingColor),
                              width : "calc(91.69%)",
                              marginTop : "0.269rem"
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {patrolBuddyData.Jurisdiction}
                         </HUDTyper>
                    </label>
                    
                    {patrolBuddyCellContent && patrolBuddyCellContent}
               </div>
          ) : (
               <div
                    className = "invisa-scroller"
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         flexShrink : 0,
                         justifyContent : "center",
                         alignItems : "center",
                         msOverflowStyle : "none",
                         scrollbarWidth : "none",
                         overflowY : "scroll",
                         overflowX : "hidden",
                         cursor : "pointer",
                         transition : "all 0.269s ease-in",
                         boxShadow : cellHover ? `inset 0 0 10px ${colorTransparency(paletteColor(palette, "Interface.color12", patrolBuddyGoLoadingColor), 0.469)}` : `inset 0 0 10px ${colorTransparency(paletteColor(palette, "Interface.color25", patrolBuddyGoLoadingColor), 0.469)}`,
                         borderRadius : "1rem",
                         background : cellHover ? colorTransparency(paletteColor(palette, "Interface.color5", patrolBuddyGoLoadingColor), 0.269) : colorTransparency(paletteColor(palette, "Interface.color25", patrolBuddyGoLoadingColor), 0.269),
                         height : "calc(100%)",
                         width : "calc(15.69%)",
                         scale : cellHover ? "1.0169" : "1",
                         gap : "0.269rem", 
                         paddingBottom : "20px",
                         paddingTop : "20px",
                         marginRight : "0.269rem",
                         ...patrolBuddyCellContainerConfigurationSettings
                    }}
                    
                    onMouseLeave = {() => {
                         setCellHover(false);
                         
                         return;
                    }}
                    
                    onMouseEnter = {() => {
                         setCellHover(true);
                         
                         return;
                    }}
                    
                    onClick = {() => {
                         if(onClick)
                         {
                              onClick(patrolBuddyData, index);
                         }
                         
                         return;
                    }}
                    
                    {...patrolBuddyCellContainerConfigurations}
               >
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              userSelect : "none",
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              textAlign : "center",
                              wordWrap : "break-word",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              fontSize : "0.69rem",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color4", patrolBuddyGoLoadingColor),
                              width : "calc(100%)"
                         }}
                    >
                         <HUDTyper speed = {7} >
                              N/A
                         </HUDTyper>
                    </label>
          </div>
          )}
          </>
     );
};

export default PatrolBuddyCell;