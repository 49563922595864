import * as React from "react";











var processingOperations = new Map();

class HUDUtilityManager  
{ 
     static checkOperation(operationID, duration = 100)
     {
          if (processingOperations.get(operationID)) { return true; }
          
          processingOperations.set(operationID, true);
          
          setTimeout(() => {
               processingOperations.delete(operationID);
          }, duration);
          
          return false;
     }
};

export default HUDUtilityManager;
