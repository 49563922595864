import axios from "axios";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDEventManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { getAPIClient } from "./HUDAPIClientsManager.js";

import HUDUtilityManager from "./HUDUtilityManager.js";











class PatrolBuddyGoHUDPatrolBuddyDepartmentsManager  
{
   static GetPatrolBuddiesInPrecinct(address) 
   {
        if (HUDUtilityManager.checkOperation("gettingPatrolBuddiesInPrecinct")) { return null; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbgs");
        
        return patrolBuddyGoDataManagerAPI.get(`/get-pbgs-in-precinct`, {
            params : {
                 inputAddress : address
            }
        }).then((ress) => {
            return ress.data;
        })
        .catch(() => {
            return null;
        });
   }
   
   static GetPatrolBuddiesInCounty(localityDomain = "county", locality) 
   {
        if (HUDUtilityManager.checkOperation("gettingPatrolBuddiesInCounty")) { return null; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbgs");
        
        return patrolBuddyGoDataManagerAPI.get(`/get-pbgs-in-locality`, {
            params : {
                 inputLocalityDomain : localityDomain,
                 inputLocality : locality
            }
        }).then((ress) => {
            return ress.data;
        })
        .catch(() => {
            return null;
        });
   }
} 

export default PatrolBuddyGoHUDPatrolBuddyDepartmentsManager; 
