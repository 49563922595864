import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { enableScrolling } from "@trap_stevo/legendarybuilderproreact-ui/HUDConfigurationUtilities";

import { HUDUniversalHUDUtilityManager, HUDOperationsUtilityManager, Delay, Download, ConvertDateTime, ConvertFileSizeToDisplay } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { HUDLoading, HUDSideContainerPanel, HUDMarkdownViewer, HUDWindow, HUDToolTip, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { Loml } from "@trap_stevo/loml";

import PatrolBuddyDataViewer from "../HUDComponents/PatrolBuddyDataViewer.js";











const loml = new Loml();

function PatrolBuddyDataView(patrolBuddyDataDashboardViewConfigurationSettings)
{
     const {
          videoConfigurationSettings = {},
          colorTransparency,
          paletteColor,
          palette,
          sidePanelVisible,
          createWindow,
          setFiles,
          setFile,
          file,
          type,
          signedUser,
          signedIn,
     } = patrolBuddyDataDashboardViewConfigurationSettings;
     
     const [detailsSidePanelButtonHover, setDetailsSidePanelButtonHover] = useState(false);
     
     const [detailsSidePanelVisible, setDetailsSidePanelVisible] = useState(false);
     
     const [fileActionButtonHovered, setFileActionButtonHovered] = useState(null);
     
     const [currentFileDetails, setCurrentFileDetails] = useState("");
     
     const [processingAction, setProcessingAction] = useState(null);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     const [fileActionButtons, setFileActionButtons] = useState([
          {
               iconHoverColor : paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor),
               iconColor : paletteColor(palette, "PatrolBuddyLights.color15", patrolBuddyGoLoadingColor),
               accessLevel : 2,
               iconName : "download",
               toolTipText : "Download",
               name : "Download",
               hudAction : async () => {
                    setProcessingAction("Downloading...");
                    
                    await Download({ fileType : type === "video" ? "mp4" : type, fileName : file.name, data : file.url });
                    
                    setProcessingAction(null);
               }
          },
          {
               iconHoverColor : paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor),
               iconColor : paletteColor(palette, "PatrolBuddyLights.color15", patrolBuddyGoLoadingColor),
               accessLevel : 0,
               iconName : "external-link",
               toolTipText : "Opens in an external window!",
               name : "Window Mode",
               hudAction : () => {
                    if (HUDOperationsUtilityManager.checkOperation("Opening file windowed mode")) { return; }
                    
                    const fileData = {
                                        fileID : `${file.name}_${HUDUniversalHUDUtilityManager.GenerateUUID()}`,
                                        headerContent : (
                                             <div
                                                  style = {{
                                                       position : "relative",
                                                       display : "flex",
                                                       flexDirection : "row"
                                                  }}
                                             >
                                                  <label
                                                       style = {{
                                                            position : "relative",
                                                            fontWeight : "769",
                                                            fontSize : "1.269rem",
                                                            color : "white"
                                                       }}
                                                  >
                                                       {file.name}
                                                  </label>
                                             </div>
                                        ),
                                        footerContent : (
                                             <div
                                                  style = {{
                                                       position : "relative",
                                                       display : "flex",
                                                       flexDirection : "row"
                                                  }}
                                             >
                                                  <label
                                                       style = {{
                                                            position : "relative",
                                                            fontWeight : "769",
                                                            fontSize : "0.969rem",
                                                            color : "white"
                                                       }}
                                                  >
                                                       {ConvertDateTime(file.lastModified, true, false, true, true)}
                                                  </label>
                                             </div>
                                        ),
                                        content : (
                                             <PatrolBuddyDataViewer
                                                  file = {file}
                                                  
                                                  signedUser = {signedUser}
                                                  
                                                  signedIn = {signedIn}
                                             />
                                        ),
                                        ...file
                    };
                    
                    createWindow(fileData);
                    
                    return;
               }
          }
     ]);
     
     const dataViewPanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearEdge(e, detailsSidePanelVisible ? 427 : 3.69, "right", dataViewPanelRef, setDetailsSidePanelVisible);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          setCurrentFileDetails(loml.bold("File Details", { fontSize : "1.869rem" })
                                    .br()
                                    .br()
                                    .bold("Created on: ", { fontSize : "1.269rem" })
                                    .gradientText(ConvertDateTime(file.lastModified), [paletteColor(palette, "PatrolBuddyLightAccent.color7", patrolBuddyGoLoadingColor), `${paletteColor(palette, "PatrolBuddyLightAccent.color12", patrolBuddyGoLoadingColor)} 115.69%`])
                                    .br()
                                    .br()
                                    .bold(`Size: `)
                                    .gradientText(`${ConvertFileSizeToDisplay(file.size)}`, [paletteColor(palette, "PatrolBuddyLightAccent.color7", patrolBuddyGoLoadingColor), `${paletteColor(palette, "PatrolBuddyLightAccent.color12", patrolBuddyGoLoadingColor)} 115.69%`])
                                    .build());
          
          loml.clear();
     }, []);
     
     return (
          <div
               ref = {dataViewPanelRef}
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    justifyContent : "center",
                    alignItems : "center",
                    overflowY : "auto",
                    msOverflowStyle : "none",
                    scrollbarWidth : "none",
                    height : "calc(100%)",
                    width : "100vw"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               <div
                    style = {{
                         position : "fixed",
                         display : "flex",
                         transition : "all 0.69s ease",
                         zIndex : 12869,
                         left : sidePanelVisible ? "14.69rem" : "1.869rem",
                         top : "0.69rem"
                    }}
               >
                    <HUDToolTip
                         toolTipConfigurationSettings = {{
                              backgroundColor : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color5", patrolBuddyGoLoadingColor), 0.69),
                              marginLeft : "0.469rem"
                         }}
                         
                         text = "Back"
                         
                         textSpeed = {5.69}
                         
                         position = "right"
                    >
                         <HUDIcon
                              iconConfigurationSettings = {{
                                   display : "flex",
                                   cursor : "pointer",
                                   transition : "all 0.369s ease",
                                   borderRadius : "0.969rem",
                                   background : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color19", patrolBuddyGoLoadingColor), 0.69),
                                   scale : backButtonHover ? "1.169" : "1",
                                   padding : "0.469rem",
                                   paddingRight : "0.569rem",
                                   paddingLeft : "0.569rem"
                              }}
                              
                              color = {backButtonHover ? paletteColor(palette, "PatrolBuddyLightAccent.color12", patrolBuddyGoLoadingColor) : paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}
                              
                              name = "fa-chevron-left"
                              
                              onMouseLeave = {() => {
                                   setBackButtonHover(false);
                                   
                                   return;
                              }}
                              
                              onMouseEnter = {() => {
                                   setBackButtonHover(true);
                                   
                                   return;
                              }}
                              
                              onClick = {() => setFile(null)}
                         />
                    </HUDToolTip>
               </div>
               
               <HUDSideContainerPanel
                    sideContainerConfigurationSettings = {{
                         transition : "all 0.369s ease",
                         background : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color19", patrolBuddyGoLoadingColor), 0.769),
                         width : detailsSidePanelVisible ? "369.69px" : "27.69px",
                         paddingLeft : "0.69rem"
                     }}
                     
                     controlledOpen = {true}
                     
                     displayCloseButton = {false}
                     
                     displayBackDrop = {false}
                     
                     position = "right"
               >
                     <div
                          style = {{
                               position : "relative",
                               display : "flex",
                               flexDirection : "column",
                               width : "calc(100%)",
                               marginRight : detailsSidePanelVisible ? "0.769rem" : "0rem",
                               marginLeft : detailsSidePanelVisible ? "0rem" : "0.0969rem"
                          }}
                     >
                          <HUDToolTip
                               toolTipConfigurationSettings = {{
                                    position : "fixed",
                                    backgroundColor : detailsSidePanelVisible ? "transparent" : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color5", patrolBuddyGoLoadingColor), 0.69)
                               }}
                               
                               text = {detailsSidePanelVisible ? "" : "Open Side Bar"}
                               
                               textSpeed = {5.69}
                               
                               position = "left"
                          >
                          <div
                               style = {{
                                    position : "relative",
                                    display : "flex",
                                    flexDirection : "row",
                                    justifyContent : detailsSidePanelVisible ? "flex-end" : undefined,
                                    alignItems : "center",
                                    cursor : "pointer",
                                    transition : "all 0.369s ease",
                                    scale : detailsSidePanelButtonHover ? "1.0169" : "1",
                                    gap : "10px",
                                    paddingBottom : "0.269rem",
                                    paddingTop : "0.269rem",
                                    marginBottom : "1.269rem",
                                    marginRight : detailsSidePanelVisible ? "1.069rem" : undefined
                               }}
                               
                               onMouseLeave = {() => {
                                    setDetailsSidePanelButtonHover(false);
                                    
                                    return;
                               }}
                               
                               onMouseEnter = {() => {
                                    setDetailsSidePanelButtonHover(true);
                                    
                                    return;
                               }}
                               
                               onClick = {() => {
                                    setDetailsSidePanelVisible((currentDetailsSidePanelVisible) => !currentDetailsSidePanelVisible);
                                    
                                    return;
                               }}
                          >
                                    <HUDIcon
                                         color = {detailsSidePanelVisible ? paletteColor(palette, "PatrolBuddyLightAccent.color7", patrolBuddyGoLoadingColor) : paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}
                                         
                                         name = {"fa-columns"}
                                    />
                          </div>
                          </HUDToolTip>
                          
                          <div
                               style = {{
                                    position : "relative",
                                    display : "flex",
                                    borderRadius : "0.69rem",
                                    background : paletteColor(palette, "PatrolBuddyAccent.color4", patrolBuddyGoLoadingColor),
                                    height : "0.169rem",
                                    width : "calc(100%)",
                                    marginBottom : "0.869rem",
                                    marginTop : "0.069rem"
                               }}
                          />
                          
                          <div
                               style = {{
                                    position : "relative",
                                    display : "flex",
                                    flexDirection : "column",
                                    gap : "1.269rem"
                               }}
                          >
                          {fileActionButtons.length > 0 && fileActionButtons.map((fileActionButton, index) => (
                               <>
                               {signedUser && signedUser.accessLevel && signedUser.accessLevel.accountAccessR >= fileActionButton.accessLevel ? (
                               <HUDToolTip
                               toolTipConfigurationSettings = {{
                                    position : "fixed",
                                    backgroundColor : detailsSidePanelVisible ? "transparent" : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color5", patrolBuddyGoLoadingColor), 0.69)
                               }}
                               
                               text = {detailsSidePanelVisible ? "" : fileActionButton.toolTipText}
                               
                               textSpeed = {5.69}
                               
                               position = "left"
                               >
                               <div
                                    key = {fileActionButton.name}
                                    
                                    style = {{
                                         position : "relative",
                                         display : "flex",
                                         flexDirection : "row",
                                         alignItems : "center",
                                         cursor : "pointer",
                                         pointerEvents : processingAction === "Downloading..." ? "none" : undefined,
                                         transition : "all 0.369s ease",
                                         scale : fileActionButtonHovered === fileActionButton.name ? "1.069" : "1",
                                         width : "9.69rem",
                                         gap : "10px",
                                         marginLeft : detailsSidePanelVisible && fileActionButtonHovered === fileActionButton.name ? "0.369rem" : fileActionButtonHovered === fileActionButton.name ? "0.169rem" : "0rem"
                                    }}
                                    
                                    onMouseLeave = {() => {
                                         setFileActionButtonHovered(null);
                                         
                                         return;
                                    }}
                                    
                                    onMouseEnter = {() => {
                                         setFileActionButtonHovered(fileActionButton.name);
                                         
                                         return;
                                    }}
                                    
                                    onClick = {fileActionButton.hudAction}
                               >
                                    {!processingAction ? (
                                    <HUDIcon
                                         iconConfigurationSettings = {{
                                              
                                         }}
                                         
                                         color = {fileActionButtonHovered === fileActionButton.name ? fileActionButton.iconHoverColor : fileActionButton.iconColor}
                                         
                                         name = {fileActionButton.iconName}
                                    />
                                    ) : (
                                         <HUDLoading
                                              downloadColor = {paletteColor(palette, "PatrolBuddyLights.color3", patrolBuddyGoLoadingColor)}
                                              
                                              size = {27}
                                              
                                              type = "download"
                                         />
                                    )}
                                    
                                    <label
                                         style = {{
                                              position : "relative",
                                              display : "flex",
                                              cursor : "pointer",
                                              fontWeight : "769",
                                              fontSize : "1.069rem",
                                              color : fileActionButtonHovered === fileActionButton.name ? fileActionButton.iconHoverColor : fileActionButton.iconColor,
                                         }}
                                    >
                                         <HUDTyper speed = {12.69} >
                                              {detailsSidePanelVisible && !processingAction ? fileActionButton.name : processingAction ? processingAction : ""}
                                         </HUDTyper>
                                    </label>
                               </div>
                               </HUDToolTip>
                               ) : (
                               <>
                               </>
                               )}
                               </>
                          ))}
                          </div>
                     </div>
                     
                     {detailsSidePanelVisible ? (
                     <HUDMarkdownViewer
                         currentMarkdown = {currentFileDetails}
                         
                         markDownViewerContainerConfigurationSettings = {{
                              background : "rgba(0, 0, 0, 0)",
                              width : "calc(94.69%)",
                              marginLeft : "0.69rem",
                              marginTop : "1.69rem"
                         }}
                         
                         markDownViewerConfigurationSettings = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "column",
                              transition : "height 0.869s ease-in-out",
                              boxShadow : "inset 0 3px 7px rgba(0, 0, 0, 0.169)",
                              border : `0.69px solid ${paletteColor(palette, "PatrolBuddyAccent.color4", patrolBuddyGoLoadingColor)}`,
                              borderRadius : "0.69rem 0rem 0rem 0.69rem",
                              background : "rgba(12, 12, 12, 0.069)",
                              color : paletteColor(palette, "PatrolBuddyAccent.color15", patrolBuddyGoLoadingColor),
                              height : "auto",
                              width : "calc(100%)",
                              paddingTop : "0.369rem",
                              marginBottom : "0rem",
                              marginTop : "0rem",
                         }}
                    >
                    </HUDMarkdownViewer>
                    ) : (
                    <>
                    </>
                    )}
                    
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              borderRadius : "0.69rem",
                              background : paletteColor(palette, "PatrolBuddyAccent.color4", patrolBuddyGoLoadingColor),
                              height : "0.169rem",
                              width : "calc(100%)",
                              marginBottom : "0.869rem",
                              marginTop : "0.869rem"
                         }}
                    />
               </HUDSideContainerPanel>
               
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         height : "calc(87.69%)",
                         width : "calc(94.69%)",
                         marginBottom : "3.269rem",
                         marginRight : "1.69rem",
                         marginTop : "2.369rem"
                    }}
               >
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              height : "52.69rem",
                              width : "calc(100%)"
                         }}
                    >
                         <PatrolBuddyDataViewer
                              videoConfigurationSettings = {{
                                   headerConfigurationSettings : {
                                        paddingBottom : "0.69rem",
                                        paddingTop : "0.69rem"
                                   },
                                   showDownloadButton : false,
                                   ...videoConfigurationSettings
                              }}
                              
                              file = {file}
                              
                              signedUser = {signedUser}
                              
                              signedIn = {signedIn}
                         />
                    </div>
               </div>
               
               {!detailsSidePanelVisible ? (
                    <label
                         style = {{
                              position : "fixed",
                              display : "flex",
                              fontWeight : "569",
                              fontSize : "1.269rem",
                              borderRadius : "0.69rem 0rem 0rem 0.69rem",
                              background : colorTransparency(paletteColor(palette, "Interface.color5", patrolBuddyGoLoadingColor), 0.269),
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor),
                              zIndex : 12699,
                              paddingBottom : "0.369rem",
                              paddingRight : "2.869rem",
                              paddingLeft : "0.69rem",
                              paddingTop : "0.369rem",
                              bottom : "0.769rem",
                              right : 0
                         }}
                    >
                         <HUDTyper speed = {5.69} >
                              {ConvertDateTime(file.lastModified)}
                         </HUDTyper>
                    </label>
               ) : (
                    <>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyDataView;