import * as React from "react";

import { useState, useEffect } from "react";

import { HUDUniversalHUDUtilityManager, Delay } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { HUDTyper, HUDInput, HUDCredentialInput } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDLayoutManagerScreen } from "../HUDComponents/HUDLayoutManager.js";

import PatrolBuddyGoHUDAccessManager from "../HUDManagers/PatrolBuddyGoHUDAccessManager.js";

import PatrolBuddyGoHUDUserManager from "../HUDManagers/PatrolBuddyGoHUDUserManager.js";











function PatrolBuddyAccountCreationView(patrolBuddyBaseAccountCreationViewConfigurationSettings)
{
     const {
          signedUser,
          signedIn,
     } = patrolBuddyBaseAccountCreationViewConfigurationSettings;
     
     const [firstNameInputFocus, setFirstNameInputFocus] = useState(false);
     
     const [middleNameInputFocus, setMiddleNameInputFocus] = useState(false);
     
     const [lastNameInputFocus, setLastNameInputFocus] = useState(false);
     
     const [usernameInputFocus, setUsernameInputFocus] = useState(false);
     
     const [emailInputFocus, setEmailInputFocus] = useState(false);
     
     const [passwordInputFocus, setPasswordInputFocus] = useState(false);
     
     const [confirmPasswordInputFocus, setConfirmPasswordInputFocus] = useState(false);
     
     
     
     const [firstNameInputValue, setFirstNameInputValue] = useState("");
     
     const [middleNameInputValue, setMiddleNameInputValue] = useState("");
     
     const [lastNameInputValue, setLastNameInputValue] = useState("");
     
     const [usernameInputValue, setUsernameInputValue] = useState("");
     
     const [emailInputValue, setEmailInputValue] = useState("");
     
     const [passwordInputValue, setPasswordInputValue] = useState("");
     
     const [confirmPasswordInputValue, setConfirmPasswordInputValue] = useState("");
     
     const [createPatrollerProcessIndicator, setCreatePatrollerProcessIndicator] = useState("Creating patroller...");
     
     const [createPatrollerButtonHover, setCreatePatrollerButtonHover] = useState(false);
     
     const [creatingPatroller, setCreatingPatroller] = useState(false);
     
     const [selectedPBGRank, setSelectedPBGRank] = useState(PatrolBuddyGoHUDAccessManager.getPBGRank("Patroller"));
     
     const [currentPBGRankButtonHover, setCurrentPBGRankButtonHover] = useState(null);
     
     const [currentPBGRanks, setCurrentPBGRanks] = useState([]);
     
     
     
     
     
     
     
     
     
     
     
     const handleSelectedPBGRank = (pbgRankData) => {
          setSelectedPBGRank(pbgRankData);
          
          return;
     };
     
     const handleCreatePatroller = async () => {
          setCreatingPatroller(true);
          
          await Delay(1569);
          
          const firstName = firstNameInputValue;
          
          const middleName = middleNameInputValue;
          
          const lastName = lastNameInputValue;
          
          const username = usernameInputValue;
          
          const email = emailInputValue;
          
          const password = passwordInputValue;
          
          const createdPBGAccount = await PatrolBuddyGoHUDUserManager.AddPBGUserAccount(firstName, middleName, lastName, username, email, password, selectedPBGRank.patrolAccessP, signedUser.precinct.precinct, signedUser.locality.county);
          
          if (createdPBGAccount[0])
          {
               setCreatePatrollerProcessIndicator(`Successfully created ${firstName} ${lastName}'s PatrolBuddy account!`);
               
               setFirstNameInputValue("");
               
               setMiddleNameInputValue("");
               
               setLastNameInputValue("");
               
               setUsernameInputValue("");
               
               setEmailInputValue("");
               
               setPasswordInputValue("");
               
               setConfirmPasswordInputValue("");
               
               await Delay(1869);
               
               setCreatingPatroller(false);
               
               setCreatePatrollerProcessIndicator("Creating patroller...");
               
               return true;
          }
          
          setCreatePatrollerProcessIndicator(`Did not create ${firstName} ${lastName}'s PatrolBuddy account.... Try again.`);
          
          await Delay(1869);
          
          setCreatingPatroller(false);
          
          setCreatePatrollerProcessIndicator("Creating patroller...");
          
          return false;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          setCurrentPBGRanks(PatrolBuddyGoHUDAccessManager.getPBGRanks().filter(pbgRankData => signedIn && signedUser.accessLevel.accountAccessR > pbgRankData.patrolAccessR));
          
          return () => {};
     }, [signedIn, signedUser]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <HUDLayoutManagerScreen>
               <div style={{ position: 'relative', height: '100%' }}>
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "center",
                              fontSize : "1.269rem",
                              color : "#616161",
                              marginTop : "0.569rem"
                         }}
                    >
                         <HUDTyper speed = {3} >
                              PatrolBuddy Account
                         </HUDTyper>
                    </label>
                    
                    <div
                         style = {{
                              position : "relative",
                              background : "#616161",
                              height : "0.169rem",
                              width : "calc(100%)",
                              opacity : 0.769,
                              marginBottom : "0.69rem",
                              marginTop : "0.69rem",
                         }}
                    />
                    
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "column",
                              justifyContent : "flex-start",
                              alignItems : "flex-start",
                              borderRadius : "0.69rem",
                              height : "calc(100%)",
                              width : "calc(100%)",
                         }}
                    >
                         {creatingPatroller ? (
                              <Fade direction = "down" duration = {369} delay = {69} >
                                   <label
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.369s ease",
                                             fontFamily : "ModeNine",
                                             fontSize : "1.869rem",
                                             color : createPatrollerProcessIndicator.toLowerCase().includes("try again") ? "#616161" : "#616161"
                                        }}
                                   >
                                        <HUDTyper speed = {3} >
                                             {createPatrollerProcessIndicator}
                                        </HUDTyper>
                                   </label>
                              </Fade>
                         ) : (
                              <>
                              </>
                         )}
                         
                         <div
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "row",
                                   visibility : creatingPatroller ? "collapse" : "visible",
                                   height : "auto",
                                   width : "calc(77.69%)",
                                   gap : "1.269rem",
                                   marginBottom : "0.69rem",
                                   marginLeft : "1.69rem",
                                   marginTop : "1.69rem"
                              }}
                         >
                              <div
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        transition : "all 0.369s ease", 
                                        visibility : creatingPatroller ? "collapse" : "visible",
                                        height : creatingPatroller ? "0rem" : undefined,
                                        width : "calc(77.69%)",
                                        gap : "1.269rem",
                                   }}
                              >
                                   <HUDInput
                                        inputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.069s ease",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             background : "transparent",
                                             borderRadius : "0.169rem",
                                             borderBottom : firstNameInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                             borderRight : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             
                                             color : "rgba(169, 227.69, 255, 1)",
                                             height : creatingPatroller ? "0rem" : undefined,
                                             width : "calc(100%)",
                                             right : "5px",
                                             marginLeft : firstNameInputFocus ? "1.69rem" : "0rem",
                                        }}
                                        
                                        placeholder = "First Name..."
                                        
                                        setInputFocus = {setFirstNameInputFocus}
                                        
                                        setInputValue = {setFirstNameInputValue}
                                        
                                        inputFocus = {firstNameInputFocus}
                                        
                                        inputValue = {firstNameInputValue}
                                   />
                                   
                                   <HUDInput
                                        inputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.069s ease",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             background : "transparent",
                                             borderRadius : "0.169rem",
                                             borderBottom : middleNameInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                             borderRight : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             fontFamily : "ModeNine",
                                             fontSize : "1.069rem",
                                             color : "rgba(169, 227.69, 255, 1)",
                                             width : "calc(100%)",
                                             right : "5px",
                                             marginLeft : middleNameInputFocus ? "1.69rem" : "0rem",
                                        }}
                                        
                                        placeholder = "Middle Name (Optional)..."
                                        
                                        setInputFocus = {setMiddleNameInputFocus}
                                        
                                        setInputValue = {setMiddleNameInputValue}
                                        
                                        inputFocus = {middleNameInputFocus}
                                        
                                        inputValue = {middleNameInputValue}
                                   />
                                   
                                   <HUDInput
                                        inputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.069s ease",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             background : "transparent",
                                             borderRadius : "0.169rem",
                                             borderBottom : lastNameInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                             borderRight : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             fontFamily : "ModeNine",
                                             fontSize : "1.069rem",
                                             color : "rgba(169, 227.69, 255, 1)",
                                             width : "calc(100%)",
                                             right : "5px",
                                             marginLeft : lastNameInputFocus ? "1.69rem" : "0rem",
                                        }}
                                        
                                        placeholder = "Last Name..."
                                        
                                        setInputFocus = {setLastNameInputFocus}
                                        
                                        setInputValue = {setLastNameInputValue}
                                        
                                        inputFocus = {lastNameInputFocus}
                                        
                                        inputValue = {lastNameInputValue}
                                   />
                                   
                                   <HUDInput
                                        inputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.069s ease",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             background : "transparent",
                                             borderRadius : "0.169rem",
                                             borderBottom : usernameInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                             borderRight : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             fontFamily : "ModeNine",
                                             fontSize : "1.069rem",
                                             color : "rgba(169, 227.69, 255, 1)",
                                             width : "calc(100%)",
                                             right : "5px",
                                             marginLeft : usernameInputFocus ? "1.69rem" : "0rem",
                                        }}
                                        
                                        placeholder = "Username..."
                                        
                                        setInputFocus = {setUsernameInputFocus}
                                        
                                        setInputValue = {setUsernameInputValue}
                                        
                                        inputFocus = {usernameInputFocus}
                                        
                                        inputValue = {usernameInputValue}
                                   />
                                   
                                   <HUDInput
                                        inputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.069s ease",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             background : "transparent",
                                             borderRadius : "0.169rem",
                                             borderBottom : emailInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                             borderRight : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             fontFamily : "ModeNine",
                                             fontSize : "1.069rem",
                                             color : "rgba(169, 227.69, 255, 1)",
                                             width : "calc(100%)",
                                             right : "5px",
                                             marginLeft : emailInputFocus ? "1.69rem" : "0rem",
                                        }}
                                        
                                        placeholder = "Email..."
                                        
                                        setInputFocus = {setEmailInputFocus}
                                        
                                        setInputValue = {setEmailInputValue}
                                        
                                        inputFocus = {emailInputFocus}
                                        
                                        inputValue = {emailInputValue}
                                   />
                                   
                                   <HUDCredentialInput
                                        credentialInputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             width : "calc(100%)",
                                             marginLeft : "0rem",
                                        }}
                                        
                                        inputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.069s ease",
                                             background : "transparent",
                                             borderRadius : "0.169rem",
                                             borderBottom : passwordInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                             borderRight : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             fontFamily : "ModeNine",
                                             fontSize : "1.069rem",
                                             color : "rgba(169, 227.69, 255, 1)",
                                             width : "calc(100%)",
                                             marginLeft : passwordInputFocus ? "1.69rem" : "0rem",
                                        }}
                                        
                                        credentialShownIconColor = "rgba(169, 227.69, 255, 1)"
                                        
                                        placeholder = "Password..."
                                        
                                        setInputFocus = {setPasswordInputFocus}
                                        
                                        setInputValue = {setPasswordInputValue}
                                        
                                        inputFocus = {passwordInputFocus}
                                        
                                        inputValue = {passwordInputValue}
                                        
                                        inputType = "password"
                                   />
                                   
                                   <HUDCredentialInput
                                        credentialInputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             width : "calc(100%)",
                                             marginLeft : "0rem",
                                        }}
                                        
                                        inputContainerConfigurationSettings = {{
                                             position : "relative",
                                             display : "flex",
                                             transition : "all 0.069s ease",
                                             background : "transparent",
                                             borderRadius : "0.169rem",
                                             borderBottom : confirmPasswordInputFocus ? "1.869px solid rgba(169, 227.69, 255, 1)" : "solid 0.269px #A5A5A5",
                                             borderRight : "none",
                                             borderLeft : "none",
                                             borderTop : "none",
                                             fontFamily : "ModeNine",
                                             fontSize : "1.069rem",
                                             color : "rgba(169, 227.69, 255, 1)",
                                             width : "calc(100%)",
                                             marginLeft : confirmPasswordInputFocus ? "1.69rem" : "0rem",
                                        }}
                                        
                                        credentialShownIconColor = "rgba(169, 227.69, 255, 1)"
                                        
                                        placeholder = "Confirm Password..."
                                        
                                        setInputFocus = {setConfirmPasswordInputFocus}
                                        
                                        setInputValue = {setConfirmPasswordInputValue}
                                        
                                        inputFocus = {confirmPasswordInputFocus}
                                        
                                        inputValue = {confirmPasswordInputValue}
                                        
                                        inputType = "password"
                                   />
                              </div>
                              
                              {currentPBGRanks.length > 0 ? (
                                   <Fade direction = "right" duration = {469} >
                                   <div
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             flexDirection : "column",
                                             transition : "all 0.069s ease",
                                             boxShadow : "3px 3px 7.69px -1.69px rgba(0, 0, 0, 0.69)",
                                             background : "rgba(47.69, 47.69, 47.69, 0.369)",
                                             borderRadius : "0.69rem",
                                             width : "auto",
                                             gap : "1.169rem",
                                             zIndex : 269,
                                             padding : "0.369rem",
                                             marginLeft : (firstNameInputFocus || middleNameInputFocus || lastNameInputFocus || usernameInputFocus || emailInputFocus || passwordInputFocus || confirmPasswordInputFocus) ? "3.69rem" : "0.269rem"
                                        }}
                                   >
                                        <label
                                             style = {{
                                                  userSelect : "none",
                                                  fontWeight : "569",
                                                  fontSize : "1.269rem",
                                                  color : "rgba(120, 120, 140, 0.569)",
                                             }}
                                        >
                                             <HUDTyper speed = {1} >
                                                  Select Rank:
                                             </HUDTyper>
                                        </label>
                                        
                                        {currentPBGRanks.map((currentPBGRank, index) => (
                                             <div
                                                  style = {{
                                                       position : "relative",
                                                       display : "flex",
                                                       flexDirection : "row",
                                                       userSelect : "none",
                                                       cursor : "pointer",
                                                       transition : "background 0.369s ease, color 0.369s ease, scale 1.269s ease",
                                                       fontWeight : "569",
                                                       fontSize : "1.069rem",
                                                       borderRadius : "1rem",
                                                       background : selectedPBGRank !== null && selectedPBGRank.patrolAccessN === currentPBGRank.patrolAccessN ? "rgba(95, 95, 120, 0.5269)" : currentPBGRankButtonHover === index ? "rgba(95, 95, 120, 0.169)" : "rgba(69, 69, 87.69, 0.169)",
                                                       color : selectedPBGRank !== null && selectedPBGRank.patrolAccessN === currentPBGRank.patrolAccessN ? "rgba(120, 120, 140, 1)" : currentPBGRankButtonHover === index ? "rgba(120, 120, 140, 0.869)" : "rgba(120, 120, 140, 0.69)",
                                                       scale : currentPBGRankButtonHover === index ? "1.069" : "1",
                                                       width : "auto",
                                                       paddingBottom : "0.369rem",
                                                       paddingRight : "0.69rem",
                                                       paddingLeft : "0.69rem",
                                                       paddingTop : "0.369rem",
                                                       marginRight : "0.269rem",
                                                       marginLeft : "0.269rem",
                                                       marginTop : index === 0 ? "0.569rem" : "0.069rem",
                                                  }}
                                                  
                                                  onMouseLeave = {() => {
                                                       setCurrentPBGRankButtonHover(null);
                                                       
                                                       return;
                                                  }}
                                                  
                                                  onMouseEnter = {() => {
                                                       setCurrentPBGRankButtonHover(index);
                                                       
                                                       return;
                                                  }}
                                                  
                                                  onClick = {() => {
                                                       handleSelectedPBGRank(currentPBGRank);
                                                       
                                                       return;
                                                  }}
                                             >
                                                  <HUDTyper speed = {1} >
                                                       {currentPBGRank.patrolAccessN}
                                                  </HUDTyper>
                                             </div>
                                        ))}
                                   </div>
                                   </Fade>
                              ) : (
                                   <>
                                   </>
                              )}
                         </div>
                         
                         {firstNameInputValue.trim() !== "" && lastNameInputValue.trim() !== "" && usernameInputValue.trim() !== "" && emailInputValue.trim() !== "" && HUDUniversalHUDUtilityManager.CheckEmail(emailInputValue.trim()) && passwordInputValue.length > 8 && passwordInputValue !== "" && passwordInputValue === confirmPasswordInputValue ? (
                              <div
                                   style = {{
                                        position : "relative",
                                        display : "flex",
                                        flexDirection : "column",
                                        alignItems : "center",
                                        width : "calc(77.69%)",
                                        marginTop : "1rem",
                                   }}
                              >
                                   <Fade direction = "down" duration = {269} >
                                   <div
                                        style = {{
                                             position : "relative",
                                             display : "flex",
                                             WebkitAppRegion : "no-drag",
                                             userSelect : "none",
                                             cursor : "pointer",
                                             transition : "background 0.369s ease, color 0.369s ease, font-size 0.369s ease-in-out, scale 0.269s ease-in-out",
                                             visibility : creatingPatroller ? "collapse" : "visible",
                                             fontFamily : "ModeNine",
                                             fontSize : createPatrollerButtonHover ? "1.269rem" : "1.169rem",
                                             borderRadius : "0.69rem",
                                             background : createPatrollerButtonHover ? "rgba(201, 245, 255, 0.569)" : "rgba(69, 69, 87.69, 0.169)",
                                             color : createPatrollerButtonHover ? "white" : "rgba(120, 120, 140, 0.69)",
                                             scale : createPatrollerButtonHover ? "1.269" : "1",
                                             width : "auto",
                                             paddingBottom : "0.569rem",
                                             paddingRight : "0.69rem",
                                             paddingLeft : "0.69rem",
                                             paddingTop : "0.569rem",
                                        }}
                                        
                                        onMouseLeave = {() => {
                                             setCreatePatrollerButtonHover(false);
                                             
                                             return;
                                        }}
                                        
                                        onMouseEnter = {() => {
                                             setCreatePatrollerButtonHover(true);
                                             
                                             return;
                                        }}
                                        
                                        onClick = {handleCreatePatroller}
                                   >
                                        <HUDTyper speed = {3} >
                                              Create Patroller
                                        </HUDTyper>
                                   </div>
                                   </Fade>
                              </div>
                         ) : (
                              <>
                              </>
                         )}
                    </div>
               </div>
          </HUDLayoutManagerScreen>
     );
}

export default PatrolBuddyAccountCreationView;