import * as React from "react";

import { useState, useEffect } from "react";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { enableScrolling } from "@trap_stevo/legendarybuilderproreact-ui/HUDConfigurationUtilities";

import { HUDUniversalHUDUtilityManager, Delay, DetermineFileType } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { HUDLoading, HUDWindow, HUDGrid, HUDGridItem, HUDTyper, HUDInput, HUDCredentialInput } from "@trap_stevo/legendarybuilderproreact-ui";

import PatrolBuddyDataView from "../HUDViews/PatrolBuddyDataView.js";

import PBGFileCell from "../HUDComponents/PBGFileCell.js";











function PatrolBuddyDataDashboardView(patrolBuddyDataDashboardViewConfigurationSettings)
{
     const {
          colorTransparency,
          paletteColor,
          palette,
          addWindowContents,
          sidePanelVisible,
          setSelectedFile,
          selectedFile,
          viewFile,
          setFiles,
          files,
          signedUser,
          signedIn,
     } = patrolBuddyDataDashboardViewConfigurationSettings;
     
     
     
     
     
     
     
     
     
     
     
     const createWindow = (fileData) => {
          addWindowContents(fileData);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     return (
          <>
          {selectedFile && HUDUniversalHUDUtilityManager.CheckEmptyData(selectedFile) === false ? (
          <PatrolBuddyDataView
               colorTransparency = {colorTransparency}
               paletteColor = {paletteColor}
               palette = {palette}
               sidePanelVisible = {sidePanelVisible}
               createWindow = {createWindow}
               setFiles = {setFiles}
               setFile = {setSelectedFile}
               file = {selectedFile}
               type = {DetermineFileType(selectedFile)}
               signedUser = {signedUser}
               signedIn = {signedIn}
          />
          ) : files && files.length > 0 ? (
          <>
          <HUDGrid
               gridConfigurationSettings = {{
                    position : "relative",
                    ...enableScrolling("vertical"),
                    msOverflowStyle : "none",
                    scrollbarWidth : "none",
                    transition : "all 0.69s ease",
                    borderRadius : "0.69rem",
                    background : paletteColor(palette, "PatrolBuddyAccent.color19", patrolBuddyGoLoadingColor),
                    height : "48.69rem",
                    width : sidePanelVisible ? "calc(81.69%)" : "calc(91.69%)",
                    gap : "1.969rem",
                    zIndex : sidePanelVisible ? 869 : 12769,
                    padding : "10px",
                    marginLeft : sidePanelVisible ? "12.69rem" : "0rem",
                    marginTop : "4.69rem"
               }}
               
               columns = "repeat(4, auto)"
               
               rows = "repeat(4, auto)"
               
               fluid = {true}
          >
               {files.map((file, index) => (
                    <PBGFileCell
                         colorTransparency = {colorTransparency}
                         
                         paletteColor = {paletteColor}
                         
                         palette = {palette}
                         
                         signedUser = {signedUser}
                         
                         signedIn = {signedIn}
                         
                         index = {index}
                         
                         file = {file}
                         
                         createWindow = {createWindow}
                         
                         viewFile = {viewFile}
                    />
               ))}
          </HUDGrid>
          </>
          ) : (
               <div
                     style = {{
                          position : "relative",
                          display : "flex",
                          flexDirection : "column",
                          justifyContent : "center",
                          alignItems : "center",
                          gap : "0.69rem",
                          height : "100vh",
                          width : "100vw"
                     }}
               >
                     <HUDLoading
                          cloudContainerConfigurationSettings = {{
                               marginRight : "2.69rem"
                          }}
                          
                          cubeColor = {paletteColor(palette, "PatrolBuddyLights.color12", patrolBuddyGoLoadingColor)}
                          
                          cloudFormationRadius = {57}
                          
                          pulseSize = {1.269}
                          
                          cubeCount = {57}
                          
                          size = {269}
                          
                          type = "cloud"
                     />
                     
                     <HUDLoading
                          wordContainerConfigurationSettings = {{
                               marginBottom : "2.69rem"
                          }}
                          
                          letterSpacing = "3.69px"
                          
                          word = "Gathering PatrolBuddies' evidence..."
                          
                          wordColor = {paletteColor(palette, "PatrolBuddyLights.color15", patrolBuddyGoLoadingColor)}
                          
                          type = "word"
                     />
               </div>
          )}
          </>
     );
}

export default PatrolBuddyDataDashboardView;