import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { sanitizeHTML, containsHTML } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import HUDIoTide from "@trap_stevo/iotide-client";

import PatrolBuddyOfficerCell from "../HUDComponents/PatrolBuddyOfficerCell.js";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











const supportIO = new HUDIoTide();

function PatrolBuddyGoSupportChatViewPanel() 
{
     const [patrolBuddyGoActionButtonHover, setPatrolBuddyGoActionButtonHover] = useState(false);
     
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Support");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [downloading, setDownloading] = useState(false);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [currentOnGoingChatHover, setCurrentOnGoingChatHover] = useState(false);
     
     const [currentOnGoingChat, setCurrentOnGoingChat] = useState(null);
     
     const [currentActionTraces, setCurrentActionTraces] = useState([]);
     
     const { getFromSessionCache, addToSessionCache, clearSessionCache } = useHUDSession();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const [messageViewScrollPosition, setMessageViewScrollPosition] = useState(0);
     
     const [messageInputInFocus, setMessageInputInFocus] = useState(false);
     
     const [messageInputHeight, setMessageInputHeight] = useState(0);
     
     const [messageInputValue, setMessageInputValue] = useState("");
     
     const [messageLineCount, setMessageLineCount] = useState(1);
     
     const [ownMessageBackgroundColor, setOwnMessageBackgroundColor] = useState("rgba(57, 57, 57, 0.569)");
     
     const [messageBackgroundColor, setMessageBackgroundColor] = useState("rgba(31, 31, 31, 0.469)");
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const currentSupportChatData = useRef(localStorage.getItem("CurrentSupportChat") ? JSON.parse(localStorage.getItem("CurrentSupportChat")) : {});
     
     const [currentChatMessagesData, setCurrentChatMessagesData] = useState([...currentSupportChatData.current.CurrentMessages]);
     
     const [currentChatParticipantsData, setCurrentChatParticipantsData] = useState([]);
     
     const mostRecentOwnMessageRef = useRef(null);
     
     const mostRecentMessageRef = useRef(null);
     
     const sendMessageButtonRef = useRef(null);
     
     const messageHotBarRef = useRef(null);
     
     const messageInputRef = useRef(null);
     
     const messageViewRef = useRef(null);
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible, true);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("", true);
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     
     
     
     
     
     
     
     
     
     
     const adjustMessageInputHeight = () => {
          const textArea = messageInputRef.current;
          
          if (!textArea) { return; }
          
          textArea.style.transition = 'none';
          
          textArea.style.height = `${2.969}rem`;
          
          const scrollHeight = textArea.scrollHeight;
          
          if (textArea.scrollHeight < textArea.clientHeight) { return; }
          
          if (messageLineCount <= 5 && messageInputValue.length > 0)
          {
               textArea.style.height = `${scrollHeight}px`;
          }
          
          textArea.style.transition = '';
          
          return;
     };
     
     const handleMessageInputInFocus = (e) => {
          setMessageInputInFocus(true);
          
          return;
     };
     
     const handleMessageInputBlur = (e) => {
          setMessageInputInFocus(false);
          
          return;
     };
     
     const handleMessageInputChange = (e) => {
          setMessageInputValue(e.target.value);
          
          adjustMessageInputHeight();
          
          return;
     };
     
     const broadcastMessageByEnterPress = (e) => {
          if (e.key === "Enter" && !e.shiftKey)
          {
               e.preventDefault();
               
               setPatrolBuddyGoActionButtonHover(true);
               
               setTimeout(() => {
                    broadcastMessage();
                    
                    return;
               }, 369);
               
               return;
          }
          
          return;
     };
     
     const broadcastMessage = () => {
          const currentUTCDateTime = new Date().toUTCString();
          
          const currentDate = new Date(currentUTCDateTime).toLocaleDateString(undefined, {
               year : "2-digit",
               month : "numeric",
               day : "numeric",
          }) +
          ' ' +
          new Date(currentUTCDateTime).toLocaleTimeString(undefined, {
               hour : "numeric",
               minute : "numeric",
               hour12 : true,
          });
          
          const messageData = {
               MessageID : currentChatMessagesData.length,
               MessageDate : currentDate,
               MessageReadDate : "",
               MessageRead : 0,
               SentMessage : messageInputValue,
               MessageSender : signedUser.displayName,
          };
          
          const currentUTCTimeInMilliseconds = new Date(currentUTCDateTime).getTime();
          
          const currentUTCTimeInSeconds = currentUTCTimeInMilliseconds / 1000;
          
          var patrolBuddyGoSupportNetworkSendMessageAction = {
               userID : signedUser.userID,
               username : signedUser.username,
               access : signedUser.accessLevel,
               action : "sendMessageInSupportChat",
               sentData : {...messageData, DisplayName : signedUser.displayName, ChatID : currentSupportChatData.current.ChatID, CurrentParticipants : currentSupportChatData.current.Participants, Date : currentUTCTimeInSeconds}
          };
          
          supportIO.emitEvent("supportNet", "message room", { roomName : currentSupportChatData.current.ChatID, userID : signedUser.userID, includeSender : true, message : { type : "text", ...messageData }});
          
          supportIO.emitEvent("supportNet", "save-message-in-chat", { roomName : currentSupportChatData.current.ChatID, userID : signedUser.userID, sentData : { ChatID : currentSupportChatData.current.ChatID, SentMessage : messageData.SentMessage, DisplayName : signedUser.DisplayName, CurrentParticipants : currentChatParticipantsData, Date : HUDUniversalHUDUtilityManager.GenerateUTCDateDouble() }});
          
          setPatrolBuddyGoActionButtonHover(false);
          
          setMessageInputValue("");
          
          return;
     };
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (currentChatParticipantsData.length <= 0)
          {
               setCurrentChatParticipantsData([...currentChatParticipantsData, ...currentSupportChatData.current.Participants]);
          }
          
          supportIO.createIO("supportNet", "https://utilities.patrolbuddygo.com:8699");
          
          supportIO.onEvent("supportNet", "connect", () => {
               console.log("Connected to support net!");
               
               supportIO.sockets["supportNet"].roomID = currentSupportChatData.current.ChatID;
               
               supportIO.sockets["supportNet"].userID = signedUser.userID;
               
               supportIO.emitEvent("supportNet", "join room", { roomName : currentSupportChatData.current.ChatID, userID : signedUser.userID });
               
               supportIO.emitEvent("supportNet", "listen-for-current-chat-participants", { currentChatID : currentSupportChatData.current.ChatID, userData : { userID : signedUser.userID, access : signedUser.accessLevel }});
          });
          
          supportIO.onEvent("supportNet", "disconnect", () => {
               supportIO.emitEvent("supportNet", "leave room", { roomName : currentSupportChatData.current.ChatID, userID : signedUser.userID });
          });
          
          supportIO.onEvent("supportNet", "chatParticipants", (incomingUpdatedChatParticipantsData) => {
               try
               {
                    const incomingUpdatedChatParticipants = JSON.parse(incomingUpdatedChatParticipantsData);
                    
                    setCurrentChatParticipantsData([...incomingUpdatedChatParticipants.participants]);
                    
                    return;
               }
               catch (error)
               {
                    console.log("Did not get current chat participants: ", error);
                    
                    return;
               }
          });
          
          supportIO.onEvent("supportNet", "incoming message", (incomingMessageData) => {
               const containsMessage = currentChatMessagesData.some(message => message.MessageID === incomingMessageData.MessageID);
               
               setCurrentChatMessagesData((pChatMessages) => [...pChatMessages, incomingMessageData]);
               
               setTimeout(() => {
                    addToSessionCache("CurrentChatMessages", currentChatMessagesData);
               }, 269);
          });
          
          return () => {
               supportIO.emitEvent("supportNet", "leave room", { roomName : currentSupportChatData.current.ChatID, userID : signedUser.userID });
               
               supportIO.closeSocket("supportNet");
          };
     }, [signedUser, supportIO]);
     
     useEffect(() => {
          const timer = setTimeout(() => {
               const getCurrentActionTraces = async () => { 
                    if (signedUser.userID !== null && signedUser.userID !== undefined)
                    {
                         const userActionTraces = await PatrolBuddyGoHUDUtilityManager.GetPatrolBuddyGoUserActionTraces(signedUser.userID);
                         
                         setCurrentActionTraces(userActionTraces);
                         
                         return;
                    }
               };
               
               getCurrentActionTraces();
               
               if (messageInputRef.current === null || messageInputRef.current === undefined) { return; }
               
               messageInputRef.current.focus();
               
               return;
          }, 100);
          
          return () => clearInterval(timer);
     }, []);
     
     useEffect(() => {
          if (messageInputRef.current === null || messageInputRef.current === undefined) { return; }
          
          if (sidePanelVisible)
          {
               messageInputRef.current.blur();
               
               return;
          }
          
          messageInputRef.current.focus();
          
          return;
     }, [sidePanelVisible]);
     
     useEffect(() => {
          const lineCount = messageInputValue.split('\n').length;
          
          setMessageLineCount(lineCount);
          
          adjustMessageInputHeight();
          
          return () => {};
     }, [messageInputValue]);
     
     useEffect(() => {
          if (messageViewRef.current === null || messageViewRef.current === undefined) { return; }
          
          setMessageViewScrollPosition(messageViewRef.current.scrollHeight - messageViewRef.current.clientHeight);
          
          if (mostRecentOwnMessageRef.current !== null && currentChatMessagesData.length > 0 && currentChatMessagesData[currentChatMessagesData.length - 1].MessageSender === signedUser.displayName) {
               const mostRecentOwnMessageCell = mostRecentOwnMessageRef.current;
               
               const mostRecentOwnMessageCellPosition = mostRecentOwnMessageCell.getBoundingClientRect().top + window.pageYOffset;
               
               const messageView = messageViewRef.current;
               
               const timer = setTimeout(() => {
                    messageView.scrollTop = messageView.scrollHeight - messageView.clientHeight;
                    
                    window.scrollTo({ top: mostRecentOwnMessageCellPosition, behavior: 'smooth' });
               }, 269);
               
               return () => clearInterval(timer);
          }
          
          if (currentChatMessagesData.length > 0 && currentChatMessagesData[currentChatMessagesData.length - 1].MessageSender !== signedUser.displayName) {
               setMessageBackgroundColor("#9FD1D9");
               
               const timer = setTimeout(() => {
                   setMessageBackgroundColor("rgba(31, 31, 31, 0.469)");
                   
                   return;
               }, 369);
               
               return () => clearInterval(timer);
          }
     }, [currentChatMessagesData]);
     
     useEffect(() => {
          if (downloading === true) 
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#A5A5A5" : "#616161"));
               }, 569);
               
               return () => clearInterval(intervalId);
          }
          
          return () => {};
     }, [downloading]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return () => {};
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <PatrolBuddySidePanel
                    currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    currentPatrolBuddyGoSidePanelData = {currentPatrolBuddyGoSidePanelData}
                    
                    setSidePanelVisible = {setSidePanelVisible}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    sidePanelRef = {sidePanelRef}
                    
                    containsTopHeader = {true}
               />
               
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         transition : "height 0.269s ease-in",
                         width : "calc(94.69%)",
                         borderRadius : "1rem",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         background : colorTransparency(paletteColor(palette, "Interface.color16", patrolBuddyGoLoadingColor), 0.569),
                         marginBottom : "1rem",
                         marginTop : "2.69rem",
                    }}
               >
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         justifyContent : "center",
                         height : "auto",
                         transition : "height 0.269s ease-in",
                         width : "calc(91.69%)",
                         borderRadius : "1rem",
                         background : "transparent",
                         marginBottom : "1rem",
                         marginLeft : "1.269rem",
                         marginTop : "0.869rem",
                    }}
               >
                    <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "row",
                         justifyContent : "flex-start",
                         height : "auto",
                         transition : "height 0.269s ease-in",
                         width : "calc(100%)",
                         borderRadius : "1rem",
                         background : "transparent",
                         marginBottom : "1rem",
                         marginTop : "0.869rem",
                    }}
                    >
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.69rem",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color10", patrolBuddyGoLoadingColor),
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentSupportChatData.current.ChatTitle}
                         </HUDTyper>
                    </label>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "center",
                              wordWrap : "break-word",
                              fontSize : "1.169rem",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color10", patrolBuddyGoLoadingColor),
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentSupportChatData.current.ChatCreationDate}
                         </HUDTyper>
                    </label>
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.169rem",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color4", patrolBuddyGoLoadingColor),
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentSupportChatData.current.ChatStatus}
                         </HUDTyper>
                    </label>
                    
                    <div
                         style = {{
                              position : "relative",
                              height : "0.169rem",
                              width : "calc(100%)",
                              background : "#AAAAAA",
                              marginTop : "1.469rem",
                         }}
                    >
                         
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color4", patrolBuddyGoLoadingColor),
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.269rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              Current Participants:
                         </HUDTyper>
                    </label>
                    
                    {currentChatParticipantsData.length > 0 ? (
                    <>
                         <div
                              className = "invisa-scroller"
                              
                              style = {{
                                   position : "relative",
                                   display : "flex",
                                   flexDirection : "row",
                                   overflowY : "scroll",
                                   overflowX : "hidden",
                                   transition : "height 0.269s ease-in",
                                   boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                                   borderRadius : "1rem",
                                   background : colorTransparency(paletteColor(palette, "Interface.color25", patrolBuddyGoLoadingColor), 0.269),
                                   width : "calc(98.69%)",
                                   padding : "5px",
                                   marginBottom : "1rem",
                                   marginTop : "1.269rem",
                              }}
                         >
                              {currentChatParticipantsData.map((currentChatParticipantData, index) => (
                                   <PatrolBuddyOfficerCell
                                        patrolBuddyOfficerData = {currentChatParticipantData}
                                        
                                        showUsername = {true}
                                        
                                        index = {index}
                                   />
                              ))}
                         </div>
                    </>
                    ) : (
                         <>
                              
                         </>
                    )}
               </div>
               
               <div
                    className = "invisa-scroller"
                    
                    ref = {messageViewRef}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         WebkitAppRegion : "no-drag", 
                         overflowY : "scroll",
                         overflowX : "hidden",
                         transition : "height 0.269s ease-in",
                         borderRadius : "1rem",
                         boxShadow : `inset 0 0 10px ${colorTransparency(paletteColor(palette, "Interface.color25", patrolBuddyGoLoadingColor), 0.469)}`,
                         background : colorTransparency(paletteColor(palette, "Interface.color25", patrolBuddyGoLoadingColor), 0.269),
                         height : "35.269rem",
                         width : "calc(98.69%)",
                         marginBottom : "5.69rem",
                         marginTop : "0.869rem",
                    }}
               >
               {currentChatMessagesData.length > 0 && currentChatMessagesData.map((currentChatMessageData, index) => (
               <div
                    key = {index}
                    
                    ref = {index === currentChatMessagesData.length - 1 && currentChatMessageData.MessageSender === signedUser.displayName ? mostRecentOwnMessageRef : mostRecentMessageRef}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         WebkitAppRegion : "no-drag",
                         transition : "height 0.269s ease-in, background 0.69s ease-in-out, scale 0.369s ease-in, box-shadow 0.569s ease-in",
                         scale : currentChatMessageData === index ? "1.10069" : "1",
                         borderRadius : "1rem",
                         background : "transparent",
                         height : "auto",
                         width : "calc(47%)",
                         marginLeft : currentChatMessageData.MessageSender === signedUser.displayName ? "auto" : "0.69rem",
                         marginRight : "0.69rem",
                         marginBottom : "1rem",
                         marginTop : "0.869rem",
                    }}
                    
                    onMouseLeave = {() => {
                         setCurrentOnGoingChatHover(false);
                         
                         setCurrentOnGoingChat(null);
                    }}
                    
                    onMouseEnter = {() => {
                         setCurrentOnGoingChatHover(true);
                         
                         setCurrentOnGoingChat(index);
                    }}
               >
               <Fade direction = {currentChatMessageData.MessageSender === signedUser.displayName ? "right" : "left"} duration = {369} >
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         WebkitAppRegion : "no-drag",
                         transition : "height 0.269s ease-in, background 0.69s ease-in-out, scale 0.369s ease-in, box-shadow 0.569s ease-in",
                         boxShadow : currentChatMessageData === index ? "inset 0 0 10px rgba(0, 0, 0, 0.69)" : "3px 3px 9.69px -2.69px rgba(0, 0, 0, 0.469)",
                         scale : currentChatMessageData === index ? "1.10069" : "1",
                         borderRadius : "1rem",
                         background : currentChatMessageData.MessageSender === signedUser.displayName ? ownMessageBackgroundColor : messageBackgroundColor,
                         height : "auto",
                         width : "calc(100%)"
                    }}
               >
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              justifyContent : "flex-start",
                              transition : "height 0.269s ease-in",
                              width : "calc(87%)",
                         }}
                    >
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color8", patrolBuddyGoLoadingColor),
                              width : "calc(57%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentChatMessageData.MessageSender}
                         </HUDTyper>
                    </label>
                    
                    <label
                         style = {{
                              position : "relative",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "right",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : paletteColor(palette, "PatrolBuddyLightAccent.color8", patrolBuddyGoLoadingColor),
                              width : "calc(48.069%)",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              marginTop : "0.969rem",
                              flexShrink : 0
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentChatMessageData.MessageDate}
                         </HUDTyper>
                    </label>
                    </div>
                    
                    {currentChatMessageData.SentMessage && containsHTML(currentChatMessageData.SentMessage) ? (
                         <pre
                              style = {{
                                   position: "relative",
                                   flexShrink: 0,
                                   overflowY: "hidden",
                                   overflowX: "hidden",
                                   transition: "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   fontFamily: "ModeNine",
                                   fontWeight: "bold",
                                   textAlign: "left",
                                   whiteSpace: "pre-wrap",
                                   wordWrap: "break-word",
                                   fontSize: "0.9169rem",
                                   color: paletteColor(palette, "PatrolBuddyLightAccent.color2", patrolBuddyGoLoadingColor),
                                   width: "calc(85.69%)",
                                   marginBottom: "2.269rem",
                                   marginLeft: "2.269rem",
                                   marginTop: "1.069rem"
                              }}
                              
                              dangerouslySetInnerHTML={{ __html : sanitizeHTML(currentChatMessageData.SentMessage) }}
                         />
                    ) : (
                         <pre
                              style = {{
                                   position: "relative",
                                   flexShrink: 0,
                                   overflowY: "hidden",
                                   overflowX: "hidden",
                                   transition: "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   fontFamily: "ModeNine",
                                   fontWeight: "bold",
                                   textAlign: "left",
                                   whiteSpace: "pre-wrap",
                                   wordWrap: "break-word",
                                   fontSize: "0.9169rem",
                                   color: paletteColor(palette, "PatrolBuddyLightAccent.color2", patrolBuddyGoLoadingColor),
                                   width: "calc(85.69%)",
                                   marginBottom: "2.269rem",
                                   marginLeft: "2.269rem",
                                   marginTop: "1.069rem"
                              }}
                         >
                              <HUDTyper speed = {3} >
                                   {currentChatMessageData.SentMessage}
                              </HUDTyper>
                         </pre>
                    )}
               </div>
               </Fade>
               </div>
               ))}
               </div>
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <div
                    className = "invisa-scroller"
                    
                    style = {{
                         position : "fixed",
                         display : "flex",
                         flexDirection : "row",
                         alignItems : "center",
                         height : "7.469rem",
                         width : "calc(100%)",
                         background : "rgba(0, 0, 0, 0.0969)",
                         visibility : downloading ? "hidden" : "visible",
                         borderRadius : "1rem 1rem 0rem 0rem",
                         overflowY : "auto",
                         zIndex : "69",
                         bottom : 0
                    }}
               >
                    <textarea
                         className = "hud-input-light"
                         
                         type = "text"
                         
                         ref = {messageInputRef}
                         
                         value = {messageInputValue}
                         
                         onChange = {handleMessageInputChange}
                         
                         onFocus = {handleMessageInputInFocus}
                         
                         onBlur = {handleMessageInputBlur}
                         
                         rows = {messageLineCount}
                         
                         placeholder = "Type your message..."
                         
                         style = {{ 
                              position : "relative",
                              display : "flex",
                              overflowX : "hidden",
                              overflowY : "auto",
                              outline : "none",
                              transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in, margin-left 0.369s ease",
                              fontFamily : "ModeNine",
                              fontSize : "1.269rem",
                              borderLeft : "none",
                              borderTop : "none",
                              boxShadow : messageInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                              borderBottom : messageInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              borderRight : messageInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                              background : "transparent",
                              borderRadius : "2rem",
                              color : messageInputInFocus ? "#C9F5FF" : "#A5A5A5",
                              scale : messageInputInFocus ? "1.069" : "1",
                              maxHeight : "5.69rem",
                              height : "2.969rem",
                              width : "calc(47%)",
                              resize : "none",
                              paddingBottom : "0.469rem",
                              paddingRight : "0.769rem",
                              paddingLeft : "0.769rem",
                              paddingTop : "0.69rem",
                              marginBottom : "0.69rem",
                              marginLeft : messageInputInFocus ? "5.69rem" : "2.169rem",
                              marginTop : "1.269rem"
                         }}
                         
                         onKeyPress = {broadcastMessageByEnterPress}
                    />
                    
                    {messageInputValue.trim() !== "" && messageInputValue !== null && (
                    <div
                         ref = {sendMessageButtonRef}
                         
                         style = {{
                              position : "fixed",
                              display : "flex",
                              flexDirection : "row",
                              alignItems : "center",
                              height : "2.469rem",
                              width : "auto",
                              scale : patrolBuddyGoActionButtonHover ? "1.169" : "1",
                              transition : "scale 0.3s ease-in-out, background 0.69s ease-in-out, box-shadow 0.69s ease-in-out",
                              background : patrolBuddyGoActionButtonHover ? "#9FD1D9" : "#575757",
                              borderRadius : "1.269rem",
                              boxShadow : patrolBuddyGoActionButtonHover ? "0 0 10px rgba(255, 255, 255, 0.69)" : "inset 0 0 10px rgba(0, 0, 0, 0.69)",
                              marginBottom : "10.69rem",
                              marginLeft : messageInputInFocus ? "5.69rem" : "1.969rem",
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoActionButtonHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoActionButtonHover(true);
                         }}
                         
                         onClick = {async () => {
                              broadcastMessage();
                              
                              return;
                         }}
                    >
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bold",
                                   fontSize : "1.469rem",
                                   color : "#FFFFFF",
                                   marginRight : "1.69rem",
                                   marginLeft : "1.69rem",
                                   flexShrink : 0
                              }}
                          >
                              <HUDTyper speed = {2} >
                                   Send
                              </HUDTyper>
                          </label>
                    </div>
                    )}
               </div>
               
               <div
                    style = {{
                         position : "relative",
                         height : "0rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "0rem",
                    }}
               >
                    
               </div>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoSupportChatViewPanel;