import * as React from "react";

import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation, PatrolBuddyGoSupportChatPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { sanitizeHTML, containsHTML } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import HUDIoTide from "@trap_stevo/iotide-client";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";











const supportIO = new HUDIoTide();

function PatrolBuddyGoSupportPanel() 
{
     const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
     
     const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Support");
     
     const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
     
     const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
     
     const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
     
     const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
     
     const [restrictedAccessed, setRestrictedAccessed] = useState(false);
     
     const [usernameLabelHover, setUsernameLabelHover] = useState(false);
     
     const [currentOnGoingChatHover, setCurrentOnGoingChatHover] = useState(false);
     
     const [loadingCurrentChatsProcessIndicator, setLoadingCurrentChatsProcessIndicator] = useState("No chats yet!");
     
     const [loadingCurrentChats, setLoadingCurrentChats] = useState(false);
     
     const [currentOnGoingChat, setCurrentOnGoingChat] = useState(null);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(false);
     
     const { addToSessionCache, getFromSessionCache, clearSessionCache } = useHUDSession();
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const sidePanelRef = useRef(null);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible, true);
     
     var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("", true);
     
     let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => !actionButton.needsAuthentication || signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
     
     const [currentOnGoingChatsData, setCurrentOnGoingChatsData] = useState([]);
     
     
     
     
     
     
     
     
     
     
     
     const handleMouseMove = (e) => {
          HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 27.69, e);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          supportIO.createIO("supportNet", "https://utilities.patrolbuddygo.com:8699");
          
          setLoadingCurrentChats(true);
          
          supportIO.onEvent("supportNet", "connect", () => {
               console.log("Connected to support net!");
               
               supportIO.emitEvent("supportNet", "listen-for-chats-on-status", { userData : { userID : signedUser.userID, access : signedUser.accessLevel }});
               
               supportIO.emitEvent("supportNet", "listen-for-most-recent-chat");
          });
          
          supportIO.onEvent("supportNet", "disconnect", () => {
               supportIO.emitEvent("supportNet", "leave room", { roomName : "Support Chat", userID : signedUser.userID });
          });
          
          
          
          supportIO.onEvent("supportNet", "mostRecentChat", (incomingMostRecentChatCell) => {
               try
               {
                    const incomingMostRecentChatCellData = JSON.parse(incomingMostRecentChatCell);
                    
                    const containsChat = currentOnGoingChatsData.some(chat => chat.ChatID === incomingMostRecentChatCellData.mostRecentChatCell.ChatID);
                    
                    if (!containsChat)
                    {
                         setCurrentOnGoingChatsData([...currentOnGoingChatsData, incomingMostRecentChatCellData.mostRecentChatCell]);
                         
                         setLoadingCurrentChatsProcessIndicator("Preparing...");
                    }
                    
                    return;
               }
               catch (error)
               {
                    console.log("Did not get most recent chat cell: ", error);
                    
                    return;
               }
          });
          
          supportIO.onEvent("supportNet", "chatsOnStatus", (incomingChatCells) => {
               try
               {
                    const incomingChatCellData = JSON.parse(incomingChatCells);
                    
                    const currentChats = incomingChatCellData.chatCells.filter((chat) => chat !== undefined && chat !== null);
                    
                    setLoadingCurrentChatsProcessIndicator("Preparing...");
                    
                    setCurrentOnGoingChatsData([...currentChats]);
                    
                    setLoadingCurrentChats(false);
                    
                    return;
               }
               catch (error)
               {
                    console.log("Did not get current chats: ", error);
                    
                    setLoadingCurrentChatsProcessIndicator("No chats yet!");
                     
                    setLoadingCurrentChats(true);
                    
                    return;
               }
          });
          
          return () => {
               supportIO.closeSocket("supportNet");
          };
     }, [signedUser]);
     
     useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return () => {};
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <div
               className = "gr-scroller"
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <PatrolBuddySidePanel
                    currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    currentPatrolBuddyGoSidePanelData = {currentPatrolBuddyGoSidePanelData}
                    
                    setSidePanelVisible = {setSidePanelVisible}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    sidePanelRef = {sidePanelRef}
                    
                    containsTopHeader = {true}
               >
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              fontSize : patrolBuddyGoTitleHover ? "1.269rem" : "1.069rem",
                              color : patrolBuddyGoTitleHover ? paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor) : processIndicatorColor,
                              marginTop : "95.69vh"
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoTitleHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoTitleHover(true);
                         }}
                    >
                         <HUDTyper speed = {processIndicatorTypeSpeed} >
                              {processIndicatorTitle}
                         </HUDTyper>
                    </label>
               </PatrolBuddySidePanel>
               
               <div
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         transition : "height 0.269s ease-in",
                         width : "calc(94.69%)",
                         borderRadius : "1rem",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         background : paletteColor(palette, "Interface.color5", patrolBuddyGoLoadingColor),
                         marginBottom : "1rem",
                         marginTop : "2.69rem",
                    }}
               >
               <div
                    className = "invisa-scroller"
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         alignItems : "center",
                         transition : "height 0.269s ease-in",
                         height : "49.69rem",
                         width : "calc(41.69%)",
                         overflowY : "scroll",
                         overflowX : "hidden",
                         borderRadius : "1rem",
                         boxShadow : "0 0 10px rgba(0, 0, 0, 0.469)",
                         background : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color4", patrolBuddyGoLoadingColor), 0.169),
                         marginBottom : "1rem",
                         marginLeft : "1rem",
                         marginTop : "0.869rem",
                    }}
               >
               {currentOnGoingChatsData.length > 0 ? currentOnGoingChatsData.map((currentOnGoingChatData, index) => (
               <div
                    key = {index}
                    
                    style = {{
                         position : "relative",
                         display : "flex",
                         flexDirection : "column",
                         cursor : "pointer",
                         transition : "height 0.269s ease-in, background 0.69s ease-in-out, scale 0.369s ease-in, box-shadow 0.569s ease-in",
                         boxShadow : currentOnGoingChat === index ? "inset 0 0 10px rgba(0, 0, 0, 0.69)" : "3px 3px 9.69px -2.69px rgba(0, 0, 0, 0.469)",
                         scale : currentOnGoingChat === index ? "1.0269" : "1",
                         height : "12.169rem",
                         width : "calc(95.69%)",
                         borderRadius : "1rem",
                         background : currentOnGoingChat === index ? colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color10", patrolBuddyGoLoadingColor), 0.69) : colorTransparency(paletteColor(palette, "PatrolBuddyAccent.color18", patrolBuddyGoLoadingColor), 0.569),
                         marginBottom : "1rem",
                         marginTop : "0.869rem",
                    }}
                    
                    onMouseLeave = {() => {
                         setCurrentOnGoingChatHover(false);
                         
                         setCurrentOnGoingChat(null);
                    }}
                    
                    onMouseEnter = {() => {
                         setCurrentOnGoingChatHover(true);
                         
                         setCurrentOnGoingChat(index);
                    }}
                    
                    onClick = {() => {
                         localStorage.setItem("CurrentSupportChat", JSON.stringify(currentOnGoingChatData));
                         
                         PatrolBuddyGoSupportChatPanelNavigationActivation(pbgPanelNavigator);
                         
                         return;
                    }}
               >
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              justifyContent : "flex-start",
                              cursor : "pointer",
                              transition : "height 0.269s ease-in",
                              width : "calc(87%)",
                         }}
                    >
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "#A5A5A5",
                              width : "calc(57%)",
                              marginLeft : "0.969rem",
                              marginTop : "1.269rem",
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.ChatTitle}
                         </HUDTyper>
                    </label>
                    
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "right",
                              wordWrap : "break-word",
                              fontSize : "1.069rem",
                              color : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "#A5A5A5",
                              width : "calc(48.069%)",
                              marginTop : "0.969rem"
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.MostRecentMessageDate}
                         </HUDTyper>
                    </label>
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "0.969rem",
                              color : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "#A5A5A5",
                              width : "calc(87.69%)",
                              marginLeft : "1.269rem",
                              marginTop : "1.269rem"
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.MostRecentMessageSender}
                         </HUDTyper>
                    </label>
                    
                    {currentOnGoingChatData.MostRecentMessage && containsHTML(currentOnGoingChatData.MostRecentMessage) ? (
                         <pre
                              style = {{
                                   position : "relative",
                              flexShrink : 0,
                              cursor : "pointer",
                              overflowY : "hidden",
                              transition : "font-size 0.69s ease-in-out, background 0.69s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bold",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "0.5869rem",
                              color : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "transparent",
                              height : "4.1069rem",
                              width : "calc(85.69%)",
                              background : currentOnGoingChat === index ? "transparent" : "linear-gradient(to bottom, #A5A5A5 0%, transparent 79.69%)",
                              WebkitBackgroundClip : currentOnGoingChat === index ? undefined : "text",
                              WebkitTextFillColor : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "transparent",
                              marginLeft : "2.269rem",
                              marginTop : "1.069rem"
                              }}
                              
                              dangerouslySetInnerHTML={{ __html : sanitizeHTML(currentOnGoingChatData.MostRecentMessage) }}
                         />
                    ) : (
                         <pre
                              style = {{
                                   position : "relative",
                                   flexShrink : 0,
                                   cursor : "pointer",
                                   overflowY : "hidden",
                                   transition : "font-size 0.69s ease-in-out, background 0.69s ease-in-out, color 0.69s ease-in-out",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bold",
                                   textAlign : "left",
                                   wordWrap : "break-word",
                                   fontSize : "0.9169rem",
                                   color : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "transparent",
                                   height : "3.1069rem",
                                   width : "calc(85.69%)",
                                   background : currentOnGoingChat === index ? "transparent" : "linear-gradient(to bottom, #A5A5A5 0%, transparent 79.69%)",
                                   WebkitBackgroundClip : currentOnGoingChat === index ? undefined : "text",
                                   WebkitTextFillColor : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "transparent",
                                   marginLeft : "2.269rem",
                                   marginTop : "1.069rem"
                              }}
                         >
                              <HUDTyper speed = {7} >
                                   {currentOnGoingChatData.MostRecentMessage}
                              </HUDTyper>
                         </pre>
                    )}
                    
                    <div
                         style = {{
                              position : "absolute",
                              display : "flex",
                              flexDirection : "row",
                              justifyContent : "flex-start",
                              cursor : "pointer",
                              width : "calc(91.69%)",
                              bottom : "0.069rem",
                         }}
                    >
                    <div
                         style = {{
                              position : "relative",
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, background 0.69s ease-in-out",
                              background : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "#787878",
                              borderRadius : "3rem",
                              height : "1.269rem",
                              width : "1.269rem",
                              marginLeft : "0.869rem",
                              marginBottom : "0.69rem",
                         }}
                    >
                         
                    </div>
                    
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              cursor : "pointer",
                              transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              textAlign : "left",
                              wordWrap : "break-word",
                              fontSize : "0.9269rem",
                              color : currentOnGoingChat === index ? "rgba(227, 243, 255, 1)" : "#7d7d7d",
                              width : "calc(77.69%)",
                              marginBottom : "0.369rem",
                              marginLeft : "0.469rem",
                              marginTop : "0.169rem"
                         }}
                    >
                         <HUDTyper speed = {7} >
                              {currentOnGoingChatData.ChatStatus}
                         </HUDTyper>
                    </label>
                    </div>
               </div>
               )) : loadingCurrentChats === true ? (
                    <>
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : "2.269rem",
                                   color : "#A5A5A5",
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "1.69rem",
                                   flexShrink : 0
                              }}
                         >
                              <HUDTyper speed = {7} >
                                   Loading chats...
                              </HUDTyper>
                         </label>
                    </>
               ) : (
                    <>
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : "2.269rem",
                                   color : "#A5A5A5",
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "1.69rem",
                                   flexShrink : 0
                              }}
                         >
                              <HUDTyper speed = {7} >
                                   {loadingCurrentChatsProcessIndicator}
                              </HUDTyper>
                         </label>
                    </>
               )}
               </div>
               </div>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <div
                    style = {{
                         position : "relative",
                         height : "15rem",
                         width : "calc(87%)",
                         background : "transparent",
                         marginTop : "1.469rem",
                    }}
               >
                    
               </div>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoSupportPanel;