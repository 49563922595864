import * as React from "react";

import { HUDOperationsUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";











const patrolBuddyGoLoadingColor = "rgba(42, 82, 190, 1)";

const getPatrolBuddyGoSidePanelFooterData = (signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible, supportPanel = false, useCustomAction = false) => {
     var patrolBuddyGoSidePanelFooterData = signedIn === true ? [
          {
               id : 1,
               accessLevelR : 0,
               mediaType : "image",
               navigationID : "/support",
               label : signedIn === true && supportPanel === false ? "Support" : "Contact Us",
               hudAction : () => {
                    if (HUDOperationsUtilityManager.checkOperation("openingSupport")) { return; }
                    
                    if (signedIn && supportPanel === false) 
                    {
                         pbgPanelNavigator("/support");
                         
                         return;
                    }
                    
                    setContactUsModalVisible("visible");
                    
                    return;
               }
          },
          {
               id : 2,
               accessLevelR : 0,
               mediaType : "image",
               navigationID : "/",
               label : "Log Out",
               hudAction : () => {
                    if (HUDOperationsUtilityManager.checkOperation("loggingOut")) { return; }
                    
                    if (!signedIn) { return; }
                    
                    signOut();
                    
                    PatrolBuddyGoProductPanelNavigationActivation(pbgPanelNavigator);
                    
                    return;
               }
          },
     ] : [
          {
               id : 1,
               accessLevelR : 0,
               mediaType : "image",
               navigationID : "/support",
               label : "Contact Us",
               hudAction : () => {
                    setContactUsModalVisible("visible");
                    
                    return;
               }
          }
     ];
     
     return patrolBuddyGoSidePanelFooterData;
};
     
const getPatrolBuddyGoSidePanelData = (currentPage = "Control Panel", supportPanel = false) => {
     const patrolBuddyGoSidePanelData = supportPanel ? [
               {
                    id : 1,
                    accessLevelR : 1,
                    navigationID : "/support/new-chat",
                    iconColor : "#FFFF",
                    iconName : "fa-plus",
                    icon : null,
                    label : "Add Chat"
               }
          ] : [
               {
                    id : 1,
                    accessLevelR : 0,
                    mediaType : "image",
                    navigationID : "/",
                    icon : require("../HUDContent/HUDPatrolBuddyGoIcon.png"),
                    label : "PatrolBuddyGo"
               },
               {
                    id : 2,
                    accessLevelR : 3,
                    mediaType : "image",
                    navigationID : "/control-panel",
                    iconColor : "rgba(127, 188.69, 215.69, 1)",
                    iconName : "fa-sliders-h",
                    icon : null,
                    label : "Control Panel",
               },
               {
                    id : 3,
                    accessLevelR : 2,
                    mediaType : "image",
                    navigationID : "/global-view",
                    iconColor : "rgba(127, 188.69, 215.69, 1)",
                    iconName : "fa-globe-americas",
                    icon : null,
                    label : "Global View"
               },
               {
                    id : 4,
                    accessLevelR : 2,
                    mediaType : "image",
                    navigationID : "/account-creation",
                    icon : require("../HUDContent/AddPatrolBuddyPatrollerButtonIcon.png"),
                    label : "Add Patrollers"
               },
               {
                    id : 5,
                    accessLevelR : 0,
                    mediaType : "image",
                    navigationID : "/patrol-data-hub",
                    icon : require("../HUDContent/HUDPatrolBuddyGoDatabaseButton.png"),
                    label : "Patrol Data Hub"
               },
     ];
     
     return patrolBuddyGoSidePanelData.filter(panelData => panelData.label !== currentPage);
};










export {
     getPatrolBuddyGoSidePanelFooterData,
     getPatrolBuddyGoSidePanelData,
     patrolBuddyGoLoadingColor
};