import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";

import PatrolBuddyGoHUDUtilityManager, { PatrolBuddyGoProductPanelNavigationActivation, PatrolBuddyGoAccountCreationPanelNavigationActivation, PatrolBuddyGoAuthenticationPanelNavigationActivation } from "../HUDManagers/PatrolBuddyGoHUDUtilityManager.js";

import { getPatrolBuddyGoSidePanelFooterData, getPatrolBuddyGoSidePanelData, patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import PatrolBuddyGoHUDPatrolBuddyDepartmentsManager from "../HUDManagers/PatrolBuddyGoHUDPatrolBuddyDepartmentsManager.js";

import { HUDUniversalHUDUtilityManager, IfNightTime, Delay } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDOperationsUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSideContainerPanel, HUDLoading, HUDInput, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDTracker } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import HUDIoTide from "@trap_stevo/iotide-client";

import PatrolBuddySidePanel from "../HUDComponents/PatrolBuddySidePanel.js";

import ContactUsModal from "../HUDComponents/ContactUsModal.js";

import PBGViewCell from "../HUDComponents/PBGViewCell.js";











const ioTide = new HUDIoTide();

function PatrolBuddyGoGlobalViewPanel(patrolBuddyGoGlobalViewPanelConfigurationSettings) 
{
    const {
         
         ...rest
    } = patrolBuddyGoGlobalViewPanelConfigurationSettings;
    
    const [loadingOnlinePatrolBuddies, setLoadingOnlinePatrolBuddies] = useState(false);
    
    const [patrolBuddyGoTitleHover, setPatrolBuddyGoTitleHover] = useState(false);
    
    const [processIndicatorTitle, setProcessIndicatorTitle] = useState("Global View");
    
    const [processIndicatorFontSize, setProcessIndicatorFontSize] = useState("1.969rem");
    
    const [processIndicatorColor, setProcessIndicatorColor] = useState("#616161");
    
    const [processIndicatorTypeSpeed, setProcessIndicatorTypeSpeed] = useState(3);
    
    const [contactUsModalVisible, setContactUsModalVisible] = useState("hidden");
    
    const [restrictedAccessed, setRestrictedAccessed] = useState(false);
    
    const [usernameLabelHover, setUsernameLabelHover] = useState(false);
    
    const [sideContainerPanelVisible, setSideContainerPanelVisible] = useState(false);
    
    const [nightModeActivated, setNightModeActivated] = useState(IfNightTime());
    
    const [pbgSearchInputInFocus, setPBGSearchInputInFocus] = useState(false);
    
    const [sidePanelVisible, setSidePanelVisible] = useState(false);
    
    const [pbgSearchInputFocus, setPBGSearchInputFocus] = useState(false);
    
    const [pbgSearchInputValue, setPBGSearchInputValue] = useState("");
    
    const [currentPBGCells, setCurrentPBGCells] = useState([]);
    
    const [currentPBGs, setCurrentPBGs] = useState(new Map());
    
    const [pbgCells, setPBGCells] = useState([]);
    
    const { sessionCache, setSessionCache, addToSessionCache, clearSessionCache } = useHUDSession();
    
    const { getFromSessionTracker } = useHUDTracker();
    
    const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
    
    const { palette, paletteColor, colorTransparency } = useHUDPalette();
    
    
    
    
    
    
    
    
    
    
    
    const currentPatrolCamSessionID = useRef(HUDUniversalHUDUtilityManager.GenerateUUID());
    
    const globalViewPanelRef = useRef(null);
    
    const sidePanelRef = useRef(null);
    
    
    
    
    
    
    
    
    
    
    
    const pbgPanelNavigator = useNavigate();
    
    var patrolBuddyGoSidePanelFooterData = getPatrolBuddyGoSidePanelFooterData(signedIn, signOut, pbgPanelNavigator, PatrolBuddyGoProductPanelNavigationActivation, setContactUsModalVisible);
    
    var patrolBuddyGoSidePanelData = getPatrolBuddyGoSidePanelData("Global View");
    
    let currentPatrolBuddyGoSidePanelFooterData = patrolBuddyGoSidePanelFooterData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
    
    let currentPatrolBuddyGoSidePanelData = patrolBuddyGoSidePanelData.filter(actionButton => signedIn && actionButton.accessLevelR <= signedUser.accessLevel.accountAccessR);
    
    
    
    
    
    
    
    
    
    
    const handlePBGSearchInputUpdate = (e) => {
         const inputValue = e.target.value;
         
         setPBGSearchInputValue(inputValue);
         
         if (inputValue.trim() === "")
         {
              setCurrentPBGCells(pbgCells);
              
              return;
         }
         
         const searchedPBGs = pbgCells.filter(pbgCell => `${pbgCell.pbgDisplayName} (${pbgCell.serialK})`.includes(inputValue) || pbgCell.user && (pbgCell.user.displayName.includes(inputValue) || pbgCell.user.username.includes(inputValue)));
         
         setCurrentPBGCells(searchedPBGs);
         
         return;
    };
    
    const updatePBGList = (pbgList, newPBG) => {
         const existingIndex = pbgList.findIndex(pbg => pbg.serialK === newPBG.serialK);
         
         if (existingIndex !== -1) 
         {
              const updatedPBGList = [...pbgList];
              
              updatedPBGList[existingIndex] = newPBG;
              
              return updatedPBGList;
         } 
         else 
         {
              return [...pbgList, newPBG];
         }
    };
    
    const processCurrentPBGs = async (incomingPBGData) => {
         let onlinePBGUsers = getFromSessionTracker("pbgUsersOnline");
         
         const patrolBuddyOfficer = incomingPBGData && onlinePBGUsers ? onlinePBGUsers.get(incomingPBGData.serialK) : null;
         
         const currentPBG = incomingPBGData ? currentPBGs.get(incomingPBGData.serialK) : null;
         
         const currentPBGData = incomingPBGData && incomingPBGData.serialK ? {
               serialK : incomingPBGData.serialK,
               jurisdiction : currentPBG ? currentPBG.jurisdiction : "N/A",
               precinct : currentPBG ? currentPBG.precinct : null,
               locality : currentPBG ? currentPBG.locality : null,
               pbgDisplayName : `Car ${incomingPBGData.serialK}`,
               pbgStreamsSRC : `https://gpn.patrolbuddygo.com/${incomingPBGData.serialK}`,
               pbgSignalSRC : `wss://gpn.patrolbuddygo.com/${incomingPBGData.serialK}`,
               pbgStatus : `Online ~ ${HUDUniversalHUDUtilityManager.ConvertUTCDateToDateDisplay(parseFloat(incomingPBGData.connectedOn), 1)}`,
               user : patrolBuddyOfficer ? patrolBuddyOfficer : {}
         } : null;
         
         if (!currentPBGData) { return; }
         
         await Delay(869);
         
         if (pbgSearchInputValue.trim() === "")
         {
              setCurrentPBGCells((prCurrentPBGCells) => updatePBGList(prCurrentPBGCells, currentPBGData));
         }
         
         setPBGCells((prPBGCells) => updatePBGList(prPBGCells, currentPBGData));
         
         return;
    };
    
    const handleLoadOnlinePBGs = async () => {
         setLoadingOnlinePatrolBuddies(true);
         
         const pbgs = await ioTide.emitEventWithResponse("globalViewNet", "get-current-online-patrolbuddies-in-precinct", "current-online-patrolbuddies-in-precinct", { precinct : signedUser.precinct.precinct }, 50000);
         
         if (!pbgs) { setLoadingOnlinePatrolBuddies(false); return; }
         
         const loadedPBGs = new Map();
         
         for (let pbg of pbgs)
         {
              loadedPBGs.set(pbg.serialK, pbg);
         }
         
         setLoadingOnlinePatrolBuddies(false);
         
         setCurrentPBGs(loadedPBGs);
         
         return;
    };
    
    const handleMouseMove = (e) => {
         HUDUniversalHUDUtilityManager.CheckMouseNearComponent(setSidePanelVisible, sidePanelRef, 69, e);
         
         HUDUniversalHUDUtilityManager.CheckMouseNearEdge(e, sideContainerPanelVisible ? 427 : 27.69, "right", globalViewPanelRef, setSideContainerPanelVisible);
         
         return;
    };
    
    
    
    
    
    
    
    
    
    
    
    useEffect(() => {
          if (signedIn === false && signedUser.accessLevel === undefined && restrictedAccessed === false)
          {
               setRestrictedAccessed(true);
          }
          
          if (signedIn === true)
          {
               setRestrictedAccessed(false);
               
               setProcessIndicatorFontSize("1.969rem");
               
               setProcessIndicatorColor("#616161");
          }
          
          if (restrictedAccessed === true)
          {
               const intervalId = setInterval(() => {
                    setProcessIndicatorColor((previousColor) => (previousColor === "#616161" ? "#fa0707" : "#616161"));
                    
                    setProcessIndicatorFontSize((previousFont) => (previousFont === "1.969rem" ? "2.169rem" : "1.969rem"));
               }, 699);
               
               const openAuthentication = setInterval(() => {
                    PatrolBuddyGoAuthenticationPanelNavigationActivation(pbgPanelNavigator);
               }, 1699);
               
               return () => {
                    clearInterval(intervalId);
                    
                    clearInterval(openAuthentication);
               }
          }
          
          return;
     }, [signedUser, signedIn, restrictedAccessed, setRestrictedAccessed]);
     
     useEffect(() => {
          if (!signedIn) { return; }
          
          setLoadingOnlinePatrolBuddies(true);
          
          ioTide.createIO("globalViewNet", "https://utilities.patrolbuddygo.com:9269");
          
          handleLoadOnlinePBGs();
     }, [signedIn]);
     
     useEffect(() => {
          const nightModeWatchInterval = setInterval(() => {
               setNightModeActivated(IfNightTime());
          }, 60000);
          
          return () => {
               clearInterval(nightModeWatchInterval);
          };
     }, []);
     
     useEffect(() => {
         if (HUDOperationsUtilityManager.checkOperation("Connecting to Global View Net") || !currentPBGs || Array.from(currentPBGs.values()).length <= 0) { return; }
         
         setLoadingOnlinePatrolBuddies(true);
         
         ioTide.createIO("globalViewNet", "https://utilities.patrolbuddygo.com:9269");
         
         ioTide.onEvent("globalViewNet", "got-online-patrolbuddy", (patrolBuddyData) => {
              if (HUDOperationsUtilityManager.checkOperation("Processing current pbg")) { return; }
              
              processCurrentPBGs(patrolBuddyData);
         });
         
         const globalViewNT = setInterval(() => {
              for (let currentPBG of Array.from(currentPBGs.values()))
              {
                   ioTide.emitEvent("globalViewNet", "get-online-patrolbuddy", { serial : `PBG-${currentPBG.serialK}` });
              }
         }, 2169);
         
         return () => {
              ioTide.closeSocket("globalViewNet");
              
              clearInterval(globalViewNT);
         };
     }, [currentPBGs]);
    
    
    
    
    
    
    
    
    
    
    
    return (
          <div
               className = "gr-scroller"
               
               ref = {globalViewPanelRef}
               
               style = {{
                    position : "relative",
                    display : "flex",
                    flexDirection : "column",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    transition : "background 1.269s ease-in-out",
                    background : restrictedAccessed ? "black" : paletteColor(palette, "Interface.color1", patrolBuddyGoLoadingColor),
                    alignItems : "center",
                    overflowX : "hidden",
                    overflowY : "auto"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               {PatrolBuddyGoHUDUtilityManager.ConfirmPatrolBuddyGoControlPanelAccess(signedUser.accessLevel) && signedIn === true && restrictedAccessed === false ? (
               <>
               <PatrolBuddySidePanel
                    currentPatrolBuddyGoSidePanelFooterData = {currentPatrolBuddyGoSidePanelFooterData}
                    
                    currentPatrolBuddyGoSidePanelData = {currentPatrolBuddyGoSidePanelData}
                    
                    setSidePanelVisible = {setSidePanelVisible}
                    
                    sidePanelVisible = {sidePanelVisible}
                    
                    sidePanelRef = {sidePanelRef}
               >
                    <label
                         style = {{
                              position : "relative",
                              flexShrink : 0,
                              transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                              fontFamily : "ModeNine",
                              fontWeight : "bolder",
                              fontSize : patrolBuddyGoTitleHover ? "1.269rem" : "1.069rem",
                              color : patrolBuddyGoTitleHover ? paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor) : processIndicatorColor,
                              marginTop : "95.69vh"
                         }}
                         
                         onMouseLeave = {() => {
                              setPatrolBuddyGoTitleHover(false);
                         }}
                         
                         onMouseEnter = {() => {
                              setPatrolBuddyGoTitleHover(true);
                         }}
                    >
                         <HUDTyper speed = {processIndicatorTypeSpeed} >
                              {processIndicatorTitle}
                         </HUDTyper>
                    </label>
               </PatrolBuddySidePanel>
               
               <HUDIcon
                  iconConfigurationSettings = {{
                       position : "fixed",
                       transition : "all 0.369s ease",
                       transform : sideContainerPanelVisible ? "rotate(180deg)" : "rotate(0deg)",
                       color : sideContainerPanelVisible ? "#9FD1D9" : paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor),
                       height : "1.269rem",
                       zIndex : 869,
                       right : sideContainerPanelVisible ? "398.69px" : "7.69px",
                       top : "calc(50%)"
                  }}
                  
                  name = "fa-chevron-left"
               />
               
               <HUDSideContainerPanel
                  sideContainerConfigurationSettings = {{
                       background : !sideContainerPanelVisible ? `linear-gradient(to bottom, ${paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor)}, ${paletteColor(palette, "PatrolBuddyLights.color3", patrolBuddyGoLoadingColor)})` : paletteColor(palette, "Interface.color5", patrolBuddyGoLoadingColor),
                       width : "377.69px",
                       paddingLeft : "0.69rem"
                  }}
                  
                  controlledOpen = {sideContainerPanelVisible}
                  
                  displayCloseButton = {false}
                  
                  displayBackDrop = {false}
                  
                  position = "right"
               >
                  {sideContainerPanelVisible ? (
                       <div
                            style = {{
                                 position : "relative",
                                 display : "flex",
                                 flexDirection : "column",
                                 alignItems : "center",
                                 width : "calc(100%)"
                            }}
                       >
                       <HUDInput
                            inputContainerConfigurationSettings = {{
                                 position : "relative",
                                 display : "flex",
                                 background : "transparent",
                                 borderRadius : "0.69rem",
                                 outline : "none",
                                 borderLeft : "none",
                                 borderTop : "none",
                                 boxShadow : pbgSearchInputInFocus ? "0 0 10px rgba(255, 255, 255, 0.69)" : "none",
                                 borderBottom : pbgSearchInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                 borderRight : pbgSearchInputInFocus ? "solid #C9F5FF" : "solid 0.269px #A5A5A5",
                                 color : pbgSearchInputInFocus ? "#C9F5FF" : "#A5A5A5",
                                 scale : pbgSearchInputInFocus ? "1.069" : "1",
                                 transition : "scale 0.3s ease-in-out, opacity 0.69s ease-in-out, color 0.69s ease-in-out, border-bottom 0.3s ease-in-out, border-right 0.3s ease-in-out, box-shadow 0.269s ease-in",
                                 fontFamily : "ModeNine",
                                 fontSize : "1.069rem",
                                 height : "2.269rem",
                                 width : "calc(91.69%)",
                                 marginBottom : "0.869rem",
                                 marginTop : "0.69rem",
                            }}
                            
                            placeholder = "Search for a PatrolBuddy..."
                            
                            handleInputUpdate = {handlePBGSearchInputUpdate}
                            
                            setInputFocus = {setPBGSearchInputFocus}
                            
                            setInputValue = {setPBGSearchInputValue}
                            
                            inputFocus = {pbgSearchInputFocus}
                            
                            inputValue = {pbgSearchInputValue}
                       />
                       
                       <div
                            style = {{
                                 position : "relative",
                                 display : "flex",
                                 flexDirection : "column",
                                 boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                                 borderRadius : "0.569rem",
                                 background : "rgba(147, 147, 147, 1)",
                                 height : "0.169rem",
                                 width : "calc(91.69%)",
                                 marginBottom : "1.69rem",
                                 marginTop : "1.069rem",
                            }}
                       />
                       
                       <div
                            className = "invisa-scroller"
                            
                            style = {{
                                 position : "relative",
                                 display : "flex",
                                 flexDirection : "column",
                                 overflowY : "auto",
                                 boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                                 borderRadius : "0.569rem",
                                 background : "rgba(0, 0, 0, 0.069)",
                                 height : "82.69vh",
                                 width : "calc(91.69%)",
                                 paddingBottom : "0.69rem",
                                 paddingRight : "0.369rem",
                                 paddingLeft : "0.369rem",
                                 paddingTop : "0.369rem",
                                 marginRight : "0.69rem",
                                 marginLeft : "0.69rem",
                            }}
                       >
                            {currentPBGCells && currentPBGCells.length > 0 ? (
                                 <>
                                 {currentPBGCells.map((pbgCell, index) => (
                                 <PBGViewCell
                                   key = {index}
                                   
                                   pbgViewCellContainerConfigurationSettings = {{
                                        height : "269px",
                                        width : "328px",
                                        marginBottom : "1.69rem"
                                   }}
                                   
                                   displayName = {`${pbgCell.pbgDisplayName} (${pbgCell.serialK})`}
                                   
                                   notificationViewHeaderTitle = {pbgCell.user && pbgCell.user.username ? `${pbgCell.user.displayName} : ${pbgCell.user.accessLevel.accountAccessLevelP}` : "N/A : Officer Offline"}
                                   
                                   notificationViewFooterTitle = {pbgCell.pbgStatus}
                                   
                                   patrolCamSessionID = {currentPatrolCamSessionID.current}
                                   
                                   nightModeActivated = {nightModeActivated}
                                   
                                   pbgViewerHeight = "184.69px"
                                   
                                   pbgViewerWidth = "328px"
                                   
                                   pbgViewSectionTitle = {pbgCell.locality ? `${pbgCell.locality.county}, ${pbgCell.locality.state}` : pbgCell.user && pbgCell.user.locality ? `${pbgCell.user.locality.county}, ${pbgCell.user.locality.state}` : "County ~ N/A, State ~ N/A"}
                                   
                                   pbgViewStatus = {pbgCell.pbgStatus}
                                   
                                   pbgStreamsSRC = {pbgCell.pbgStreamsSRC}
                                   
                                   pbgSignalSRC = {pbgCell.pbgSignalSRC}
                                   
                                   pbgViewSRC = {pbgCell.pbgViewSRC}
                                   
                                   pbgViewData = {pbgCell}
                                   
                                   muted = {true}
                                   
                                   volume = {0}
                                   
                                   addToSessionCache = {addToSessionCache}
                                   
                                   colorTransparency = {colorTransparency}
                                   
                                   paletteColor = {paletteColor}
                                   
                                   palette = {palette}
                                 />
                                 ))}
                                 </>
                            ) : !loadingOnlinePatrolBuddies ? (
                                 <label
                                      style = {{
                                           position : "relative",
                                           flexShrink : 0,
                                           fontFamily : "ModeNine",
                                           fontWeight : "bolder",
                                           fontSize : "1.269rem",
                                           color : "#A1A1A1",
                                           transition : "font-size 0.3s ease-in-out, color 0.69s ease-in-out",
                                           marginBottom : "1.69rem",
                                           marginTop : "1.69rem"
                                      }}
                                 >
                                      <HUDTyper speed = {5.69} >
                                           No online PatrolBuddy(s) found.
                                      </HUDTyper>
                                 </label>
                            ) : (
                                 <HUDLoading
                                      wordConfigurationSettings = {{
                                           fontFamily : "ModeNine",
                                           fontSize : "1.169rem",
                                           marginTop : "1.269rem"
                                      }}
                                      
                                      spaceLetterSpacing = "12.69px"
                                      
                                      letterSpacing = "0.69px"
                                      
                                      wordColor = {paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}
                                      
                                      word = "Loading..."
                                      
                                      type = "word"
                                 />
                            )}
                       </div>
                       </div>
                  ) : (
                       <>
                       </>
                  )}
               </HUDSideContainerPanel>
               
               <label
                    style = {{
                         position : "fixed",
                         fontFamily : "ModeNine",
                         fontWeight : "bolder",
                         textAlign : "center",
                         fontSize : usernameLabelHover ? "1.069rem" : "0.769rem",
                         color : usernameLabelHover ? "#A5A5A5" : "#616161",
                         transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                         marginTop : "0.569rem",
                         zIndex : "6999",
                         flexShrink : 0
                    }}
                    
                    onMouseLeave = {() => {
                         setUsernameLabelHover(false);
                    }}
                    
                    onMouseEnter = {() => {
                         setUsernameLabelHover(true);
                    }}
              >
                    <HUDTyper speed = {7} >
                         {signedUser.username}
                    </HUDTyper>
               </label>
               
               <ContactUsModal
                    contactUsModalVisibilityHandler = {setContactUsModalVisible}
                    
                    modalVisibility = {contactUsModalVisible}
                    
                    signedUser = {signedUser}
                    
                    signedIn = {signedIn}
               >
                    
               </ContactUsModal>
               </>
               ) : (
                    <>
                         <video 
                              style = {{ 
                                   width : "calc(57.69%)" 
                              }}
                              
                              src = {require("../HUDContent/PatrolBuddyGoSplashScreen.mp4")}
                              
                              playsInline = {true}
                              autoPlay = {true}
                              preload = "auto"
                              play = {true}
                              loop = {true}
                              muted = {true}
                         >
                              
                         </video>
                         
                         <label
                              style = {{
                                   position : "relative",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bolder",
                                   textAlign : "center",
                                   fontSize : patrolBuddyGoTitleHover ? "2.169rem" : processIndicatorFontSize,
                                   color : patrolBuddyGoTitleHover ? "#fa0707" : processIndicatorColor,
                                   transition : "font-size 0.69s ease-in-out, color 0.69s ease-in-out",
                                   marginTop : "-5rem",
                                   flexShrink : 0
                              }}
                              
                              onMouseLeave = {() => {
                                   setPatrolBuddyGoTitleHover(false);
                              }}
                              
                              onMouseEnter = {() => {
                                   setPatrolBuddyGoTitleHover(true);
                              }}
                         >
                              <HUDTyper speed = {processIndicatorTypeSpeed} >
                                   Restricted Access
                              </HUDTyper>
                         </label>
                    </>
               )}
          </div>
     );
}

export default PatrolBuddyGoGlobalViewPanel;