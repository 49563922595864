import * as React from "react";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDSidePanel, HUDIcon } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";











function PatrolBuddySidePanel(patrolBuddySidePanelConfigurationSettings)
{
     const {
          currentPatrolBuddyGoSidePanelFooterData = [],
          currentPatrolBuddyGoSidePanelData = [],
          containsTopHeader = false,
          topHeaderContents = [],
          setSidePanelVisible,
          sidePanelVisible,
          sidePanelRef,
          children
     } = patrolBuddySidePanelConfigurationSettings;
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const [sidePanelLogoHover, setSidePanelLogoHover] = useState(false);
     
     const [backButtonHover, setBackButtonHover] = useState(false);
     
     
     
     
     
     
     
     
     
     
     
     const pbgPanelNavigator = useNavigate();
     
     
     
     
     
     
     
     
     
     
     
     
     
     return (
          <HUDSidePanel
               sidePanelRef = {sidePanelRef}
               
               sidePanelAttachmentContainerConfigurationSettings = {{
                    transition : "all 0.5969s ease"
               }}
               
               sidePanelMinimizeTabButtonConfigurationSettings = {{
                    transition : "all 0.269s ease",
                    background : "transparent",
                    color : sidePanelVisible ? paletteColor(palette, "PatrolBuddyLightAccent.color13", patrolBuddyGoLoadingColor) : paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)
               }}
               
               sidePanelFooterButtonUnderlineConfigurationSettings = {{
                    background : `linear-gradient(to right, ${paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}, ${paletteColor(palette, "PatrolBuddyLightAccent.color13", patrolBuddyGoLoadingColor)})`
               }}
               
               sidePanelButtonUnderlineConfigurationSettings = {{
                    background : `linear-gradient(to right, ${paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}, ${paletteColor(palette, "PatrolBuddyLightAccent.color13", patrolBuddyGoLoadingColor)})`
               }}
               
               sidePanelHeaderLogoConfigurationSettings = {{
                    transition : "scale 0.3s ease-in-out, margin-left 0.3s ease",
                    scale : sidePanelLogoHover ? "1.069" : "1",
                    cursor : "pointer",
                    marginLeft : sidePanelLogoHover ? "0.269rem" : "0rem",
               }}
               
               sidePanelContainerConfigurationSettings = {{
                    background : !sidePanelVisible ? `linear-gradient(to bottom, ${paletteColor(palette, "PatrolBuddyLights.color1", patrolBuddyGoLoadingColor)}, ${paletteColor(palette, "PatrolBuddyLights.color3", patrolBuddyGoLoadingColor)})` : paletteColor(palette, "Interface.color5", patrolBuddyGoLoadingColor),
               }}
               
               sidePanelTopHeaderConfigurationSettings = {{
                    marginTop : "0.69rem"
               }}
               
               sidePanelCurrentButtonIndicatorHoverColor = {paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}
               
               sidePanelFooterButtons = {currentPatrolBuddyGoSidePanelFooterData}
               
               sidePanelButtons = {currentPatrolBuddyGoSidePanelData}
               
               sidePanelTopHeaderContents = {containsTopHeader ? [
                    <HUDIcon 
                         iconConfigurationSettings = {{
                              transition : "scale 0.3s ease",
                              scale : backButtonHover ? "1.069" : "1",
                              cursor : "pointer"
                         }}
                         
                         name = "fa-chevron-left"
                         
                         color = {paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}
                         
                         onMouseLeave = {() => {
                              setBackButtonHover(false);
                              
                              return;
                         }}
                         
                         onMouseEnter = {() => {
                              setBackButtonHover(true);
                              
                              return;
                         }}
                         
                         onClick = {() => {
                              pbgPanelNavigator(-1);
                              
                              return;
                         }}
                    />,
                    ...topHeaderContents
               ] : []}
               
               sidePanelFooterButtonAction = {(sidePanelButton, index) => {
                    if (sidePanelButton.hudAction)
                    {
                         sidePanelButton.hudAction();
                    }
                    
                    return;
               }}
               
               sidePanelLogoHoverEnterAction = {() => {
                    setSidePanelLogoHover(true);
                    
                    return;
               }}
               
               sidePanelLogoHoverExitAction = {() => {
                    setSidePanelLogoHover(false);
                    
                    return;
               }}
               
               sidePanelButtonAction = {(sidePanelButton, index) => {
                    if (sidePanelButton.hudAction)
                    {
                         sidePanelButton.hudAction();
                         
                         return;
                    }
                    
                    pbgPanelNavigator(sidePanelButton.navigationID);
                    
                    return;
               }}
               
               sidePanelLogoAction = {() => {
                    window.open("https://generalizedrobotics.com", "_blank")
                    
                    return;
               }}
               
               logo = {require("../HUDContent/GeneralizedGRLegendaryLogoClassical.png")}
               
               sidePanelButtonBackgroundHoverColor = {paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}
               
               sidePanelButtonBackgroundColor = {paletteColor(palette, "PatrolBuddyAccent.color5", patrolBuddyGoLoadingColor)}
               
               minimizeTabPosition = "center-right"
               
               hideType = "Full"
               
               setVisible = {setSidePanelVisible}
               
               visible = {sidePanelVisible}
          >
               {children}
          </HUDSidePanel>
     );
};

export default PatrolBuddySidePanel;