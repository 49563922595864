import React, { useState, useEffect, useRef, useCallback } from "react";











const baseStyles = {
    container : {
        display : "flex",
        flexDirection : "column",
        fontFamily : "Arial, sans-serif",
        boxShadow : "0 4px 8px rgba(0, 0, 0, 0.2)",
        backgroundColor : "#2a2d37",
        color : "#ffffff",
        height : "100%"
    },
    header : {
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
        fontSize : "1.5em",
        fontWeight : "bold",
        boxShadow : "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderBottom : "2px solid #444",
        backgroundColor : "#1f1f1f",
        color : "#00bcd4",
        padding : "20px"
    },
    splitContainer : {
        position : "relative",
        display : "flex",
        flex : 1,
        flexWrap : "wrap",
        backgroundColor : "#2a2d37",
        padding : "10px"
    },
    screen : {
        position : "relative",
        flexShrink : 0,
        overflow : "auto",
        boxShadow : "0 4px 8px rgba(0, 0, 0, 0.269)",
        border : "1px solid #444",
        borderRadius : "10px",
        backgroundColor : "#333",
        padding : "10px",
        margin : "10px",
    },
    resizeHandle : {
        position : "absolute",
        backgroundColor : "#00bcd4",
        height : "10px",
        width : "10px",
        zIndex : 10,
    },
    top : {
        cursor : "ns-resize",
        transform : "translateX(-50%)",
        left : "50%",
        top : "-5px"
    },
    bottom : {
        cursor : "ns-resize",
        transform : "translateX(-50%)",
        bottom : "-5px",
        left : "50%"
    },
    left : {
        cursor : "ew-resize",
        transform : "translateY(-50%)",
        left : "-5px",
        top : "50%"
    },
    right : {
        cursor : "ew-resize",
        transform : "translateY(-50%)",
        right : "-5px",
        top : "50%"
    },
    topLeft : {
        cursor: "nwse-resize",
        left : "-5px",
        top : "-5px"
    },
    topRight : {
        cursor : "nesw-resize",
        right : "-5px",
        top : "-5px"
    },
    bottomLeft : {
        cursor : "nesw-resize",
        bottom : "-5px",
        left : "-5px"
    },
    bottomRight : {
        cursor : "nwse-resize",
        bottom : "-5px",
        right : "-5px"
    },
    sidePanel : {
        display : "flex",
        flexDirection : "column",
        boxShadow : "2px 0 4px rgba(0, 0, 0, 0.1)",
        borderRight : "2px solid #444",
        backgroundColor : "#1f1f1f",
        minWidth : "150px",
        padding : "10px"
    },
    button : {
        cursor : "pointer",
        transition : "background-color 0.3s ease",
        border : "none",
        borderRadius : "5px",
        backgroundColor : "#00bcd4",
        color : "#fff",
        padding : "10px 20px",
        margin : "5px 0"
    },
    buttonHover : {
        backgroundColor : "#0097a7",
    },
};

const mergeStyles = (base, custom) => ({ ...base, ...custom });

const HUDLayoutManagerContainer = ({ children, containerConfigurationSettings = {} }) => {
    return (
        <div style = {mergeStyles(baseStyles.container, containerConfigurationSettings)}>
            {children}
        </div>
    );
};

const HUDLayoutManagerHeader = ({ children, headerConfigurationSettings = {} }) => {
    return (
        <header style={mergeStyles(baseStyles.header, headerConfigurationSettings)}>
            {children}
        </header>
    );
};

const HUDLayoutManagerBody = ({ children, screens = [], bodyConfigurationSettings = {}, ...rest }) => {
    const defaultSizes = new Array(React.Children.count(children)).fill({ height : 100, width: 100 / React.Children.count(children)});
    
    const [sizes, setSizes] = useState(screens.length === React.Children.count(children) ? screens : defaultSizes);
    
    const [draggingIndex, setDraggingIndex] = useState(null);
    
    const [dragDirection, setDragDirection] = useState(null);
    
    const [hiddenScreens, setHiddenScreens] = useState([]);
    
    const [dragging, setDragging] = useState(false);
    
    const containerRef = useRef(null);
    
    
    
    
    
    
    
    
    
    
    
    const hideScreen = (index) => {
        setHiddenScreens([...hiddenScreens, index]);
    };

    const showScreen = (index) => {
        setHiddenScreens(hiddenScreens.filter((i) => i !== index));
    };

    const handleMouseMove = useCallback((e) => {
        if (!dragging || draggingIndex === null) { return; }
        
        const containerHeight = containerRef.current.offsetHeight;
        
        const containerWidth = containerRef.current.offsetWidth;
        
        let newSizes = [...sizes];
        
        switch (dragDirection)
        {
            case "ew-resize":
                const newWidth = (e.clientX / containerWidth) * 100;
                
                if (newWidth > 10 && newWidth < 90)
                {
                     newSizes[draggingIndex] = { ...newSizes[draggingIndex], width: newWidth };
                }
                
                break;
            case "ns-resize":
                const newHeight = (e.clientY / containerHeight) * 100;
                
                if (newHeight > 10 && newHeight < 90)
                {
                    newSizes[draggingIndex] = { ...newSizes[draggingIndex], height: newHeight };
                }
                
                break;
            case "nwse-resize":
            case "nesw-resize":
                const newCornerHeight = (e.clientY / containerHeight) * 100;
                
                const newCornerWidth = (e.clientX / containerWidth) * 100;
                
                if (newCornerWidth > 10 && newCornerWidth < 90 && newCornerHeight > 10 && newCornerHeight < 90)
                {
                     newSizes[draggingIndex] = { width: newCornerWidth, height: newCornerHeight };
                }
                
                break;
            default:
                break;
        }
        
        setSizes(newSizes);
    }, [dragging, draggingIndex, dragDirection, sizes]);
    
    const handleMouseUp = useCallback(() => {
        setDraggingIndex(null);
        
        setDragDirection(null);
        
        setDragging(false);
    }, []);
    
    
    
    
    
    
    
    
    
    
    
    useEffect(() => {
        if (dragging)
        {
            document.addEventListener("mousemove", handleMouseMove);
            
            document.addEventListener("mouseup", handleMouseUp);
        }
        else
        {
            document.removeEventListener("mousemove", handleMouseMove);
            
            document.removeEventListener("mouseup", handleMouseUp);
        }
        
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [dragging, handleMouseMove, handleMouseUp]);
    
    const renderScreens = () => {
        const screenComponents = [];
        
        React.Children.forEach(children, (child, index) => {
            if (hiddenScreens.includes(index)) { return; }
            
            const { outerScreenConfigurationSettings = {}, resizeHandleStyles = {}, draggable = false } = child.props;
            
            const { width, height } = sizes[index];
            
            screenComponents.push(
                <div key = {index} style = {{ ...baseStyles.screen, height : typeof height !== "string" ? `${height}%` : `${height}`, width : typeof width !== "string" ? `${width}%` : `${width}`, ...outerScreenConfigurationSettings }} >
                    {React.cloneElement(child, { hideScreen : () => hideScreen(index), draggable })}
                    
                    {draggable && (
                        <>
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.right, ...resizeHandleStyles.right }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index);
                                    
                                    setDragDirection("ew-resize");
                                }}
                            />
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.left, ...resizeHandleStyles.left }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index - 1);
                                    
                                    setDragDirection("ew-resize");
                                }}
                            />
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.top, ...resizeHandleStyles.top }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index);
                                    
                                    setDragDirection("ns-resize");
                                }}
                            />
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.bottom, ...resizeHandleStyles.bottom }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index);
                                    
                                    setDragDirection("ns-resize");
                                }}
                            />
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.topLeft, ...resizeHandleStyles.topLeft }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index);
                                    
                                    setDragDirection("nwse-resize");
                                }}
                            />
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.topRight, ...resizeHandleStyles.topRight }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index);
                                    
                                    setDragDirection("nesw-resize");
                                }}
                            />
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.bottomLeft, ...resizeHandleStyles.bottomLeft }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index);
                                    
                                    setDragDirection("nesw-resize");
                                }}
                            />
                            <div
                                style = {{ ...baseStyles.resizeHandle, ...baseStyles.bottomRight, ...resizeHandleStyles.bottomRight }}
                                
                                onMouseDown = {() => {
                                    setDragging(true);
                                    
                                    setDraggingIndex(index);
                                    
                                    setDragDirection("nwse-resize");
                                }}
                            />
                        </>
                    )}
                </div>
            );
        });
        
        return screenComponents;
    };
    
    const renderHiddenScreenButtons = () => {
        return hiddenScreens.map((index) => (
            <button
                key = {index}
                
                style = {baseStyles.button}
                
                onMouseEnter = {(e) => (e.target.style.backgroundColor = baseStyles.buttonHover.backgroundColor)}
                
                onMouseLeave = {(e) => (e.target.style.backgroundColor = baseStyles.button.backgroundColor)}
                
                onClick = {() => showScreen(index)}
            >
                Show Screen {index + 1}
            </button>
        ));
    };
    
    return (
        <div ref = {containerRef} style = {{ display : "flex", height : "100%" }}>
            {hiddenScreens.length > 0 && <div style = {baseStyles.sidePanel} >{renderHiddenScreenButtons()}</div>}
            
            <div style = {{ ...mergeStyles(baseStyles.splitContainer, { overflowY : "auto", paddingBottom : "3.69rem", ...bodyConfigurationSettings }) }} {...rest} >
                {renderScreens()}
            </div>
        </div>
    );
};

const HUDLayoutManagerScreen = ({ children, outerScreenConfigurationSettings = {}, innerScreenConfigurationSettings = {}, screenConfigurationSettings = {}, hideScreen, showHideButton = false, customHideButton, resizeHandleStyles = {} }) => {
    return (
        <div style = {mergeStyles(innerScreenConfigurationSettings, screenConfigurationSettings)} >
            {showHideButton && (
                <div style = {{ position : "absolute", top : 10, right : 10 }} >
                    {customHideButton ? (
                        React.cloneElement(customHideButton, { onClick : hideScreen })
                    ) : (
                        <button
                            style = {baseStyles.button}
                            onMouseEnter = {(e) => (e.target.style.backgroundColor = baseStyles.buttonHover.backgroundColor)}
                            onMouseLeave = {(e) => (e.target.style.backgroundColor = baseStyles.button.backgroundColor)}
                            onClick = {hideScreen}
                        >
                            Hide
                        </button>
                    )}
                </div>
            )}
            
            {children}
        </div>
    );
};

export { HUDLayoutManagerContainer, HUDLayoutManagerHeader, HUDLayoutManagerBody, HUDLayoutManagerScreen };
