import * as React from "react";











var pbgRanks = [
     {
          patrolAccessR : 6,
          patrolAccessN : "PBGHeadAdmin",
          patrolAccessP : "PBGHeadAdmin",
          patrolAccessID : 5
     },
     {
          patrolAccessR : 5,
          patrolAccessN : "PBGSuperAdmin",
          patrolAccessP : "PBGSuperAdmin",
          patrolAccessID : 6
     },
     {
          patrolAccessR : 4,
          patrolAccessN : "PBGAdmin",
          patrolAccessP : "PBGAdmin",
          patrolAccessID : 1
     },
     {
          patrolAccessR : 3,
          patrolAccessN : "PBGSupport",
          patrolAccessP : "PBGSupport",
          patrolAccessID : 7
     },
     {
          patrolAccessR : 2,
          patrolAccessN : "Patrol Chief",
          patrolAccessP : "Pief",
          patrolAccessID : 2
     },
     {
          patrolAccessR : 1,
          patrolAccessN : "Patroller",
          patrolAccessP : "Patroller",
          patrolAccessID : 3
     }
];

var PatrolBuddyGoHUDAccessManager = {
     getPBGRanks : function()
     {
          return pbgRanks;
     },
     
     getPBGRank(pbgRankP)
     {
          const desiredPBGRank = pbgRanks.filter(pbgRankData => pbgRankData.patrolAccessN === pbgRankP);
          
          return desiredPBGRank.length > 0 ? desiredPBGRank[0] : {};
     }
};

export default PatrolBuddyGoHUDAccessManager;