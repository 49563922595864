import * as React from "react";

import { useState, useEffect } from "react";

import { HUDOperationsUtilityManager, HUDEventManager, ConvertNumberToMoneyFormat } from "@trap_stevo/legendarybuilderproreact-ui";

import { Fade } from "@trap_stevo/legendarybuilderproreact-ui/LegendaryAnimations";

import { HUDCardSectional, HUDScrollContainer, HUDGrid, HUDGridItem, HUDIcon, HUDTyper } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDVirtuxifier } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";

import { useHUDTracker } from "@trap_stevo/legendarybuilderproreact-ui";

import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";

import HUDIoTide from "@trap_stevo/iotide-client";

import { patrolBuddyGoLoadingColor } from "../HUDManagers/PatrolBuddyGoHUDDataUtilityManager.js";

import PatrolBuddyOfficerCell from "./PatrolBuddyOfficerCell.js";











const patrolBuddyIO = new HUDIoTide();

function PBGUserActivitySection(pbgUserActivitySectionConfigurationSettings)
{
     const {
          pbgUserActivitySectionContainerConfigurationSettings = {},
     } = pbgUserActivitySectionConfigurationSettings;
     
     const [onlinePatrollers, setOnlinePatrollers] = useState([]);
     
     const [hovering, setHovering] = useState(false);
     
     const { visibleData, offsetY, handleScroll, updateData, containerRef } = useHUDVirtuxifier(onlinePatrollers, 10, 5, (activePage, totalPageCount) => {
         console.log(`Page ${activePage} of ${totalPageCount}`);
     });
     
     const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
     
     const { palette, paletteColor, colorTransparency } = useHUDPalette();
     
     const { sessionTracker, getFromSessionTracker } = useHUDTracker();
     
     
     
     
     
     
     
     
     
     
     
     const handleMouseLeave = () => {
          setHovering(false);
          
          return;
     };
     
     const handleMouseEnter = () => {
          setHovering(true);
          
          return;
     };
     
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          if (HUDOperationsUtilityManager.checkOperation("PBGUser Activity Section ~ Loading")) { return; }
          
          let currentPBGUsersOnline = getFromSessionTracker("pbgUsersOnline");
          
          HUDEventManager.on("pbg-user-online", (pbgUsersOnline) => {
               if (!pbgUsersOnline) { return; }
               
               setOnlinePatrollers(Array.from(pbgUsersOnline.values()));
          });
          
          HUDEventManager.on("pbg-user-offline", (pbgUsersOnline) => {
               if (!pbgUsersOnline) { return; }
               
               setOnlinePatrollers(Array.from(pbgUsersOnline.values()));
          });
          
          if (currentPBGUsersOnline)
          {
               setOnlinePatrollers(Array.from(currentPBGUsersOnline.values()));
          }
          
          return () => {};
     }, [signedUser]);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <Fade 
               style = {{
                    position : "relative",
                    display : "flex",
                    width : "calc(100%)",
                    marginTop : "2.69rem",
                    ...pbgUserActivitySectionContainerConfigurationSettings
               }}
               
               direction = "down" 
               
               duration = {269} 
          >
          <div
               style = {{
                    position : "relative",
                    display : "flex",
                    width : "calc(100%)"
               }}
               
               onMouseLeave = {handleMouseLeave}
               
               onMouseEnter = {handleMouseEnter}
          >
          <HUDCardSectional
               containerConfigurationSettings = {{
                    transition : "all 0.369s ease",
                    boxShadow : hovering ? "0px 0px 12px -1.69px rgba(0, 0, 0, 0.269)" : "none",
                    background : hovering ? paletteColor(palette, "PatrolBuddyAccent.color19", patrolBuddyGoLoadingColor) : "transparent",
                    width : "calc(100%)"
               }}
               
               headerConfigurationSettings = {{
                    transition : "all 0.369s ease",
                    borderBottom : hovering ? `1px solid ${paletteColor(palette, "PatrolBuddyLightAccent.color3", patrolBuddyGoLoadingColor)}` : `1px solid ${paletteColor(palette, "PatrolBuddyAccent.color1", patrolBuddyGoLoadingColor)}`
               }}
               
               footerConfigurationSettings = {{
                    borderTop : "none"
               }}
               
               holographicBorderColor = {colorTransparency(paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor), 0.869)}
               
               holographicBorderWidth = {1}
               
               headerContent = {
                    <div
                         style = {{
                              position : "relative",
                              display : "flex",
                              height : "calc(100%)", 
                              width : "calc(100%)"
                         }}
                    >
                         <label
                              style = {{
                                   userSelect : "none",
                                   fontWeight : "569",
                                   fontFamily : "ModeNine"
                              }}
                         >
                              <HUDTyper speed = {3.69} >
                                   {`Patrollers Online - ${ConvertNumberToMoneyFormat(onlinePatrollers.length, false)}`}
                              </HUDTyper>
                         </label>
                    </div>
               }
          >
               {onlinePatrollers.length > 0 ? (
                    <HUDScrollContainer
                         containerConfigurationSettings = {{
                              position : "relative",
                              display : "flex",
                              flexDirection : "row",
                              overflowY : "auto",
                              overflowX : "auto",
                              height : "calc(100%)",
                              width : "calc(100%)",
                              paddingBottom : "10px",
                              padding : "5px"
                         }}
                         
                         onScroll = {(e) => {
                              handleScroll(e.target.scrollTop);
                         }}
                         
                         scrollContainerRef = {containerRef}
                         
                         //scrollThumbColors = {[`${paletteColor(palette, "PatrolBuddyAccent.color1", patrolBuddyGoLoadingColor)}`, `${paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)}`]}
                         
                         thumbGradient = {`linear-gradient(45deg, ${paletteColor(palette, "PatrolBuddyAccent.color1", patrolBuddyGoLoadingColor)}, ${paletteColor(palette, "PatrolBuddyLightAccent.color1", patrolBuddyGoLoadingColor)})`}
                         
                         trackGradient = "linear-gradient(90deg, rgba(0, 0, 0, 0.269), rgba(0, 0, 0, 0.169))"
                         
                         hoverThumbColor = "rgba(0, 187, 247, 1)"
                         
                         trackColor = "tranpsarent"
                         
                         horizontalScrollHeight = "5px"
                         
                         borderRadius = "8px"
                         
                         width = "8px"
                    >
                         <div style = {{ position: "relative", display: "flex", flexDirection: "column", width: "100%" }} >
                         <HUDGrid
                              gridConfigurationSettings = {{
                                   position : "relative",
                                   msOverflowStyle : "none",
                                   scrollbarWidth : "none",
                                   transition : "all 0.69s ease",
                                   transform : `translateY(${offsetY}px)`,
                                   willChange : "transform",
                                   borderRadius : "0.69rem",
                                   height : onlinePatrollers.length <= 5 ? "9.69rem" : "21.69rem",
                                   width : "calc(100%)",
                                   gap : "1.69rem",
                                   zIndex : 12769,
                                   paddingBottom : "10px",
                                   marginLeft : "0rem"
                              }}
                              
                              columns = "repeat(5, auto)"
                              
                              rows = "repeat(5, auto)"
                              
                              fluid = {true}
                         >
                         {visibleData.map((onlinePatroller, index) => (
                              <HUDGridItem
                                   gridItemConfigurationSettings = {{
                                        position : "relative",
                                        display : "flex",
                                        flexShrink : 0,
                                        alignItems : "center",
                                        justifyContent : "center",
                                        overflowY : "hidden",
                                        overflowX : "hidden",
                                        msOverflowStyle : "none",
                                        scrollbarWidth : "none",
                                        cursor : "pointer",
                                        transition : "border 0.269s ease, scale 0.269s ease, transform 0.269s ease, opacity 0.269s ease",
                                        fontWeight : "bold",
                                        fontSize : "18px",
                                        border : `0.469px solid ${paletteColor(palette, "PatrolBuddyAccent.color2", patrolBuddyGoLoadingColor)}`,
                                        borderRadius : "10px",
                                        boxShadow : "0px 4px 8px rgba(0, 0, 0, 0.5)",
                                        backgroundColor : paletteColor(palette, "Interface.color3", patrolBuddyGoLoadingColor),
                                        height : "9.69rem",
                                        width : "auto",
                                        paddingBottom : "1.69px",
                                        paddingTop : "1.69px"
                                   }}
                              >
                                   <PatrolBuddyOfficerCell
                                        patrolBuddyOfficerCellContainerConfigurationSettings = {{
                                             width : "calc(100%)"
                                        }}
                                        
                                        patrolBuddyOfficerData = {{
                                             AccessP : onlinePatroller.accessLevel.accountAccessR,
                                             DisplayName : onlinePatroller.displayName, 
                                             Username : onlinePatroller.username
                                        }}
                                        
                                        showUsername = {true}
                                        
                                        index = {offsetY + index}
                                   />
                              </HUDGridItem>
                         ))}
                         </HUDGrid>
                         </div>
                    </HUDScrollContainer>
               ) : null}
          </HUDCardSectional>
          </div>
          </Fade>
     );
};

export default PBGUserActivitySection;