import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useEffect } from 'react';
import PatrolBuddyGoHUDUtilityManager, {PatrolBuddyGoHUDDataReloader} from "./HUDManagers/PatrolBuddyGoHUDUtilityManager.js";
import PatrolBuddyGoAuthenticationPanel from "./HUDPages/PatrolBuddyGoAuthenticationPanel.js";
import PatrolBuddyGoAccountCreationPanel from "./HUDPages/PatrolBuddyGoAccountCreationPanel.js";
import PatrolBuddyGoSupportChatViewPanel from "./HUDPages/PatrolBuddyGoSupportChatViewPanel.js";
import PatrolBuddyGoSupportAddChatPanel from "./HUDPages/PatrolBuddyGoSupportAddChatPanel.js";
import PatrolBuddyGoPatrolDataHubPanel from "./HUDPages/PatrolBuddyGoPatrolDataHubPanel.js";
import PatrolBuddyGoGlobalViewPanel from "./HUDPages/PatrolBuddyGoGlobalViewPanel.js";
import PatrolBuddyGoSupportPanel from "./HUDPages/PatrolBuddyGoSupportPanel.js";
import PatrolBuddyGoPBGViewPanel from "./HUDPages/PatrolBuddyGoPBGViewPanel.js";
import PatrolBuddyGoControlPanel from "./HUDPages/PatrolBuddyGoControlPanel.js";
import PatrolBuddyGoProductPanel from "./HUDPages/PatrolBuddyGoProductPanel.js";
import PBGFileCell from "./HUDComponents/PBGFileCell.js";
import { HUDUniversalHUDUtilityManager, HUDOperationsUtilityManager, HUDEventManager } from "@trap_stevo/legendarybuilderproreact-ui";
import { DetermineFileType } from "@trap_stevo/legendarybuilderproreact-ui";
import { HUDWindow } from "@trap_stevo/legendarybuilderproreact-ui";
import { ScrollHandlingProvider } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDWasabiService } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDTracker } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDSession } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDPalette } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDWindow } from "@trap_stevo/legendarybuilderproreact-ui";
import { useHUDVault } from "@trap_stevo/legendarybuilderproreact-ui";
import { useAuth } from "@trap_stevo/legendarybuilderproreact-ui";
import "@trap_stevo/legendarybuilderproreact-ui/HUDStyles";
import HUDIoTide from "@trap_stevo/iotide-client";
import "./HUDStyles/GRAnimations.css";
import "./HUDStyles/grScrollBar.css";
import "./HUDStyles/hudInputs.css";
import "./HUDStyles/hudFonts.css";











const patrolBuddyIO = new HUDIoTide();

function App() 
{
  const { credentials, vaultOnline, vaultOffline, downloadVaultAccess, updatePassword, saveCredential, syncCredentials, syncToCredentials, updateUseMasterKey, updateIdentifier } = useHUDVault();
  
  const { initializeService, setRegion, loadFiles, getFileURL } = useHUDWasabiService();
  
  const { signedUser, setSignedUser, signedIn, signIn, signOut } = useAuth();
  
  const { windowContents } = useHUDWindow();
  
  const { palette, paletteColor, colorTransparency, generatePalette } = useHUDPalette();
  
  const { sessionCache, setSessionCache, addToSessionCache, clearSessionCache } = useHUDSession();
  
  const { getFromSessionTracker, trackSessionData, untrackSessionData } = useHUDTracker();
  
  useEffect(() => {
       if (HUDOperationsUtilityManager.checkOperation("PatrolBuddy Interface ~ Loading")) { return; }
       
       if (!PatrolBuddyGoHUDUtilityManager.CheckLocalStorageTemporanceInterval(5))
       {
            PatrolBuddyGoHUDDataReloader(setSignedUser, setSessionCache, signIn);
       }
       
       downloadVaultAccess();
       
       setRegion("us-east-2");
       
       updateUseMasterKey(true, () => {
            updateIdentifier("WasabiSCGR", () => {
                 setTimeout(async () => {
                      //await syncCredentials("0000", "pbgSerial");
                      
                      //await syncToCredentials("0000", "pbgSerial");
                      
                      await vaultOnline(false, null, true, true, "0000", "pbgSerial");
                 }, 1000);
            });
       });
       
       return () => {};
  }, []);
  
  useEffect(() => {
       if (HUDUniversalHUDUtilityManager.CheckEmptyData(credentials) || HUDOperationsUtilityManager.checkOperation("Updated Vault Credentials")) { return; }
       
       initializeService();
       
       return () => {};
  }, [credentials]);
  
  useEffect(() => {
       if (HUDUniversalHUDUtilityManager.CheckEmptyData(signedUser) || signedUser.userID === undefined || signedUser.userID === null || HUDOperationsUtilityManager.checkOperation("Updated PatrolBuddy User Status")) { return; }
       
       patrolBuddyIO.createIO("PatrolBuddyNet ~ User Activity", "https://utilities.patrolbuddygo.com:9269", {}, {}, "", {}, false, false, () => {
            patrolBuddyIO.joinChannel("PatrolBuddyNet ~ User Activity", `${signedUser.precinct.precinctID}-pbg-user-activity`, signedUser.userID);
            
            //patrolBuddyIO.emitEvent("PatrolBuddyNet ~ User Activity", "register-pbg-user-online", signedUser);
       }, () => {
            //patrolBuddyIO.emitEvent("PatrolBuddyNet ~ User Activity", "register-pbg-user-offline", signedUser);
       });
       
       patrolBuddyIO.onEvent("PatrolBuddyNet ~ User Activity", "pbg-user-online", (onlinePBGUser) => {
            console.log("PBG user online: ", onlinePBGUser.username);
            
            let pbgUsersOnline = getFromSessionTracker("pbgUsersOnline");
            
            pbgUsersOnline = pbgUsersOnline ? pbgUsersOnline : new Map();
            
            if (pbgUsersOnline && Array.from(pbgUsersOnline.values()).filter(pbgUserOnline => pbgUserOnline.userID === onlinePBGUser.userID).length >= 1)
            {
                 return;
            }
            
            pbgUsersOnline.set(onlinePBGUser.serialKey, onlinePBGUser);
            
            trackSessionData("pbgUsersOnline", pbgUsersOnline);
            
            HUDEventManager.emit("pbg-user-online", pbgUsersOnline);
       });
       
       patrolBuddyIO.onEvent("PatrolBuddyNet ~ User Activity", "pbg-user-offline", (onlinePBGUser) => {
            console.log("PBG user offline: ", onlinePBGUser.username);
            
            let pbgUsersOnline = getFromSessionTracker("pbgUsersOnline");
            
            pbgUsersOnline = pbgUsersOnline ? pbgUsersOnline : new Map();
            
            if (pbgUsersOnline && Array.from(pbgUsersOnline.values()).filter(pbgUserOnline => pbgUserOnline.userID === onlinePBGUser.userID).length <= 0)
            {
                 return;
            }
            
            pbgUsersOnline.delete(onlinePBGUser.serialKey);
            
            trackSessionData("pbgUsersOnline", pbgUsersOnline);
            
            HUDEventManager.emit("pbg-user-offline", pbgUsersOnline);
       });
       
       return () => {};
  }, [signedUser]);
  
  useEffect(() => {
       if (HUDUniversalHUDUtilityManager.CheckEmptyData(signedUser) || HUDOperationsUtilityManager.checkOperation("Updated PatrolBuddy Statuses")) { return; }
       
       patrolBuddyIO.createIO("PatrolBuddyNet ~ PatrolBuddy Activity", "https://utilities.patrolbuddygo.com:9269", {}, {}, "", {}, false, false, () => {
            patrolBuddyIO.joinChannel("PatrolBuddyNet ~ PatrolBuddy Activity", `${signedUser.precinct.precinctID}-patrolbuddy-activity`, signedUser.userID);
            
            patrolBuddyIO.emitEvent("PatrolBuddyNet ~ PatrolBuddy Activity", "get-online-patrolbuddies-in-precinct", { precinct : signedUser.precinct.precinct });
       }, () => {
            untrackSessionData("patrolBuddiesOnline");
            
            HUDEventManager.emit("online-patrolbuddies-in-precinct", new Map());
       });
       
       patrolBuddyIO.onEvent("PatrolBuddyNet ~ PatrolBuddy Activity", "got-online-patrolbuddies-in-precinct", (onlinePatrolBuddies) => {
            console.log(onlinePatrolBuddies && onlinePatrolBuddies.length > 0 ? `${onlinePatrolBuddies.length} ${onlinePatrolBuddies.length > 1 ? "PatrolBuddies" : "PatrolBuddy"} online!` : "No PatrolBuddies online.");
            
            let patrolBuddiesOnline = getFromSessionTracker("patrolBuddiesOnline");
            
            if (!onlinePatrolBuddies)
            {
                 return;
            }
            
            patrolBuddiesOnline = patrolBuddiesOnline ? patrolBuddiesOnline : new Map();
            
            onlinePatrolBuddies.forEach(onlinePatrolBuddy => {
                 const patrolBuddyOperational = Date.now() <= (onlinePatrolBuddy.operationalSince + 5000);
                 
                 if (patrolBuddyOperational)
                 {
                      patrolBuddiesOnline.set(onlinePatrolBuddy.serialK, onlinePatrolBuddy);
                 }
                 else if (!patrolBuddyOperational)
                 {
                      patrolBuddiesOnline.delete(onlinePatrolBuddy.serialK);
                 }
            });
            
            trackSessionData("patrolBuddiesOnline", onlinePatrolBuddies.length === 0 ? new Map() : patrolBuddiesOnline);
            
            HUDEventManager.emit("online-patrolbuddies-in-precinct", patrolBuddiesOnline);
       });
       
       patrolBuddyIO.onEvent("PatrolBuddyNet ~ PatrolBuddy Activity", "patrolbuddy-in-precinct-online", (onlinePatrolBuddy) => {
            const serialK = onlinePatrolBuddy ? onlinePatrolBuddy.serialK : null;
            
            if (!onlinePatrolBuddy || !serialK) { return; }
            
            console.log("PatrolBuddy online: ", "PBG-" + serialK.slice(0, 1) + "..." + serialK.slice(3));
            
            let patrolBuddiesOnline = getFromSessionTracker("patrolBuddiesOnline");
            
            patrolBuddiesOnline = patrolBuddiesOnline ? patrolBuddiesOnline : new Map();
            
            if (!onlinePatrolBuddy || patrolBuddiesOnline && Array.from(patrolBuddiesOnline.values()).filter(patrolBuddyOnline => patrolBuddyOnline.serialK === serialK).length >= 1)
            {
                 return;
            }
            
            patrolBuddiesOnline.set(serialK, onlinePatrolBuddy);
            
            trackSessionData("patrolBuddiesOnline", patrolBuddiesOnline);
            
            HUDEventManager.emit("online-patrolbuddies-in-precinct", patrolBuddiesOnline);
       });
       
       patrolBuddyIO.onEvent("PatrolBuddyNet ~ PatrolBuddy Activity", "patrolbuddy-in-precinct-offline", (offlinePatrolBuddy) => {
            const serialK = offlinePatrolBuddy ? offlinePatrolBuddy.serialK : null;
            
            if (!offlinePatrolBuddy || !serialK) { return; }
            
            console.log("PatrolBuddy offline: ", "PBG-" + serialK.slice(0, 1) + "..." + serialK.slice(3));
            
            let patrolBuddiesOnline = getFromSessionTracker("patrolBuddiesOnline");
            
            if (!offlinePatrolBuddy || patrolBuddiesOnline && Array.from(patrolBuddiesOnline.values()).filter(patrolBuddyOnline => patrolBuddyOnline.serialK === serialK).length <= 0)
            {
                 return;
            }
            
            patrolBuddiesOnline.delete(serialK);
            
            trackSessionData("patrolBuddiesOnline", patrolBuddiesOnline);
            
            HUDEventManager.emit("online-patrolbuddies-in-precinct", patrolBuddiesOnline);
       });
       
       return () => {};
  }, [signedUser]);
  
  useEffect(() => {
       generatePalette(25, null, "#1A1B1E", false, "futuristic");
       
       generatePalette(25, null, "#454545, #464d55, #A1A1A1, #292a2e", false, "futuristic", "PatrolBuddyAccent");
       
       generatePalette(25, null, "#A1A1A1, #fffff", true, "futuristic", "PatrolBuddyLightAccent");
       
       generatePalette(25, null, "#9FD1D9, #C9F5FF", false, "futuristic", "PatrolBuddyLights");
       
       return () => {};
  }, []);
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div>
         <noscript>Welcome to PatrolBuddyGo!</noscript>
         
         <div id="root"></div>
         
         <script type="application/ld+json">
              {`
                   {
                         "@context": "https://schema.org",
                         "@type": "LLC",
                         "url": "https://patrolbuddygo.com",
                         "name": "PatrolBuddyGo",
                         "description": "A product of Generalized Robotics, empowering police departments.",
                         "logo": "https://patrolbuddygo.com/HUDPatrolBuddyGoIcon.png"
                   }
             `}
         </script>
         
         <Router>
              <ScrollHandlingProvider>
                   <PBGRouter sessionCache = {sessionCache} />
              </ScrollHandlingProvider>
         </Router>
         
         {windowContents.length > 0 && windowContents.map((windowFile, index) => (
               <HUDWindow
                    key = {windowFile.fileID}
                    
                    id = {windowFile.fileID}
                    
                    containerConfigurationSettings = {{
                         zIndex : 12869
                    }}
                    
                    viewportConfigurationSettings = {{
                         position : "relative",
                         display : "flex",
                         height : "calc(87.69%)",
                         paddingBottom : "3.69px",
                         paddingRight : "12.69px",
                         paddingLeft : "12.69px",
                         paddingTop : "3.69px",
                         marginBottom : "0.69rem"
                    }}
                    
                    headerContent = {windowFile.headerContent}
                    
                    footerContent = {windowFile.footerContent}
                    
                    minimizeAnimation = {{ opacity : 0.569, scale : 0.769 }}
                    
                    closeAnimation = {{ opacity : 0, scale : 0.569 }}
                    
                    maximizeAnimation = {{ opacity : 1, scale: 1 }}
                    
                    animationDuration = {0.569}
                    
                    minimizedContent = {(
                         <PBGFileCell
                              pbgFileCellContainerConfigurationSettings = {{
                                   justifyContent : "center",
                                   alignItems : "center",
                                   height : "calc(100%)"
                              }}
                              
                              pbgFileFooterContainerConfigurationSettings = {{
                                   paddingLeft : "0rem"
                              }}
                              
                              pbgFileDateContainerConfigurationSettings = {{
                                   paddingLeft : "0rem"
                              }}
                              
                              pbgFileConfigurationSettings = {{
                                   width : "calc(100%)"
                              }}
                              
                              filePreviewIconConfigurations = {{
                                   style : {
                                        position : "absolute",
                                        height : "1.69rem",
                                        width : "1.69rem",
                                        top : 5
                                   },
                                   name : DetermineFileType(windowFile) === "video" ? "fa-video" : DetermineFileType(windowFile) === "audio" ? "fa-volume-up" : DetermineFileType(windowFile) === "text" ? "fa-file-lines" : "fa-file"
                              }}
                              
                              playVideoOnHover = {false}
                              
                              playVideoOnClick = {false}
                              
                              autoPlayVideo = {false}
                              
                              showHeaderBar = {false}
                              
                              colorTransparency = {colorTransparency}
                              
                              paletteColor = {paletteColor}
                              
                              palette = {palette}
                              
                              signedUser = {signedUser}
                              
                              signedIn = {signedIn}
                              
                              file = {windowFile}
                              
                              index = {index}
                         />
                    )}
                    
                    initialHeight = {692}
                    
                    initialWidth = {769}
               >
                    {windowFile.content}
               </HUDWindow>
        ))}
    </div>
  );
}

const PBGRouter = ({ sessionCache }) => {
  const pbgViewPanelConfigurationSettings = sessionCache.CurrentPBGView ? sessionCache.CurrentPBGView : {};
  
  const location = useLocation();
  
  
  
  
  
  
  
  
  
  
  
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Routes location={location}>
          <Route path = "/" element = {<PatrolBuddyGoProductPanel />} />
          
          <Route path = "/authentication" element = {<PatrolBuddyGoAuthenticationPanel />} />
          
          <Route path = "/patrol-data-hub" element = {<PatrolBuddyGoPatrolDataHubPanel />} />
          
          <Route path = "/control-panel" element = {<PatrolBuddyGoControlPanel />} />
          
          <Route path = "/global-view/:county/:serialKey" element = {<PatrolBuddyGoPBGViewPanel {...pbgViewPanelConfigurationSettings} />} />
          
          <Route path = "/global-view" element = {<PatrolBuddyGoGlobalViewPanel />} />
          
          <Route path = "/support/new-chat" element = {<PatrolBuddyGoSupportAddChatPanel />} />
          
          <Route path = "/support/chat" element = {<PatrolBuddyGoSupportChatViewPanel />} />
          
          <Route path = "/support" element = {<PatrolBuddyGoSupportPanel />} />
          
          <Route path = "/account-creation" element = {<PatrolBuddyGoAccountCreationPanel />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;

