import axios from "axios";

let apiClients = {};

const subRoutes = [
  "pbg-serialization",
  "pbg-downloads",
  "pbg-traces",
  "pbg-auth/users",
  "localities",
  "pbg-users",
  "pbg-keys",
  "pbgs"
];

export const updateAPIClients = (port) => {
  apiClients = {};
  subRoutes.forEach(subRoute => {
    apiClients[subRoute] = axios.create({
      baseURL : `https://utilities.patrolbuddygo.com/${subRoute}`,
      headers : { "Content-Type": "application/json" }
    });
  });
};

export const getAPIClient = (subRoute) => {
  return apiClients[subRoute];
};

updateAPIClients(5000);