import * as React from "react";











const patrolCams = {
     "patrol-360-cam" : {
          name : "Patrol 360 Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "pbg360cam",
          camIP : "127.0.0.1",
          camID : "patrol-360-cam",
          multipleCameras : true,
          zoneMode : true,
          camIndex : 0,
          camPort : 8554
     },
     "front-right-cam" : {
          name : "Front Right Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "rightleftcam",
          camIP : "127.0.0.1",
          camID : "front-right-cam",
          camHeight : 1080,
          camWidth : 1280,
          hideInMenu : true,
          zoneMode : false,
          camIndex : 1,
          camPort : 8557
     },
     "front-left-cam" : {
          name : "Front Left Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "leftrightcam",
          camIP : "127.0.0.1",
          camID : "front-left-cam",
          camHeight : 1080,
          camWidth : 1280,
          hideInMenu : true,
          zoneMode : false,
          camIndex : 2,
          camPort : 8556
     },
     "back-right-cam" : {
          name : "Back Right Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "rightrightcam",
          camIP : "127.0.0.1",
          camID : "back-right-cam",
          camHeight : 1080,
          camWidth : 1280,
          hideInMenu : true,
          zoneMode : false,
          camIndex : 3,
          camPort : 8558
     },
     "back-left-cam" : {
          name : "Back Left Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "leftleftcam",
          camIP : "127.0.0.1",
          camID : "back-left-cam",
          camHeight : 1080,
          camWidth : 1280,
          hideInMenu : true,
          zoneMode : false,
          camIndex : 4,
          camPort : 8555
     },
     "front-zone-cam" : {
          name : "Front Zone Cam",
          iconColor : "#979cbd",
          iconName : "chevron-up",
          access : "frontzone",
          camIP : "127.0.0.1",
          camID : "front-zone-cam",
          camHeight : 1080,
          camWidth : 2560,
          hideInMenu : false,
          zoneMode : true,
          camZoneIndex : 0,
          camIndex : 5,
          camPort : 8561
     },
     "back-zone-cam" : {
          name : "Back Zone Cam",
          iconColor : "#979cbd",
          iconName : "chevron-down",
          access : "backzone",
          camIP : "127.0.0.1",
          camID : "back-zone-cam",
          camHeight : 1080,
          camWidth : 2560,
          hideInMenu : false,
          zoneMode : true,
          camZoneIndex : 1,
          camIndex : 6,
          camPort : 8562
     },
     "left-zone-cam" : {
          name : "Left Zone Cam",
          iconColor : "#979cbd",
          iconName : "chevron-left",
          access : "leftzone",
          camIP : "127.0.0.1",
          camID : "left-zone-cam",
          camHeight : 1080,
          camWidth : 2560,
          hideInMenu : false,
          zoneMode : true,
          camZoneIndex : 2,
          camIndex : 7,
          camPort : 8559
     },
     "right-zone-cam" : {
          name : "Right Zone Cam",
          iconColor : "#979cbd",
          iconName : "chevron-right",
          access : "rightzone",
          camIP : "127.0.0.1",
          camID : "right-zone-cam",
          camHeight : 1080,
          camWidth : 2560,
          hideInMenu : false,
          zoneMode : true,
          camZoneIndex : 3,
          camIndex : 8,
          camPort : 8560
     },
     "test-cam" : {
          name : "Test Cam",
          iconColor : "#979cbd",
          iconName : "camera",
          access : "test",
          camIP : "192.168.12.113",
          camID : "test-cam",
          camHeight : 240,
          camWidth : 320,
          hideInMenu : true,
          zoneMode : false,
          camIndex : 9,
          camPort : 8554
     }
};











export {
     patrolCams
};