import axios from "axios";

import { HUDUniversalHUDUtilityManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDEventManager } from "@trap_stevo/legendarybuilderproreact-ui";

import { HUDLockManager } from "@trap_stevo/encryped-lock";

import { getAPIClient } from "./HUDAPIClientsManager.js";

import HUDUtilityManager from "./HUDUtilityManager.js";











class PatrolBuddyGoHUDUserManager  
{
   static GetPatrolBuddyGoUserLocalityByUsername(username) 
   {
        if (HUDUtilityManager.checkOperation("gettingUserLocality")) { return null; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
        
        return patrolBuddyGoDataManagerAPI.get(`/getPatrolBuddyGoUserLocality`, {
            params : {
                 inputUsername : username
            }
        }).then((ress) => {
            return ress.data;
        })
        .catch(() => {
            return null;
        });
   }
   
   static GetPatrolBuddyGoUserAccessByUsername(username) 
   {
        if (HUDUtilityManager.checkOperation("gettingUserAccess")) { return ""; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
        
        return patrolBuddyGoDataManagerAPI.get(`/getPatrolBuddyGoUserAccountAccessLevel`, {
            params : {
                 inputUsername : username
            }
        }).then((ress) => {
            return ress.data;
        })
        .catch(() => {
            return "";
        });
   }
   
   static GetPatrolBuddyGoUserEmailByUsername(username) 
   {
        if (HUDUtilityManager.checkOperation("gettingUserEmail")) { return ""; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
        
        return patrolBuddyGoDataManagerAPI.get(`/getPatrolBuddyGoUserEmail`, {
            params : {
                 inputUsername : username
            }
        }).then((ress) => {
            return ress.data;
        })
        .catch(() => {
            return "";
        });
   }
   
   static GetPatrolBuddyGoUserIDByUsername(username) 
   {
        if (HUDUtilityManager.checkOperation("gettingUserID")) { return -1; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
        
        return patrolBuddyGoDataManagerAPI.get(`/getPatrolBuddyGoUserID`, {
            params : {
                 inputUsername : username
            }
        }).then((ress) => {
            if (ress.data && HUDUniversalHUDUtilityManager.CheckEmptyData(ress.data) === false)
            {
                 return ress.data.userID;
            }
            
            return -1;
        })
        .catch(() => {
            return -1;
        });
   }
   
   
   
   static GetPatrolBuddyGoUsersByPrecinct(precinct)
   {
        if (HUDUtilityManager.checkOperation("gettingUsersByPrecinct")) { return []; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
        
        return patrolBuddyGoDataManagerAPI.get(`/get-pbg-users-by-precinct?inputPrecinct=${precinct}`).then((ress) => {
            const userCount = ress.data.length;
            
            console.log(`Got ${userCount} ${userCount > 1 ? "users" : "user"} operating from ${precinct}!`);
            
            return ress.data;
        })
        .catch(() => {
            return [];
        });
   }
   
   static GetPatrolBuddyGoUsersByCounty(county) 
   {
        if (HUDUtilityManager.checkOperation("gettingUsersByCounty")) { return []; }
        
        const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
        
        return patrolBuddyGoDataManagerAPI.get(`/get-pbg-users-by-county?inputCounty=${county}`).then((ress) => {
            const userCount = ress.data.length;
            
            console.log(`Got ${userCount} ${userCount > 1 ? "users" : "user"} currently in ${county}!`);
            console.log(ress.data);
            return ress.data;
        })
        .catch(() => {
            return [];
        });
   }
   
   
   
   static async AddPBGUserAccount(firstName, middleName, lastName, username, email, password, rank, address, county)
   {
        try
        {
             if (county === null || HUDUtilityManager.checkOperation("addingPBGUserAccount")) { return [false, null]; }
             
             const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
             
             return patrolBuddyGoDataManagerAPI(`/addPatrolBuddyGoUserAccount`, {
                  method: "POST",
                  params : {
                       "inputFirstName" : firstName,
                       "inputMiddleName" : middleName,
                       "inputLastName" : lastName,
                       "inputUsername" : username.trim(),
                       "inputEmail" : email.trim(),
                       "inputPassword" : password,
                       "inputRank" : rank,
                       "address" : address,
                       "county" : county
                  }
             }).then((ress) => {
                  console.log("Added PBG account: " + ress.data.username + "!");
                  
                  const desiredPBGUser = {
                       precinct : ress.data.precinct,
                       access : {
                            accessP : ress.data.accessLevel.accountAccessP,
                            accessR : ress.data.accessLevel.accountAccessR
                       },
                       userDisplayName : ress.data.displayName,
                       userFirstName : ress.data.firstName,
                       userLastName : ress.data.lastName,
                       userUsername : ress.data.username,
                       userEmail : ress.data.email,
                       userID : ress.data.userID,
                  };
                  
                  HUDEventManager.emit("patroller-added", desiredPBGUser);
                  
                  return [true, desiredPBGUser];
             })
             .catch(() => {
                  return [false, null];
             });
        }
        catch (error)
        {
             console.log("Did not add PBG account....", error);
             
             return [false, null];
        }
   }
   
   static async AddPBGUserBaseAccount(username, password, address, county)
   {
        try
        {
             if (county === null || HUDUtilityManager.checkOperation("addingPBGUserBaseAccount")) { return [false, null]; }
             
             const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-users");
             
             const hudLockManager = new HUDLockManager(12);
             
             const hashedPassword = await hudLockManager.hashPassword(password);
             
             return await patrolBuddyGoDataManagerAPI(`/addPatrolBuddyGoUserBaseAccount`, {
                  method: "POST",
                  params : {
                       "inputUsername" : username,
                       "inputPassword" : hashedPassword,
                       "address" : address,
                       "county" : county
                  }
             }).then((ress) => {
                  console.log("Added base account: " + ress.data.username + "!");
                  
                  return [true, ress.data];
             })
             .catch(() => {
                  return [false, null];
             });
       }
       catch (error)
       {
             console.log("Did not add base account....", error);
             
             return [false, null];
       }
   }
   
   
   
   static async GetPatrolBuddyGoUserActionTraces(userID)
   {
        try
        {
             if (HUDUtilityManager.checkOperation("gettingUserActionTraces")) { return []; }
             
             const patrolBuddyGoDataManagerAPI = getAPIClient("pbg-traces");
             
             return patrolBuddyGoDataManagerAPI.get(`/getPatrolBuddyGoUserActionTraces`, {
                  params : {
                       inputUserID : userID
                  }
             }).then((ress) => {
                  return ress.data;
             })
             .catch(() => {
                  return [];
             });
        }
        catch (error)
        {
             console.log(`Did not get action traces for ${userID}`, error);
             
             return [];
        }
   }
} 

export default PatrolBuddyGoHUDUserManager; 
