import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { HUDNotificationProvider } from "./HUDComponents/HUDNotificationProvider.js";
import { HUDVaultProvider, HUDWasabiServiceProvider, HUDTrackerProvider, HUDSessionProvider } from "@trap_stevo/legendarybuilderproreact-ui";
import { HUDPaletteProvider } from "@trap_stevo/legendarybuilderproreact-ui";
import { HUDWindowProvider } from "@trap_stevo/legendarybuilderproreact-ui";
import { AuthProvider } from "@trap_stevo/legendarybuilderproreact-ui";
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
         <HUDSessionProvider>
              <HUDTrackerProvider>
                   <HUDVaultProvider rootURL = "https://utilities.patrolbuddygo.com" appName = "PatrolBuddyGo">
                        <HUDWasabiServiceProvider>
                             <HUDNotificationProvider>
                                  <HUDPaletteProvider>
                                       <HUDWindowProvider
                                            toasterContainerConfigurationSettings = {{
                                                 zIndex : 12869
                                            }}
                                            
                                            toasterHeight = "269px"
                                       >
                                            <App />
                                       </HUDWindowProvider>
                                  </HUDPaletteProvider>
                             </HUDNotificationProvider>
                        </HUDWasabiServiceProvider>
                   </HUDVaultProvider>
              </HUDTrackerProvider>
         </HUDSessionProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
